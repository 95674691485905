import { FC, useState } from "react";
import { Autocomplete, Button, TableBody, TableCell, TableRow, TextField, Toolbar, Typography } from "@mui/material";
import { Stack, alpha } from "@mui/system";

import { CustomTableStructure } from "../core/CustomTableStructure/CustomTableStructure";
import { ICampaignTacticTypes } from "src/interfaces/entities/ICampaignTacticTypes";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";
import { getComparator, stableSort } from "src/utils/sort";
import { CampaignTacticsTableHeader } from "./CampaignTacticsTableHeader";
import { CAMPAIGN_TACTICS_HEADER, Choice } from "src/consts/campaignTacticsPage/campaignTactics";
import { CampaignTacticsTableRow } from "./CampaignTacticsTableRow";

interface ICampaignTacticsTable {
    campaignsTactics: IDimCampaign[];
    tacticsType: ICampaignTacticTypes[];
    handleSaveCampaignTactics: (isuniversalTacticApply: boolean) => void;
    onTacticSelectionChange: (value: ICampaignTacticTypes | null, campaignId: string) => void;
    universalTactic: string | null;
    tacticChangeHandler: (value: ICampaignTacticTypes | null) => void;
    onTacticRowClick: (campaignId: string) => void;
    selectedRows: readonly string[];
    isTacticSaving: boolean;
    handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CampaignTacticsTable: FC<ICampaignTacticsTable> = ({
    campaignsTactics,
    tacticsType,
    onTacticSelectionChange,
    handleSaveCampaignTactics,
    universalTactic,
    tacticChangeHandler,
    onTacticRowClick,
    selectedRows,
    isTacticSaving,
    handleSelectAllClick,
}) => {
    const sortedTacticTypes = stableSort(tacticsType, getComparator("asc", "displayName")) as ICampaignTacticTypes[];

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [order, setOrder] = useState<Choice>("asc");
    const [orderBy, setOrderBy] = useState("");

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const count = parseInt(event.target.value, 10);
        setPage(0);
        setRowsPerPage(count);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IDimCampaign) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const getSelectedTactics = (tacticId?: string | null) => {
        return tacticId ? tacticsType.find((t) => t.id === +tacticId) || null : null;
    };

    const handleTacticSelectionChange = (
        e: React.SyntheticEvent<Element, Event>,
        newValue: ICampaignTacticTypes | null,
        row: IDimCampaign,
    ) => {
        e.stopPropagation();
        onTacticSelectionChange(newValue, row.campaignId);
    };

    const isSelected = (campaignId: string) => selectedRows.indexOf(campaignId) !== -1;

    return (
        <Stack gap="20px">
            {selectedRows.length > 0 && (
                <Toolbar
                    sx={{
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }}
                >
                    <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                        {`Update ${selectedRows.length} tactics`}
                    </Typography>
                    <Stack direction="row" gap={1} alignItems="center">
                        <Autocomplete
                            sx={{
                                width: 250,
                                height: "100%",
                                "& .MuiOutlinedInput-notchedOutline": { border: "1px solid rgba(38, 38, 38, 0.5)" },
                            }}
                            size="small"
                            options={tacticsType}
                            getOptionLabel={(option: ICampaignTacticTypes) => option.displayName}
                            defaultValue={getSelectedTactics(universalTactic)}
                            value={getSelectedTactics(universalTactic)}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e, newValue) => tacticChangeHandler(newValue)}
                        />
                        <Button
                            onClick={() => handleSaveCampaignTactics(true)}
                            variant="outlined"
                            disabled={isTacticSaving}
                        >
                            Save
                        </Button>
                    </Stack>
                </Toolbar>
            )}
            <CustomTableStructure
                paginationProps={{
                    count: campaignsTactics.length,
                    page,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    rowsPerPage,
                }}
            >
                <CampaignTacticsTableHeader
                    onRequestSort={handleRequestSort}
                    choice={order}
                    choiceBy={orderBy}
                    selectedRowCount={selectedRows.length}
                    onSelectAllClick={handleSelectAllClick}
                    totalRowCount={campaignsTactics.length}
                />
                <TableBody>
                    {campaignsTactics.length ? (
                        stableSort(campaignsTactics, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((d: any, index: number) => {
                                const isRowSelected = isSelected(d.campaignId);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <CampaignTacticsTableRow
                                        key={d.campaignId}
                                        row={d}
                                        getSelectedTactics={getSelectedTactics}
                                        tacticsType={sortedTacticTypes}
                                        handleTacticSelectionChange={handleTacticSelectionChange}
                                        isSelected={isRowSelected}
                                        onTacticRowClick={onTacticRowClick}
                                        labelId={labelId}
                                    />
                                );
                            })
                    ) : (
                        <TableRow>
                            <TableCell align="center" colSpan={CAMPAIGN_TACTICS_HEADER.length + 2}>
                                No data found
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </CustomTableStructure>
        </Stack>
    );
};
