import React from "react";
import { Box, TableCell, TableHead, TableRow, TableSortLabel, Theme, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { css } from "@emotion/css";

import { useStylesForHeaderInTable } from "../../../consts/StylesForTables";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { Choice, IHeadCell, TRUE_METRICS } from "../../../consts/performancePaidPage/performancePaidPage";
import logoAttribution from "../../../assets/Prescient-Logo-Bug.png";

interface ICampaignTableHeader {
    onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
    choice?: Choice;
    choiceBy?: string;
    selectedTableColumns: IHeadCell[];
}

export const useStylesForCampaignTableHeader = (theme: Theme) => ({
    bottomBorder: css({
        borderBottom: `1px solid ${theme.palette.divider}`,
    }),
    headerLeftSectionTitle: css({
        minWidth: "90px",
        fontWeight: 700,
        "& div .haloEffectStyle": {
            width: "45px",
        },
    }),
    stickyHeader: css({
        zIndex: 1,
        position: "relative",
        backgroundColor: "white",

    }),
    modifiedCellClass: css({
        color: "#000000 !important",
        padding: "16px 16px",
        fontWeight: "700",
    }),
});

export const CampaignTableHeader = (props: ICampaignTableHeader) => {
    const { onRequestSort, choice, choiceBy, selectedTableColumns } = props;

    const theme = useTheme();
    const classes = { ...useStylesForCampaignTableHeader(theme), ...useStylesForHeaderInTable() };
    const analyticsService = useAnalyticsService();

    const createSortHandler = (property: string, name?: string) => (event: React.MouseEvent<unknown>) => {
        if (onRequestSort) {
            analyticsService.logEvent("Performance Attribution Table Sorted", {
                Column: name,
            });
            onRequestSort(event, property);
        }
    };

    return (
        <TableHead className={classes.tableHeader}>
            <TableRow id="performance-info">
                {selectedTableColumns.map((header) => {
                    const style = {
                        backgroundColor: TRUE_METRICS.includes(header.id) ? theme.palette.primary.main : "inherit",
                        borderRight: TRUE_METRICS.includes(header.id) ? `1px solid #FFFFFF` : "inherit",
                        borderRadius: "2px 2px 0 0",
                        borderBottom: 0,
                        borderTop: 0,
                        padding: "8px 3px",
                    };
                    return (
                        !header.hidden && (
                            <TableCell key={`extra-info-${header.id}`} className={classes.bottomBorder} sx={style}>
                                {TRUE_METRICS.includes(header.id) && (
                                    <Stack
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ height: "20px", width: "22px", margin: "0 auto" }}
                                    >
                                        <img src={logoAttribution} style={{ width: "100%", height: "100%" }} />
                                    </Stack>
                                )}
                            </TableCell>
                        )
                    );
                })}
                <TableCell key="row-actions" align="right" className={classes.bottomBorder}>
                    <span className={classes.headerLeftSectionTitle}></span>
                </TableCell>
            </TableRow>
            <TableRow className={`${classes.headerRow} ${classes.stickyHeader}`} id="sticky-performance-header">
                {selectedTableColumns.map(
                    (header) =>
                        !header.hidden && (
                            <TableCell
                                key={header.id}
                                align={"left"}
                                sortDirection={choiceBy === header.id ? choice : false}
                                className={classes.modifiedCellClass}
                                sx={{
                                    width: header.width,
                                    minWidth: header.width,
                                    backgroundColor: "#FFFFFF",
                                }}
                            >
                                {onRequestSort ? (
                                    <TableSortLabel
                                        active={choiceBy === header.id}
                                        direction={choiceBy === header.id ? choice : "asc"}
                                        onClick={createSortHandler(header.id, header.label)}
                                        className={classes.headerLeftSectionTitle}
                                        color="green"
                                    >
                                        {header.label}
                                        {choiceBy === header.id ? (
                                            <Box component="span" style={{ display: "none" }}>
                                                {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    <span className={classes.headerLeftSectionTitle}>{header.label}</span>
                                )}
                            </TableCell>
                        ),
                )}
                <TableCell key="row-actions" align="right" className={classes.tableCell}>
                    <span className={classes.headerLeftSectionTitle}></span>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
