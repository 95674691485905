import groupBy from "lodash/groupBy";
import startCase from "lodash/startCase";
import { Typography } from "@mui/material";

import {
    DEFAULT_REVENUE_CONNECTOR,
    SECONDARY_REVENUE_CONNECTOR,
    TERTIARY_REVENUE_CONNECTOR,
    QUATERNARY_REVENUE_CONNECTOR,
} from "./homeDashboard";
import { calculateAverageOrder, calculateCAC, calculateROAS, formatValue } from "../utils";
import { IDictionary } from "src/interfaces/IDictionary";
import { IChartItem, IChartItemLegend } from "src/interfaces/chart/IChartItem";
import {
    KeyKPIPerformanceTabTypes,
    PERFORMANCE_KPI_METRICS_TABS,
    TREND_TABLE_HEADER,
} from "src/consts/homeDashboard/homeDashboard";
import { FORMATS } from "src/enums/Formats";
import { calculatePercentage } from "../performancePage/performancePage";
import { IDateRange, IDateSelectionRange, IKpiPerformanceMetric } from "src/interfaces/dashboard/trend";
import { getRangeFormattedTitle } from "src/components/DateRangeFilter/reactDateRangeUtils";
import { AMAZON_SELLING_PARTNER, GOOGLE_ANALYTICS, GOOGLE_ANALYTICS_4 } from "src/consts/connectors";
import { AMAZON_SELLING_PARTNER_REVENUE } from "src/consts/targetRevenue";
import { getDataSourceImage } from "src/services/dataSources/dataSources";
import { IDataSource } from "src/interfaces/IData";
import { getDefaultRevenueFromReveueArr } from "src/components/CampaignComponents/InsightsModal/AttributionTab/attributionUtils";

export const calculateTotalMetricValue = (metric: Array<{ value: number }>) =>
    metric.reduce((acc, { value }) => acc + value, 0);

export const getTargetOptions = (defaultSource: string, isAmazonConnected: boolean) => {
    const options = [
        { label: "Total", id: "total", isDisabled: false },
        { label: "Amazon Selling Partner only", id: AMAZON_SELLING_PARTNER, isDisabled: !isAmazonConnected },
    ];

    if (defaultSource) {
        options.splice(1, 0, {
            label: `${startCase(defaultSource.replace(/_/g, " "))} Only`,
            id: defaultSource,
            isDisabled: false,
        });
    }

    return options;
};

export const getDefaultSource = (connectors: string[] = []) => {
    const prioritizedConnectors = [
        DEFAULT_REVENUE_CONNECTOR,
        SECONDARY_REVENUE_CONNECTOR,
        TERTIARY_REVENUE_CONNECTOR,
        QUATERNARY_REVENUE_CONNECTOR,
    ];
    for (const connector of prioritizedConnectors) {
        if (connectors.includes(connector)) {
            return connector;
        }
    }
    // If none of the prioritized connectors are found, return the first connector
    return connectors.length > 0 ? connectors[0] : null;
};

export const getSelectedTab = (ecommerceState: KeyKPIPerformanceTabTypes) =>
    PERFORMANCE_KPI_METRICS_TABS.find((tab) => tab.id === ecommerceState);

export const getDefaultDataSource = (ecommerceState: KeyKPIPerformanceTabTypes, data: IChartItem[] = []) => {
    const seriesDataHolder = [...data];
    const uniqueNames = Array.from(new Set(seriesDataHolder.map(({ name }) => name)));

    if (["revenue", "orders"].includes(ecommerceState)) {
        const defaultSource = getDefaultSource(uniqueNames);
        if (defaultSource && ![GOOGLE_ANALYTICS, GOOGLE_ANALYTICS_4].includes(defaultSource)) {
            return seriesDataHolder.filter(({ name }) => name === defaultSource || name === AMAZON_SELLING_PARTNER);
        }
    }

    if (ecommerceState === "web_bounce_rate") {
        return seriesDataHolder.map((series) =>
            series.name === GOOGLE_ANALYTICS_4 ? { ...series, value: series.value * 100 } : series,
        );
    }

    return seriesDataHolder;
};

export const groupByAndSortByKey = (data: IChartItem[], key: "channelName" | "date" | "name") => {
    return groupBy(
        [...data].sort((a, b) => +new Date(a.date) - +new Date(b.date)),
        key,
    );
};

const handleChannelReportedROAS = (dataByMetric: any, dataSourcesByProgrammaticName: IDictionary<IDataSource>) => {
    const { channel_reported_roas } = dataByMetric;

    const meta = channel_reported_roas?.meta;
    const connectorTotals = meta?.connectorTotals || [];

    if (!channel_reported_roas && !connectorTotals.length) {
        return { series: [], legend: [] };
    }

    const legend = [
        { name: "Total", value: channel_reported_roas.meta?.total || 0, seriesName: "total" },
        ...connectorTotals.map((l: any) => {
            return {
                name: dataSourcesByProgrammaticName[l.connectorName]?.name || l.connectorName,
                value: l.value,
                seriesName: l.connectorName,
            };
        }),
    ] as IChartItemLegend[];

    return {
        series: channel_reported_roas.data || [],
        legend,
    };
};

const calculateROASSeries = (
    filteredRevenue: IChartItem[],
    filteredSpend: IChartItem[],
    spendGrpByDate: IDictionary<IChartItem[]>,
    revenueGrpByDate: IDictionary<IChartItem[]>,
) => {
    let spendObj: IDictionary = {};

    filteredSpend.forEach((metric) => {
        if (!spendObj[metric.date]) {
            spendObj = { ...spendObj, [metric.date]: [metric] };
        } else {
            spendObj = { ...spendObj, [metric.date]: [...spendObj[metric.date], metric] };
        }
    });

    const roasSeries: IChartItem[] = filteredRevenue
        .filter((rev) => spendObj[rev.date])
        .map((rev) => {
            const sameDateSpend: IChartItem[] = spendObj[rev.date];
            const spendMetric = sameDateSpend.reduce((a, c) => a + c.value, 0);
            return {
                ...rev,
                value: calculateROAS(spendMetric || 0, rev.value),
            };
        });

    const legendCount = Object.keys(groupBy(roasSeries, "channelName")).length;
    const spendTotal: IDictionary = {};
    const revenueTotal: IDictionary = {};

    Object.keys(spendGrpByDate).forEach((spend) => {
        spendTotal[spend] = spendGrpByDate[spend]?.reduce((a, c) => a + +(c.value || 0), 0) || 0;
    });

    Object.keys(revenueGrpByDate).forEach((revenue) => {
        revenueTotal[revenue] = revenueGrpByDate[revenue]?.reduce((a, c) => a + +(c.value || 0), 0) || 0;
    });

    const roasTotal = Object.keys(revenueTotal).map((revenue) => {
        return {
            date: revenue,
            value: calculateROAS(spendTotal[revenue], revenueTotal[revenue]),
        };
    });

    if (legendCount < 2) {
        return roasSeries;
    }

    return [
        ...roasTotal.map((r) => ({
            date: r.date,
            value: r.value,
            name: "Total",
            channelName: "Total",
            seriesName: "total",
        })),
        ...roasSeries,
    ];
};

const calculateROASLegend = (
    filteredSpend: IChartItem[],
    filteredRevenue: IChartItem[],
    revenueGrpByChannel: IDictionary<IChartItem[]>,
) => {
    let roasLegend: IChartItemLegend[] = [];
    const totalSpend = filteredSpend?.reduce((ac, spend) => ac + spend.value, 0) || 0;
    const totalRevenue = filteredRevenue.reduce((a, c) => a + c.value, 0);

    Object.entries(revenueGrpByChannel).map(([key, channelRevenue]) => {
        roasLegend = [
            ...roasLegend,
            {
                name: key,
                seriesName: channelRevenue[0].seriesName,
                value: calculateROAS(totalSpend, channelRevenue?.reduce((ac, revenue) => ac + revenue.value, 0) || 0),
            },
        ];
    });

    if (roasLegend.length === 1) {
        return roasLegend;
    }

    return [
        {
            name: "Total",
            value: calculateROAS(totalSpend, totalRevenue),
            seriesName: "total",
        },
        ...roasLegend,
    ];
};

const handleROAS = (dataByMetric: IKpiPerformanceMetric) => {
    const { spend, revenue } = dataByMetric;
    const isDataAvailable = revenue?.length && spend?.length;

    if (!isDataAvailable) {
        return { series: [], legend: [] };
    }

    const filteredSpend = getDefaultDataSource("spend", spend);
    const filteredRevenue = getDefaultDataSource(
        "revenue",
        revenue.filter((r) => r.type === "Transactional"),
    );

    const spendGrpByDate = groupByAndSortByKey(filteredSpend, "date");
    const revenueGrpByDate = groupByAndSortByKey(filteredRevenue, "date");

    const revenueGrpByChannel = groupByAndSortByKey(filteredRevenue, "channelName");

    const roasSeries: IChartItem[] = calculateROASSeries(
        filteredRevenue,
        filteredSpend,
        spendGrpByDate,
        revenueGrpByDate,
    );
    const roasLegend: IChartItemLegend[] = calculateROASLegend(filteredSpend, filteredRevenue, revenueGrpByChannel);

    return {
        series: roasSeries,
        legend: roasLegend,
    };
};

const calculateTotalByDate = (groupByDate: IDictionary<IChartItem[]>) =>
    Object.keys(groupByDate).reduce((acc: IDictionary, date) => {
        const total = groupByDate[date].reduce((sum, entry) => sum + entry.value, 0);
        acc[date] = { date, value: total };
        return acc;
    }, {});

export const handleModeledPercentageOfEcommerceRevenue = (
    dataByMetric: IKpiPerformanceMetric,
    dataSourcesByProgrammaticName: IDictionary<Partial<IDataSource>>,
) => {
    let finalSeries: IChartItem[] = [];
    const { modeled_revenue = [], revenue = [] } = dataByMetric;
    const markietingTargetGroup = groupBy(
        revenue.filter((r) => r.type === "Transactional"),
        "name",
    );

    const defaultMarketingRevenue = getDefaultRevenueFromReveueArr(
        Object.keys(markietingTargetGroup).map((g) => g + "_revenue"),
    )?.replace("_revenue", "");

    const allMarketingData = [
        ...(defaultMarketingRevenue ? markietingTargetGroup[defaultMarketingRevenue] : []),
        ...(markietingTargetGroup.amazon_selling_partner || []),
    ];

    const targetGroup = groupBy(modeled_revenue, "target");
    const targetKeys = Object.keys(targetGroup);

    const defaultSource = getDefaultRevenueFromReveueArr(targetKeys);
    const seriesTargetKeys = targetKeys.filter((key) => [defaultSource, AMAZON_SELLING_PARTNER_REVENUE].includes(key));

    const channelReportedRevenueGrpByDate = groupByAndSortByKey(allMarketingData, "date");
    const channelReportedRevenueTotalByDate = calculateTotalByDate(channelReportedRevenueGrpByDate);

    const createSeriesObj = (revenueByDate: IDictionary, source: string): IDictionary =>
        Object.keys(revenueByDate).reduce((acc: IDictionary, date) => {
            const channelName = dataSourcesByProgrammaticName[source]?.name || "Total";
            acc[date] = {
                date,
                value: calculatePercentage(
                    revenueByDate[date]?.value ?? 0,
                    channelReportedRevenueTotalByDate[date]?.value ?? 0,
                ),
                channelName,
                name: channelName,
                seriesName: source || "total",
            };
            return acc;
        }, {});

    // Add series data for each target key
    seriesTargetKeys.forEach((target) => {
        const modeledRevenueByDate = groupByAndSortByKey(targetGroup[target], "date");
        const modeledRevenueTotalByDate = calculateTotalByDate(modeledRevenueByDate);
        const modeledSeriesObj = createSeriesObj(modeledRevenueTotalByDate, target.replace("_revenue", ""));
        finalSeries.push(...Object.values(modeledSeriesObj));
    });

    const totalModeledRevenue = calculateTotalMetricValue(modeled_revenue);
    const totalChannelReportedRevenue = calculateTotalMetricValue(allMarketingData);

    let mmmPercentageLegend = seriesTargetKeys.map((key) => {
        const sourceName = key.replace("_revenue", "");
        return {
            name: dataSourcesByProgrammaticName[sourceName]?.name || "Total",
            seriesName: sourceName,
            value: calculatePercentage(calculateTotalMetricValue(targetGroup[key]), totalChannelReportedRevenue),
        };
    });

    if (mmmPercentageLegend.length > 1) {
        mmmPercentageLegend = [
            {
                name: "Total",
                value: calculatePercentage(totalModeledRevenue, totalChannelReportedRevenue),
                seriesName: "total",
            },
            ...mmmPercentageLegend,
        ];

        const totalModeledRevenueGrpByDate = groupByAndSortByKey(modeled_revenue, "date");
        const totalModeledSeriesObj = createSeriesObj(calculateTotalByDate(totalModeledRevenueGrpByDate), "total");

        finalSeries = [...Object.values(totalModeledSeriesObj), ...finalSeries];
    }

    return {
        series: finalSeries,
        legend: mmmPercentageLegend,
    };
};

const calculateCACSeries = (
    spendGrpByDate: IDictionary<IChartItem[]>,
    newCustomersGrpByDate: IDictionary<IChartItem[]>,
) => {
    const cacSeries: IChartItem[] = [];

    for (const [key, customers] of Object.entries(newCustomersGrpByDate)) {
        const totalSpend = spendGrpByDate[key]?.reduce((a, c) => a + c.value, 0) || 0;
        for (const c of customers) {
            cacSeries.push({
                ...c,
                value: calculateCAC(totalSpend, c.value),
            });
        }
        const totalCustomers = customers.reduce((a, c) => a + c.value, 0);
        if (customers.length > 1) {
            cacSeries.push({
                name: "Total",
                seriesName: "Total",
                channelName: "Total",
                value: calculateCAC(totalSpend, totalCustomers),
                date: key,
            });
        }
    }

    return cacSeries;
};

const calculateCACLegend = (filteredSpend: IChartItem[], filteredNewCustomers: IChartItem[]) => {
    const cacLegend: IChartItemLegend[] = [];
    const totalSpend = filteredSpend?.reduce((ac, spend) => ac + spend.value, 0) || 0;
    const totalNewCustomers = filteredNewCustomers?.reduce((a, c) => a + c.value, 0);

    const newCustomersGrpByChannel = groupByAndSortByKey(filteredNewCustomers, "name");

    Object.entries(newCustomersGrpByChannel).map(([key, customers]) => {
        cacLegend.push({
            name: customers[0].channelName,
            value: calculateCAC(
                totalSpend,
                customers.reduce((a, c) => a + c.value, 0),
            ),
        });
    });

    if (cacLegend.length === 1) {
        return cacLegend;
    }

    return [
        {
            name: "Total",
            value: calculateCAC(totalSpend, totalNewCustomers),
        },
        ...cacLegend,
    ];
};

const handleCAC = (dataByMetric: IKpiPerformanceMetric, ecommerceState: KeyKPIPerformanceTabTypes) => {
    const { spend, new_customers } = dataByMetric;
    const isDataAvailable = new_customers?.length && spend?.length;

    if (!isDataAvailable) {
        return { series: [], legend: [] };
    }

    const filteredSpend = getDefaultDataSource(ecommerceState, spend);
    const filteredNewCustomers = getDefaultDataSource(ecommerceState, new_customers);

    const spendGrpByDate = groupByAndSortByKey(filteredSpend, "date");
    const newCustomersGrpByDate = groupByAndSortByKey(filteredNewCustomers, "date");

    const cacSeries: IChartItem[] = calculateCACSeries(spendGrpByDate, newCustomersGrpByDate);
    const cacLegend: IChartItemLegend[] = calculateCACLegend(filteredSpend, filteredNewCustomers);

    return { series: cacSeries, legend: cacLegend };
};

const calculateAvgOrdersSeries = (
    revenueGrpByDate: Readonly<IDictionary<IChartItem[]>>,
    ordersGrpByDate: Readonly<IDictionary<IChartItem[]>>,
) => {
    const aovSeries: IChartItem[] = [];

    for (const [key, revenues] of Object.entries(revenueGrpByDate)) {
        for (const revenue of revenues) {
            const orders = ordersGrpByDate[key]?.find((o) => o.name === revenue.name);
            if (orders) {
                aovSeries.push({
                    ...revenue,
                    value: calculateAverageOrder(revenue.value, orders.value),
                });
            }
        }
    }

    const total: IChartItem[] = Object.keys(revenueGrpByDate).flatMap((spend) => {
        const revenueTotal = revenueGrpByDate[spend]?.reduce((a, c) => a + c.value, 0) || 0;
        const ordersTotal = ordersGrpByDate[spend]?.reduce((a, c) => a + c.value, 0) || 0;
        return [
            {
                channelName: "Total",
                name: "Total",
                seriesName: "Total",
                value: calculateAverageOrder(revenueTotal, ordersTotal),
                date: spend,
            },
        ];
    });

    return [...total, ...aovSeries];
};

const calculateAvgOrdersLegend = (filteredRevenue: IChartItem[], filteredOrders: IChartItem[]) => {
    const aovLegend: IChartItemLegend[] = [];
    const totalRevenue = filteredRevenue?.reduce((ac, spend) => ac + spend.value, 0) || 0;
    const totalOrders = filteredOrders?.reduce((a, c) => a + c.value, 0);

    const revenueGrpByChannel = groupByAndSortByKey(filteredRevenue, "name");
    const ordersGrpByChannel = groupByAndSortByKey(filteredOrders, "name");

    Object.entries(revenueGrpByChannel).map(([key, revenue]) => {
        const totalChannelRevenue = revenue?.reduce((a, c) => a + c.value, 0) || 0;
        const totalChannelOrders = ordersGrpByChannel[key]?.reduce((a, c) => a + c.value, 0) || 0;
        aovLegend.push({
            name: revenue[0].channelName,
            value: calculateAverageOrder(totalChannelRevenue, totalChannelOrders),
        });
    });

    return [
        {
            name: "Total",
            value: calculateAverageOrder(totalRevenue, totalOrders),
        },
        ...aovLegend,
    ];
};

const handleAverageOrder = (dataByMetric: IKpiPerformanceMetric) => {
    const { revenue, orders } = dataByMetric;
    const isDataAvailable = revenue?.length && orders?.length;

    if (!isDataAvailable) {
        return { series: [], legend: [] };
    }
    const filteredRevenue = getDefaultDataSource("revenue", revenue);
    const filteredOrders = getDefaultDataSource("orders", orders);

    const revenueGrpByDate = groupByAndSortByKey(filteredRevenue, "date");
    const ordersGrpByDate = groupByAndSortByKey(filteredOrders, "date");

    const avgOrdersSeries: IChartItem[] = calculateAvgOrdersSeries(revenueGrpByDate, ordersGrpByDate);
    const avgOrdersLegend: IChartItemLegend[] = calculateAvgOrdersLegend(filteredRevenue, filteredOrders);

    return { series: avgOrdersSeries, legend: avgOrdersLegend };
};

const handleModeledROAS = (dataByMetric: any, dataSourcesByProgrammaticName: IDictionary<IDataSource>) => {
    const { modeled_roas } = dataByMetric;

    const meta = modeled_roas?.meta?.modeledRoas;
    const connectorTotals = meta?.connectorTotals || [];

    if (!modeled_roas || !connectorTotals.length) {
        return { series: [], legend: [] };
    }

    const legend = [
        { name: "Total", value: meta.total || 0, seriesName: "total" },
        ...connectorTotals.map((l: any) => ({
            name: dataSourcesByProgrammaticName[l.connectorName]?.name || l.connectorName,
            value: l.value,
            seriesName: l.connectorName,
        })),
    ];

    return {
        series: modeled_roas.data || [],
        legend,
    };
};

const handleModeledCAC = (dataByMetric: any, dataSourcesByProgrammaticName: IDictionary<IDataSource>) => {
    const { modeled_cac } = dataByMetric;

    const meta = modeled_cac?.meta?.modeledCac;
    const connectorTotals = meta?.connectorTotals || [];

    if (!modeled_cac || !connectorTotals.length) {
        return { series: [], legend: [] };
    }

    const legend = [
        { name: "Total", value: meta.total || 0, seriesName: "total" },
        ...connectorTotals.map((l: any) => ({
            name: dataSourcesByProgrammaticName[l.connectorName]?.name || l.connectorName,
            value: l.value,
            seriesName: l.connectorName,
        })),
    ];

    return {
        series: modeled_cac.data || [],
        legend,
    };
};

export const handleDefaultState = (ecommerceState: KeyKPIPerformanceTabTypes, dataByMetric: IKpiPerformanceMetric) => {
    const currentData = dataByMetric[ecommerceState]
        ? getDefaultDataSource(ecommerceState, dataByMetric[ecommerceState] as IChartItem[])
        : [];
    const grpByChannel = groupBy(currentData, "channelName");
    // Calculate total value across all channels
    const totalValue = currentData.reduce((a, c) => a + c.value, 0);

    const legends: IChartItemLegend[] = Object.keys(grpByChannel).map((channel) => {
        const channelTotal = grpByChannel[channel].reduce((total, item) => total + item.value, 0);
        return {
            seriesName: grpByChannel[channel][0].seriesName,
            name: channel,
            value: channelTotal,
            percentage: calculatePercentage(channelTotal, totalValue),
        };
    });

    let seriesData: IChartItem[] = [];
    let seriesLegend: IChartItemLegend[] = [];

    if (legends.length > 1) {
        const totalSeries = Object.values(
            currentData.reduce((acc, curr) => {
                const date = curr.date as string;
                if (!acc[date]) {
                    acc[date] = {
                        date,
                        value: 0,
                        name: "Total",
                        channelName: "Total",
                        seriesName: "total",
                    };
                }
                acc[date].value += curr.value;
                return acc;
            }, {} as IDictionary),
        );

        seriesLegend = [
            {
                name: "Total",
                value: legends.reduce((a, c) => a + (c.value || 0), 0),
                seriesName: "total",
                percentage: 100,
            },
            ...legends,
        ];
        seriesData = [
            ...totalSeries.map((c) => ({
                ...c,
                percentage: calculatePercentage(c.value, totalValue),
            })),
            ...currentData.map((c) => ({
                ...c,
                percentage: calculatePercentage(c.value, totalValue),
            })),
        ];
    } else {
        seriesData = [
            ...currentData.map((c) => ({
                ...c,
                percentage: calculatePercentage(c.value, totalValue),
            })),
        ];
        seriesLegend = legends;
    }

    return {
        series: seriesData,
        legend: seriesLegend,
    };
};

const handleModeledWebBounceRate = (
    ecommerceState: KeyKPIPerformanceTabTypes,
    dataByMetric: IKpiPerformanceMetric,
    getTimeframe: number,
) => {
    const currentData = dataByMetric[ecommerceState]
        ? getDefaultDataSource(ecommerceState, dataByMetric[ecommerceState] as IChartItem[])
        : [];

    const grpByChannel = groupBy(currentData, "channelName");
    let legends: IChartItemLegend[] = [];
    Object.keys(grpByChannel).map((channel) => {
        legends = [
            ...legends,
            {
                name: channel,
                value: grpByChannel[channel].reduce((a, c) => a + c.value, 0) / getTimeframe,
            },
        ];
    });

    return {
        series: currentData,
        legend: legends,
    };
};

export const makeSeriesData = (
    ecommerceState: KeyKPIPerformanceTabTypes,
    dataByMetric: IKpiPerformanceMetric,
    getTimeframe: number,
    dataSourcesByProgrammaticName: IDictionary<IDataSource>,
): {
    series: IChartItem[];
    legend: IChartItemLegend[];
} => {
    switch (ecommerceState) {
        case "channel_reported_roas":
            return handleChannelReportedROAS(dataByMetric, dataSourcesByProgrammaticName);
        case "roas":
            return handleROAS(dataByMetric);
        case "cac":
            return handleCAC(dataByMetric, ecommerceState);
        case "average_order":
            return handleAverageOrder(dataByMetric);
        case "modeled_roas":
            return handleModeledROAS(dataByMetric, dataSourcesByProgrammaticName);
        case "modeled_cac":
            return handleModeledCAC(dataByMetric, dataSourcesByProgrammaticName);
        case "web_bounce_rate":
            return handleModeledWebBounceRate(ecommerceState, dataByMetric, getTimeframe);
        case "modeled_percentage_of_ecommerce_revenue":
            return handleModeledPercentageOfEcommerceRevenue(dataByMetric, dataSourcesByProgrammaticName);
        default:
            return handleDefaultState(ecommerceState, dataByMetric);
    }
};

export const addChannelNameToData = (
    data: IChartItem[],
    dataSourcesByProgrammaticName: IDictionary<IDataSource>,
): IChartItem[] =>
    data.map((s) => ({
        ...s,
        value: s.value,
        date: s.date,
        name: s.name,
        channelName: dataSourcesByProgrammaticName[s.name.toLowerCase()]?.name || startCase(s.name.toLowerCase()),
    }));

export const mapAttributionSeriesData = (res: IChartItem[]) => {
    const allTotalValue = res.filter((r) => r.seriesName === "total").reduce((ac, el) => ac + el.value, 0);
    const channelGrp = groupBy(res, "channelName");
    return Object.keys(channelGrp)
        .map((channel) => {
            const oneChannelGrpDate = groupBy(channelGrp[channel], "date");
            return Object.keys(oneChannelGrpDate).map((channelDate) => {
                const totalDate = oneChannelGrpDate[channelDate].reduce((a, c) => a + c.value, 0);
                return {
                    date: channelDate,
                    channelName: channel,
                    name: channel,
                    value: totalDate,
                    seriesName: oneChannelGrpDate[channelDate][0].seriesName,
                    percentage: calculatePercentage(totalDate, allTotalValue),
                };
            });
        })
        .flat(1);
};

export const haloMetric: IDictionary = {
    direct: { name: "Direct traffic" },
    organic: { name: "Organic search" },
    direct_spend: { name: "Paid search" },
    amazon_spend: { name: "Paid search - Amazon selling partner" },
};

const formatSeriesItem = (params: any[], label: string) => {
    const items = params
        .map((param: any) => {
            const formattedValue = `${formatValue(param.value[0], FORMATS.PERCENT, 2)} (${formatValue(
                param.value[2],
                FORMATS.DOLLAR,
                0,
            )})`;
            return `<li style="list-style:none;display:flex;justify-content:space-between;">
                    <span style="display:inline-block;">${param.marker}${param.seriesName}</span>
                    <span style="margin-left:20px;"><b>${formattedValue}</b></span>
                </li>`;
        })
        .join("");

    return `${items}`;
};

const getSeriesHeading = (name: string, percentage?: number, value?: number) => {
    return `<li style="list-style:none;display:flex;justify-content:space-between;margin:10px 0">
    <div style="width:100%;border-bottom:1px solid gray;display:flex;justify-content:space-between;">
        <span>${name}</span>
       ${
           value
               ? `<b>${formatValue(percentage, FORMATS.PERCENT, 2)} (${formatValue(value, FORMATS.DOLLAR, 0)})</b>`
               : ""
       }
    </div>
</li>`;
};

const singleLine = (key: string, value: string) => {
    return `<li style="list-style:none;display:flex;justify-content:space-between;">
                <span style="display:inline-block;">${key}</span>
                <span style="margin-left:20px;"><b>${value}</b></span>
            </li>`;
};

const formatHaloBreakdownTooltip = (params: any[], totalHalo: number) => {
    const haloEffectHeading = getSeriesHeading("Halo Effect Breakdown");

    return (
        haloEffectHeading +
        params
            .filter((p: any) => p.seriesName.includes("Halo"))
            .map((param: any) => {
                const targetHaloChannels = groupBy(param.value[3], "targetChannelName");
                const organicByChannels = groupBy(targetHaloChannels.organic, "target");

                return Object.keys(targetHaloChannels)
                    .sort()
                    .map((target) => {
                        if (
                            target === "organic" &&
                            Object.keys(organicByChannels).includes(AMAZON_SELLING_PARTNER_REVENUE)
                        ) {
                            return Object.keys(organicByChannels)
                                .map((key) => {
                                    const amazonTotal =
                                        organicByChannels.amazon_selling_partner_revenue?.reduce(
                                            (a, c) => a + c.value,
                                            0,
                                        ) || 0;

                                    if (key === AMAZON_SELLING_PARTNER_REVENUE) {
                                        return singleLine(
                                            "Organic search - Amazon selling partner",
                                            `${formatValue(
                                                calculatePercentage(amazonTotal, totalHalo),
                                                FORMATS.PERCENT,
                                                2,
                                            )} (${formatValue(amazonTotal, FORMATS.DOLLAR, 0)})`,
                                        );
                                    } else {
                                        const remianingTotal =
                                            targetHaloChannels[target].reduce((a, c) => a + c.value, 0) - amazonTotal;

                                        return singleLine(
                                            "Organic search - Amazon selling partner",
                                            `${formatValue(
                                                calculatePercentage(remianingTotal, totalHalo),
                                                FORMATS.PERCENT,
                                                2,
                                            )} (${formatValue(remianingTotal, FORMATS.DOLLAR, 0)})`,
                                        );
                                    }
                                })
                                .join("");
                        } else {
                            const total = targetHaloChannels[target]?.reduce((a, c) => a + c.value, 0) || 0;
                            return singleLine(
                                haloMetric[target]?.name || target.replace("_", " "),
                                `${formatValue(
                                    calculatePercentage(total, totalHalo),
                                    FORMATS.PERCENT,
                                    2,
                                )} (${formatValue(total, FORMATS.DOLLAR, 0)})`,
                            );
                        }
                    })
                    .join("");
            })
            .join("")
    );
};

export const baseVsHaloTooltip = (dataSourcesByProgrammaticName: any, params: any) => {
    const haloParams = params.filter((p: any) => p.seriesName.includes("Halo"));
    const baseParams = params.filter((p: any) => !p.seriesName.includes("Halo"));

    const allTotalSeries = params.reduce((a: number, p: { value: any[] }) => a + p.value[2], 0);

    const totalHalo = params
        .filter((p: any) => p.seriesName.includes("Halo"))
        .reduce((a: any, c: any) => a + c.value[2], 0);
    const totalHaloPer = calculatePercentage(totalHalo, allTotalSeries);

    const totalBase = params
        .filter((p: any) => !p.seriesName.includes("Halo"))
        .reduce((a: any, c: any) => a + c.value[2], 0);
    const totalBasePer = calculatePercentage(totalBase, allTotalSeries);

    const header = `<li style="list-style:none;display:flex;justify-content:space-between;margin-bottom:5px;border-bottom:1px solid gray;padding:0 0 8px 0;align-items:center;">
                        <div style="display:flex;align-items:center;gap:2px">${
                            params[0].name === "Total"
                                ? ""
                                : `<img src="${getDataSourceImage(
                                      dataSourcesByProgrammaticName,
                                      params[0].value[3][0].name,
                                  )}" height="24px" width="24px" alt="image" />`
                        }<div style="margin-top:0">${params[0].name}</div></div>
                        <span style="margin-left:20px;"><b>100.00% (${formatValue(
                            allTotalSeries,
                            FORMATS.DOLLAR,
                            0,
                        )})</b></span>
                    </li>`;

    const baseSeriesItem = baseParams.length ? `${formatSeriesItem(baseParams, "Base")}` : "";
    const haloSeriesItem = `${formatSeriesItem(haloParams, "Halo")}`;

    const haloBreakdownTooltip = haloParams.length
        ? `${haloSeriesItem}${formatHaloBreakdownTooltip(haloParams, totalHalo)}`
        : "";

    return `${header}${baseSeriesItem}${haloBreakdownTooltip}`;
};

const getTargetByEcommerceState: IDictionary = {
    modeled_revenue: "revenue",
    modeled_customers: "new_customers",
    modeled_roas: "revenue",
    modeled_cac: "new_customers",
};

export const processApiResponse = (
    apiResponse: IKpiPerformanceMetric,
    channelGrpBy: string,
    ecommerceState: KeyKPIPerformanceTabTypes,
    isAttributionTimeSeries: any,
    spendData: any,
    getTimeframe: number,
    dataSourcesByProgrammaticName: IDictionary<IDataSource>,
) => {
    const selectedMetricKeys = Object.keys(apiResponse);
    if (selectedMetricKeys.length) {
        const grpByApiResponse: IDictionary = {};
        selectedMetricKeys.forEach((c) => {
            grpByApiResponse[c] =
                channelGrpBy === "total"
                    ? apiResponse[c as keyof IKpiPerformanceMetric]
                    : groupBy(apiResponse[c as keyof IKpiPerformanceMetric], "target")[
                          `${channelGrpBy}_${getTargetByEcommerceState[ecommerceState]}`
                      ];
        });

        return makeSeriesData(
            ecommerceState,
            isAttributionTimeSeries && !["modeled_roas"].includes(ecommerceState)
                ? { ...grpByApiResponse, spend: spendData }
                : apiResponse,
            getTimeframe,
            dataSourcesByProgrammaticName,
        );
    }
    return {
        series: [],
        legend: [],
    };
};

export const getDynamicTrendTableHeader = (startRange: IDateSelectionRange, selectedPreset: string) => {
    const getTypographyLabel = (text: string, range: Omit<IDateRange, "key">) => (
        <Typography fontSize="15px" fontWeight="bold">
            {text} <br /> ({getRangeFormattedTitle([range])})
        </Typography>
    );

    return TREND_TABLE_HEADER.map((h) => {
        switch (h.id) {
            case "current":
                return {
                    ...h,
                    label: getTypographyLabel(startCase(selectedPreset), startRange.selection),
                };
            case "prior":
                return {
                    ...h,
                    label: getTypographyLabel("Vs. Prior Period", startRange.compare),
                };
            default:
                return h;
        }
    });
};
