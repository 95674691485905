import { css } from "@emotion/css";
import { TableBody, TableCell, TableRow, Theme, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Stack, useTheme } from "@mui/system";
import { format } from "date-fns";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";
import {
    IOnboardingItemTooltip,
    OnboardingTooltip,
    Placements,
} from "src/components/OnboardingTooltip/OnboardingTooltip";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { NEW_CONNECTOR_PATH, TACTICS_PATH } from "src/consts/path/path";
import { UserRole } from "src/enums/UserRole";
import { DataSourceCategory } from "src/enums/dataSourceCategory";
import { IOrganization } from "src/interfaces/entities/IOrganization";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { selectCurrentlyViewingCompanyById } from "src/reduxState/slices/organizationSlice";
import { currentUserRoleByCompanyIdSelector, isSuperAdminSelector } from "src/reduxState/slices/userSlice";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { CustomTableStructure } from "../../../components/core/CustomTableStructure/CustomTableStructure";
import {
    Choice,
    IHeadCell,
    PerformanceTab,
    TOTAL_IN_METRIC_ATTRIBUTION_TABLE,
    getAllPerformanceTableHeader,
} from "../../../consts/performancePaidPage/performancePaidPage";
import { IConnector } from "../../../interfaces/IConnector";
import { IMetricAttributionTableValuesTransformed } from "../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import {
    isCampaignAvailableSelector,
    isModelingAvailableSelector,
    paginationCountSelector,
} from "../../../reduxState/slices/settingsSlice";
import { NOT_SET_TACTIC, fixedPerformanceHeader } from "../../../services/performancePage/performancePage";
import { getComparator, sortNegativeAtLast, stableSort } from "../../../utils/sort";
import { CampaignTableHeader } from "./CampaignTableHeader";
import { CampaignTableRow } from "./CampaignTableRow";

interface ICampaignTable {
    isLoading?: boolean;
    isImagesInTable?: boolean;
    tableData: IMetricAttributionTableValuesTransformed[];
    selectedTableColumns: IHeadCell[];
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setOrder: React.Dispatch<React.SetStateAction<Choice>>;
    order: Choice;
    orderBy: string;
    setOrderBy: React.Dispatch<React.SetStateAction<string>>;
    totalAmount: any;
    currentCampaignsDate: string;
    isChannelRow?: boolean;
    handleChannelClick: (row: any) => void;
    activePerformanceTab: PerformanceTab;
    orgCode: string;
}

export const useStylesForCampaignTable = (theme: Theme) => ({
    tableBody: css({
        borderCollapse: "separate",
    }),
    tableBorderClass: css({
        position: "absolute",
        height: "calc(100% - 36px)",
        marginLeft: "-2px",
        width: "calc(100% + 4px)",
        top: "36px",
        border: "1px solid rgba(0, 0, 0, 0.50)",
        [theme.breakpoints.down("md")]: {
            top: "38px",
            height: "calc(100% - 38px)",
        },
    }),
    noBorderClass: css({
        border: "0 !important",
        background: "unset",
    }),
    paginationClass: css({
        background: "white",
    }),
});

export const useStylesForEmptyTableBody = (theme: Theme) => ({
    tableRowClass: css({
        backgroundColor: "white",
    }),
    textClass: css({
        color: theme.palette.tertiary.main,
    }),
});

export const CampaignTable: React.FC<ICampaignTable> = ({
    isImagesInTable,
    tableData,
    selectedTableColumns,
    page,
    setPage,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    totalAmount,
    currentCampaignsDate,
    isChannelRow,
    handleChannelClick,
    activePerformanceTab,
    orgCode,
}) => {
    const classes = { ...useStylesForCampaignTable(useTheme()), ...useStylesForEmptyTableBody(useTheme()) };
    const navigate = useNavigate();
    const analyticsService = useAnalyticsService();
    const rowsPerPage = useSelector(paginationCountSelector);
    const currentOrg: IOrganization = useSelector(selectCurrentlyViewingCompanyById);
    const connectorChannelButtonRef = useRef<any>(null);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const isCampaignAvailable = useSelector(isCampaignAvailableSelector);
    const isModelingAvailable = useSelector(isModelingAvailableSelector);

    useEffect(() => {
        window.addEventListener("scroll", fixedPerformanceHeader);
        return () => {
            window.removeEventListener("scroll", fixedPerformanceHeader);
        };
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        setPage(0);
    };

    const goToCreateConnPage = () => {
        navigate(`/org/${orgCode}${NEW_CONNECTOR_PATH}`);
    };

    const gotoTacticSettingsPage = () => {
        analyticsService.logEvent(`Performance Tactics Label Button`, {});
        navigate(`/org/${currentOrg.code}${TACTICS_PATH}`);
    };

    const connectors: IConnector[] = useSelector(connectorsSelector);
    const advertisingConnectors = connectors.filter((conn) =>
        conn.dataSource?.categories?.includes(DataSourceCategory.ADVERTISING),
    );

    const isNumericColumn = getAllPerformanceTableHeader(connectors).find((h) => h.id === orderBy)?.numeric || false;
    const notSetTactic = NOT_SET_TACTIC.displayName;
    const isAdmin = userRole === UserRole.ADMIN || isSuperAdmin;

    const onboardingTooltipCards: IOnboardingItemTooltip[] = [
        {
            title: "Connect Your Channels",
            description: "Channels allow you to leverage data across your marketing mix",
        },
    ];

    const tableRows =
        activePerformanceTab === PerformanceTab.Tactics
            ? sortNegativeAtLast(
                  stableSort(tableData, getComparator(order, orderBy)),
                  order,
                  orderBy,
                  isNumericColumn,
              ).sort((a, b) => {
                  // Move items with empty tactic to the end
                  if (a.tacticName === notSetTactic && b.tacticName !== notSetTactic) {
                      return 1;
                  }
                  if (a.tacticName !== notSetTactic && b.tacticName === notSetTactic) {
                      return -1;
                  }
                  // Otherwise, keep the order unchanged
                  return 0;
              })
            : sortNegativeAtLast(stableSort(tableData, getComparator(order, orderBy)), order, orderBy, isNumericColumn);

    const setLabelTacticClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        gotoTacticSettingsPage();
    };

    return (
        <div style={{ position: "relative" }}>
            <div className={classes.tableBorderClass}></div>
            <CustomTableStructure
                paginationProps={{
                    count: tableData.length,
                    page,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    extraInfo: currentCampaignsDate
                        ? `Last model run date: ${format(new Date(currentCampaignsDate), "MMM d, yyyy")}`
                        : "",
                    className: classes.paginationClass,
                }}
                showPagination={tableRows.length > 0}
                tableProps={{ id: "performance-table", size: "small", className: classes.tableBody }}
                className={classes.noBorderClass}
            >
                <CampaignTableHeader
                    onRequestSort={handleRequestSort}
                    choice={order}
                    choiceBy={orderBy}
                    selectedTableColumns={selectedTableColumns}
                />
                <TableBody>
                    <>
                        {isCampaignAvailable && (
                            <CampaignTableRow
                                totalRows={Math.min(tableRows.length + 1, rowsPerPage + 1)}
                                totalAmount={totalAmount}
                                row={TOTAL_IN_METRIC_ATTRIBUTION_TABLE}
                                selectedTableColumns={selectedTableColumns}
                                activePerformanceTab={activePerformanceTab}
                                isModelingAvailable={isModelingAvailable}
                            />
                        )}
                        {tableRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: IMetricAttributionTableValuesTransformed, index: number) => {
                                return (
                                    <CampaignTableRow
                                        isImagesInTable={isImagesInTable}
                                        key={index}
                                        row={row}
                                        selectedTableColumns={selectedTableColumns}
                                        isChannelRow={isChannelRow}
                                        handleChannelClick={handleChannelClick}
                                        activePerformanceTab={activePerformanceTab}
                                        isModelingAvailable={isModelingAvailable}
                                        gotoTacticSettingsPage={(e) => setLabelTacticClick(e)}
                                    />
                                );
                            })}
                    </>

                    {connectors.length === 0 && (
                        <TableRow className={classes.tableRowClass}>
                            <TableCell
                                colSpan={selectedTableColumns.length}
                                sx={{ padding: "16px", borderBottom: "0px" }}
                            >
                                {isAdmin && (
                                    <div style={{ position: "relative", paddingLeft: "20px", width: "fit-content" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={goToCreateConnPage}
                                            ref={connectorChannelButtonRef}
                                            style={{ width: "300px", height: "30px", padding: "0px" }}
                                        >
                                            Connect Channel
                                            <div style={{ padding: "6px 0px 0px 30px" }}>
                                                <SVGIconRenderer
                                                    icon="chevronRightIcon"
                                                    strokeColor="white"
                                                    height="16px"
                                                    width="16px"
                                                />
                                            </div>
                                        </Button>
                                        <OnboardingTooltip
                                            targets={[connectorChannelButtonRef]}
                                            cards={onboardingTooltipCards}
                                            placement={Placements.RIGHT}
                                            delay={5000}
                                        />
                                    </div>
                                )}
                                <Typography variant="body2">There are no connected channels.</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {connectors.length > 0 &&
                        !isCampaignAvailable &&
                        advertisingConnectors.map((connector: IConnector, index: number) => {
                            return (
                                <TableRow className={classes.tableRowClass}>
                                    <TableCell colSpan={1} sx={{ padding: "16px", borderBottom: "0px" }}>
                                        <Stack gap={2} direction="row" alignItems="center" justifyContent="flex-start">
                                            <DataSourceAvatar
                                                programmaticName={connector.dataSource?.programmaticName}
                                                sx={{ width: "40px", height: "40px" }}
                                            />
                                            <Typography variant="body2">{connector.connectorName}</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell
                                        colSpan={selectedTableColumns.length - 1}
                                        sx={{ padding: "16px", borderBottom: "0px" }}
                                    ></TableCell>
                                </TableRow>
                            );
                        })}
                    {connectors.length > 0 && !isCampaignAvailable && (
                        <div style={{ position: "absolute", textAlign: "center", width: "100%", top: "150px" }}>
                            {isAdmin ? (
                                <>
                                    <Typography variant="body2" sx={{ paddingBottom: "16px" }}>
                                        Finish connecting all your channels, and verify your values to activate your
                                        metrics.
                                    </Typography>
                                    <Button variant="contained" color="primary" onClick={goToCreateConnPage}>
                                        Finish Connecting Channels
                                    </Button>
                                </>
                            ) : (
                                <Typography variant="body2">
                                    We are gathering your insights and getting everything in place.
                                </Typography>
                            )}
                        </div>
                    )}
                </TableBody>
            </CustomTableStructure>
        </div>
    );
};
