import { Fragment } from "react";
import { TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import groupBy from "lodash/groupBy";

import { SVGIconRenderer } from "../../../../components/SVGIconRenderer/SVGIconRenderer";
import { FORECASTED_CAMPAIGNS_HEADERS } from "../../../../consts/optimizationPage/optimizationPage";
import { ICard, IScenario } from "../../../../interfaces/entities/IScenario";
import { IDictionary } from "src/interfaces/IDictionary";
import { getScenarioTypeLabel } from "src/services/optimizationPage/optimization";

export const OutcomeTableHeader = ({
    cards,
    scenario,
    isChannelHeader = false,
    isTacticHeader = false,
    compareToLabel,
}: {
    cards: ICard[];
    scenario: IScenario;
    isChannelHeader?: boolean;
    isTacticHeader?: boolean;
    compareToLabel: string;
}) => {
    const filteredHeader = cards ? cards.filter((h) => !h.hidden) : [...FORECASTED_CAMPAIGNS_HEADERS];

    const tableHeadRow = (col: number): IDictionary => {
        return {
            other: <TableCell colSpan={col}></TableCell>,
            expected: (
                <TableCell
                    sx={{
                        fontWeight: "700",
                        fontSize: "15px",
                    }}
                    colSpan={col}
                    align="center"
                >
                    {`Expected (${compareToLabel})`}
                </TableCell>
            ),
            optimal: (
                <TableCell
                    sx={{
                        fontWeight: "700",
                        fontSize: "15px",
                    }}
                    colSpan={col}
                    align="center"
                >
                    {getScenarioTypeLabel[scenario.scenarioType]}
                </TableCell>
            ),
        };
    };

    return (
        <TableHead>
            <TableRow data-cy="outcomeTableHeader">
                {Object.entries(groupBy(filteredHeader, "groupName")).map(([key, element]: any) => {
                    return <Fragment key={key}>{tableHeadRow(element.length)[key]}</Fragment>;
                })}
            </TableRow>
            <TableRow data-cy="outcomeTableSubHeader">
                {filteredHeader.map((campaignHeader: any, index: any) => {
                    return (
                        <TableCell
                            key={index}
                            align={campaignHeader.id === "campaignName" ? "left" : "right"}
                            sx={{
                                fontWeight: "700",
                                border: "solid rgba(224, 224, 224, 1)",
                                borderWidth: "0 0 1px 0",
                                fontSize: "15px",
                            }}
                        >
                            {isChannelHeader && campaignHeader.id === "campaignName"
                                ? "Channels"
                                : isTacticHeader && campaignHeader.id === "campaignName"
                                ? "Tactics"
                                : campaignHeader.label}

                            {campaignHeader.id === "confidenceScore" && (
                                <Tooltip
                                    arrow
                                    title="Confidence is an algorithmically calculated score for each campaign recommended below which takes into account the volume of data available, the width of the upper and lower bounds, and finally the density of days at the allocated spend level over time."
                                >
                                    <span>
                                        <SVGIconRenderer
                                            icon="infoIcon"
                                            width="16px"
                                            height="16px"
                                            style={{ marginBottom: "-2px", marginLeft: "5px" }}
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};
