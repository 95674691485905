import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CustomModal } from "../../components/CustomModal/CustomModal";
import { PermissionForm } from "../../components/MemberForm/PermissionForm";
import { UserForm } from "../../components/MemberForm/UserForm";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import { SubHeader } from "../../components/SubHeader/SubHeader.v2";
import Loader from "../../components/core/Loader/Loader";
import { HeaderSize, Modal } from "../../components/core/Modal/Modal";
import { IMember } from "../../interfaces/entities/IMember";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import {
    useDeleteMemberMutation,
    useEditAdminMemberMutation,
    useLazyGetAllMembersQuery,
    useReinviteMemberWithoutOrgMutation,
    useSuperadminCreateUserMutation,
} from "../../reduxState/apis/memberApi";
import { demoModeSelector } from "../../reduxState/slices/demoModeSlice";
import { membersSelector } from "../../reduxState/slices/membersSlice";
import { selectCurrentlyViewingId, selectListOfOrganizations } from "../../reduxState/slices/organizationSlice";
import { userIdSelector, userStateSelector } from "../../reduxState/slices/userSlice";
import {
    getFilteredValue,
    getSearchedAndFilteredMembers,
    isSelect,
} from "../../services/SuperAdminMembers/SuperAdminMembers";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { capitalizeFirstLetter, getPathForAnalytics } from "../../services/utils";
import { transformApiErrors } from "../../utils/transformApiErrors";
import { ReInviteMemberModal } from "../Members/ReInviteMemberModal";
import { SuperAdminMembersTable } from "./SuperAdminMemberTable/SuperAdminMemberTable";

export const SuperAdminMembers: React.FC = () => {
    const [isFormEditMode, setIsFormEditMode] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [member, setMember] = useState<IMember>({} as unknown as IMember);
    const [addEntity, setAddEntity] = useState<any>("");
    const [isRowClickEvent, setIsRowClickEvent] = useState(false);

    const [getMembers, { isLoading }] = useLazyGetAllMembersQuery();
    const [deleteMember, { isLoading: isDeleteMember }] = useDeleteMemberMutation();
    const [createMember] = useSuperadminCreateUserMutation();
    const [editAdminMember] = useEditAdminMemberMutation();
    const members = useSelector(membersSelector);
    const companiesById = useSelector(selectListOfOrganizations);
    const currentUser = useSelector(userStateSelector);
    const currentOrgId = useSelector(selectCurrentlyViewingId);

    const [selectedMembers, setSelectedMembers] = useState<Array<number | string>>([]);
    const [filter, setFilter] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [isDelete, setIsDelete] = useState(false);

    const companies = Object.values(companiesById);

    const analyticsService = useAnalyticsService();
    const { enqueueSnackbar } = useSnackbar();
    const currentUserId = useSelector(userIdSelector);
    const isDemoMode = useSelector(demoModeSelector);

    const [statusModalOpen, setStatusModalOpen] = useState(false);
    const [reinviteMemberWithoutOrg] = useReinviteMemberWithoutOrgMutation();
    const [reinviteBtnDisable, setReinviteBtnDisable] = useState(false);
    const superAdminMailPattern = /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@prescient-ai.io$/;

    useEffect(() => {
        getMembers("companies,users_roles");
    }, []);

    const handleCloseModal = () => {
        setIsFormEditMode(false);
        setIsOpen(false);
        setAddEntity("");
        setIsRowClickEvent(false);
    };

    const handleShowRemoveModal = async () => {
        setIsDelete(true);
    };

    const handleChangeMode = () => {
        setIsFormEditMode(true);
    };

    const handleTableRowClick = (id: string | number, isAction?: string) => {
        setIsOpen(true);
        setAddEntity("addNewUser");
        setIsRowClickEvent(true);

        if (isAction === "edit") {
            handleChangeMode();
        }
        if (isAction === "delete") {
            handleShowRemoveModal();
            setIsOpen(false);
        }

        if (members && members.length) {
            const foundedMember = members.filter((member: IMember) => +member.id === +id);
            if (foundedMember.length) {
                let memberData = foundedMember[0];
                let roles: any[] = [];

                if (memberData && memberData.rolesByCompanyId) {
                    companies.forEach((c: any) => {
                        if (memberData && memberData.companies?.includes(c.id)) {
                            const rolesByCompanyId = memberData.rolesByCompanyId || {};
                            const roleObj: any = Object.values(rolesByCompanyId).find(
                                (role: any) => role.companyId === c.id,
                            );
                            roles = [...roles, { role: roleObj.role, company: c }];
                        }
                    });

                    memberData = { ...memberData, roles };
                }

                const foundedOrganization = !memberData.companies
                    ? null
                    : companies.filter(
                          (company: IOrganization) => memberData.companies && memberData.companies.includes(company.id),
                      );
                setMember({
                    ...memberData,
                    company: foundedOrganization && foundedOrganization.length ? foundedOrganization[0].name : "",
                });
            }
        }
    };

    const memberInvitedEvent = (values: IMember) => {
        analyticsService.logEvent("Member Invited", {
            "New member organization ID": currentOrgId,
            "New member organization role": values.role,
            "New member picture uploaded:": values.avatar ? true : false,
        });
    };

    const handleSubmit = async (values: IMember, formikHelpers: FormikHelpers<IMember>) => {
        if (!values.id) {
            await createMemberAction(values, formikHelpers);
        } else {
            await editMemberAction(values, formikHelpers);
        }
    };
    const editMemberAction = async (values: IMember, formikHelpers: FormikHelpers<IMember>) => {
        const errors = transformApiErrors({ message: "Error" });
        if (values.isSuperadmin && !values.email.match(superAdminMailPattern)) {
            formikHelpers.setErrors({
                ...errors,
                email: "Oops! Emails must end in '@prescient-ai.io' to be made a Super Admin",
            });
            return;
        }

        memberInvitedEvent(values);
        await editAdminMember({
            params: { id: +values.id },
            body: {
                ...values,
                roles: values.roles
                    ?.map((role) => role?.company?.id && { ...role, company: role.company.id })
                    .filter((role) => role !== undefined),
            },
        })
            .unwrap()
            .then(() => getMembers("companies,users_roles"))
            .catch((error) => {
                const errors = transformApiErrors({ message: "Error" });
                formikHelpers.setErrors(errors);
            })
            .finally(() => {
                handleCloseModal();
                formikHelpers.setSubmitting(false);
            });
    };

    const createMemberAction = async (values: IMember, formikHelpers: FormikHelpers<IMember>) => {
        const errors = transformApiErrors({ message: "Error" });

        if (values.isSuperadmin && !values.email.match(superAdminMailPattern)) {
            formikHelpers.setErrors({
                ...errors,
                email: "Oops! Emails must end in '@prescient-ai.io' to be made a Super Admin",
            });
            return;
        }

        if (
            addEntity !== "addSuperAdmin" &&
            (!values.roles || (values.roles && Array.isArray(values.roles) && values.roles.length === 0))
        ) {
            formikHelpers.setErrors({
                ...errors,
                roles: "Minimum of 1 organization role",
            });
            return;
        }

        memberInvitedEvent(values);
        await createMember({
            body: {
                ...values,
                isSuperadmin: addEntity === "addSuperAdmin",
                roles: values.roles
                    ?.map((role) => role?.company?.id && { ...role, company: role.company.id })
                    .filter((role) => role !== undefined),
            },
        })
            .unwrap()
            .then(() => getMembers("companies,users_roles"))
            .catch((error) => {
                const errors = transformApiErrors({ message: "Error" });
                formikHelpers.setErrors(errors);
            })
            .finally(() => {
                handleCloseModal();
                formikHelpers.setSubmitting(false);
            });
    };

    const handleAnalyticsClick = () => {
        if (currentOrgId) {
            analyticsService.logEvent("Member Invitation Began", {
                "Current organization ID": currentOrgId,
            });
        }
    };

    const handleOpenNewMemberModal = useCallback(() => {
        handleAnalyticsClick();
        setIsOpen(true);
        setMember({} as unknown as IMember);
        setAddEntity("");
    }, []);

    const handleRemoveMember = async () => {
        if (member && member.id) {
            await deleteMember(+member.id)
                .unwrap()
                .then(() => {
                    getMembers("companies,users_roles");
                })
                .catch((error) => {
                    enqueueSnackbar("Error", {
                        id: uniqueId(),
                        variant: "error",
                    });
                })
                .finally(() => {
                    handleCloseModal();
                });
        }
        setIsDelete(false);
    };

    const handleSelectAll = () => {
        if (selectedMembers.length > 0) {
            setSelectedMembers([]);
        } else {
            const filteredValue = getFilteredValue(members, filter, selectedMembers);
            if (filteredValue) {
                setSelectedMembers(filteredValue.map((member) => +member.id));
            }
        }
    };

    const handleStatusClick = (member: IMember) => {
        if (member && member.invitationState) {
            setMember(member);
            analyticsService.logEvent("Member Reinvitation Began", {
                Page: getPathForAnalytics(location.pathname),
                "Member status": capitalizeFirstLetter(member.invitationState),
            });
            setStatusModalOpen(true);
        }
    };

    const handleModalReInvite = () => {
        if (member) {
            setReinviteBtnDisable(true);
            reinviteMemberWithoutOrg({
                userId: member.id,
            })
                .then(() => {
                    if (member.invitationState) {
                        analyticsService.logEvent("Member Reinvited", {
                            Page: getPathForAnalytics(location.pathname),
                            "Member status": capitalizeFirstLetter(member.invitationState),
                        });
                    }
                    getMembers("companies,users_roles");
                })
                .catch((error: any) => {
                    enqueueSnackbar("Error", {
                        id: uniqueId(),
                        variant: "error",
                    });
                    throw new Error(`reinvite member error ${error}`);
                })
                .finally(() => {
                    setStatusModalOpen(false);
                    setReinviteBtnDisable(false);
                });
        }
    };

    const handleModalClose = () => {
        if (member && member.invitationState) {
            analyticsService.logEvent("Member Reinvitation Cancelled", {
                Page: getPathForAnalytics(location.pathname),
                "Member status": capitalizeFirstLetter(member.invitationState),
            });
            setStatusModalOpen(false);
        }
    };

    const getPreparedMembers = () => {
        const newMembers: IMember[] = getSearchedAndFilteredMembers(members, search, filter);
        if (newMembers && newMembers.length) {
            return newMembers.map((member: IMember) => {
                return {
                    ...member,
                    isChecked: isSelect(+member.id, selectedMembers),
                };
            });
        }

        return [];
    };

    const handleSearchChange = (item: string) => {
        setSearch(item);
    };

    const setDeleteMember = (member: IMember) => {
        setMember(member);
    };

    const memberList = getPreparedMembers();
    const modalExistMemberTitle = isFormEditMode ? "Edit member" : "Member details";

    const commonFormProps = {
        initialValues: member as IMember,
        selectedCompanies: companies ? companies.filter((c) => member.companies?.includes(c.id)) : ["No organization"],
        allCompanies: companies,
        isEditMode: isFormEditMode,
        isCurrentUser: Boolean(member && member.id && currentUser && currentUser.id && member.id === currentUser.id),
        onSubmit: handleSubmit,
        onRemove: handleShowRemoveModal,
        onCancel: handleCloseModal,
        onChangeMode: handleChangeMode,
        gotToBack: () => setAddEntity(""),
    };

    const addItemsOptions = [
        {
            label: "Add New Superadmin",
            id: "addSuperAdmin",
            form: (
                <UserForm
                    {...commonFormProps}
                    isSuperAdminForm={true}
                    showBackButton={true}
                    showSelectedCompanies={isRowClickEvent}
                />
            ),
        },
        {
            label: "Add New User",
            id: "addNewUser",
            form: (
                <UserForm
                    {...commonFormProps}
                    isSuperAdminForm={false}
                    showBackButton={!isRowClickEvent}
                    showSelectedCompanies={false}
                />
            ),
        },
        {
            label: "Add New Permission",
            id: "addNewPermission",
            form: (
                <PermissionForm
                    members={members}
                    companies={companies}
                    onSubmit={handleSubmit}
                    onRemove={handleShowRemoveModal}
                    onCancel={handleCloseModal}
                    onChangeMode={handleChangeMode}
                    gotToBack={() => setAddEntity("")}
                    setMember={setDeleteMember}
                    member={member}
                />
            ),
        },
    ];
    const currentEntity = addItemsOptions.find((option) => option.id === addEntity);

    return (
        <div>
            <PageHeader pageHeading="Users" buttonName="Add" onAdd={handleOpenNewMemberModal} disabled={isDemoMode} />
            <PageContainer>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <SubHeader
                            itemsLength={getSearchedAndFilteredMembers(members, search, filter).length}
                            title="User(s)"
                            onSearchChange={handleSearchChange}
                        />
                        <SuperAdminMembersTable
                            selectedMembers={selectedMembers.length}
                            memberList={memberList}
                            handleTableRowClick={!isDemoMode ? handleTableRowClick : () => {}}
                            companies={companies}
                            handleSelectAll={handleSelectAll}
                            onStatusClick={handleStatusClick}
                        />
                    </div>
                )}
            </PageContainer>
            {isOpen && (
                <Modal
                    headerSize={HeaderSize.SMALL}
                    isOpen={isOpen}
                    title={member && Boolean(member.id) ? modalExistMemberTitle : currentEntity?.label || "Add"}
                    canOutsideClickClose={false}
                    onClose={handleCloseModal}
                    content={
                        <>
                            {addEntity === "" && (
                                <FormControl fullWidth>
                                    <InputLabel id="select-type-label">Select type</InputLabel>
                                    <Select
                                        labelId="select-type-label"
                                        onChange={(e) => {
                                            setAddEntity(e.target.value);
                                            setIsOpen(true);
                                            setMember({} as IMember);
                                        }}
                                        defaultValue={addEntity}
                                        label="Select type"
                                        data-cy="select-member-type"
                                    >
                                        {addItemsOptions.map((item) => (
                                            <MenuItem data-cy={item.id} key={item.id} value={item.id}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            {currentEntity?.form}
                        </>
                    }
                />
            )}
            {isDelete && member && (
                <CustomModal
                    title="Remove Member?"
                    subTitle={`Removing ${member.firstName} ${member.lastName} will:`}
                    details={
                        <>
                            <Typography component="li" variant="body2">
                                - Prevent them from accessing any other organization's data
                            </Typography>
                            <Typography component="li" variant="body2">
                                - Delete their Prescient account
                            </Typography>
                        </>
                    }
                    buttonName="Delete"
                    handleAction={handleRemoveMember}
                    selectedConnector={member}
                    closeModal={() => setIsDelete(false)}
                    disableBtn={isDeleteMember}
                    type="error"
                />
            )}
            {statusModalOpen && member && (
                <ReInviteMemberModal
                    isOpen={statusModalOpen}
                    onClose={handleModalClose}
                    onSubmit={handleModalReInvite}
                    handleCancelButtonClick={handleModalClose}
                    member={member}
                    reinviteBtnDisable={reinviteBtnDisable}
                />
            )}
        </div>
    );
};
