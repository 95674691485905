import React from "react";
import {
    format,
    getQuarter,
    getYear,
    parseISO,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
    subDays,
    subYears,
} from "date-fns";
import groupBy from "lodash/groupBy";
import { toDate } from "date-fns-tz";
import { Typography } from "@mui/material";
import camelCase from "lodash/camelCase";
import { grey } from "@mui/material/colors";

import { RelativeDatesInNumber } from "../../components/DateFilter/DateFilter";
import { IHistoricalMetricOverviewItem, IHistoricalMetricData } from "../../interfaces/api/IHistoricalMetricOverview";
import { calculateROAS, capitalizeFirstLetter, stringToCapitalize } from "../utils";
import { chartGrpKey } from "../../consts/performancePaidPage/performancePaidPage";
import { RevenueType } from "../../enums/RevenueType";
import {
    AMAZON_SELLING_PARTNER,
    BIGQUERY_ECOMMERCE,
    GOOGLE_ANALYTICS,
    GOOGLE_ANALYTICS_4,
    SALESFORCE_COMMERCE_CLOUD,
    SHOPIFY,
} from "src/consts/connectors";
import { IPerformanceTrendData } from "src/interfaces/api/IPerformanceTrendResponse";
import { keysToCamelCase } from "src/utils/format";
import { IChartItem } from "src/interfaces/chart/IChartItem";
import { IArguments, IHistoricalArgs } from "src/reduxState/apis/historicalApi";
import { IMetricSeriesResponse } from "src/interfaces/api/IHistoricalMetricSeries";
import { IKpiPerformanceMetric } from "src/interfaces/dashboard/trend";
import { KeyKPIPerformanceTabTypes } from "src/consts/homeDashboard/homeDashboard";
import { calculatePercentage } from "../performancePage/performancePage";
import { IDictionary } from "src/interfaces/IDictionary";

export const getTimeDelta = (firstDate: Date) => {
    const currentDate = new Date();
    const delta = currentDate.getTime() - firstDate.getTime();
    if (delta <= 300000) {
        return "just now";
    }
    if (delta <= 3.6 * Math.pow(10, 6)) {
        return `${Math.floor(delta / 60000)}m ago`;
    }
    if (delta <= 8.28 * Math.pow(10, 7)) {
        return `${Math.floor(delta / 3600000)}h ago`;
    }
    return `${Math.floor(delta / (3600000 * 24))}d ago`;
};

export const getFilterName = (filter: RelativeDatesInNumber) => {
    const { start, end } = getRelativeDateRange(filter);

    if (filter === RelativeDatesInNumber.LAST_12_MONTHS) {
        return `${start.toLocaleString("en-us", { month: "short", year: "numeric" })} - ${end.toLocaleString("en-us", {
            month: "short",
            year: "numeric",
        })}`;
    }

    return `${start.toLocaleString("en-us", { month: "short", day: "numeric" })} - ${end.toLocaleString("en-us", {
        month: "short",
        day: "numeric",
        year: "numeric",
    })}`;
};

export const getRelativeDateRange = (
    condition: RelativeDatesInNumber = RelativeDatesInNumber.LAST_7_DAYS,
): { start: Date; end: Date } => {
    const now = new Date();
    let end = new Date(now);
    let start = new Date(now);

    end = subDays(end, 1);
    start = subDays(start, condition);

    if (condition === RelativeDatesInNumber.LAST_12_MONTHS) {
        subYears(start, 1);
    }

    return { start, end };
};

export const DEFAULT_WEB_ANALYTICS_CONNECTOR = GOOGLE_ANALYTICS_4;
export const SECONDARY_WEB_ANALYTICS_CONNECTOR = GOOGLE_ANALYTICS;
export const DEFAULT_REVENUE_CONNECTOR = BIGQUERY_ECOMMERCE;
export const SECONDARY_REVENUE_CONNECTOR = SALESFORCE_COMMERCE_CLOUD;
export const TERTIARY_REVENUE_CONNECTOR = SHOPIFY;
export const QUATERNARY_REVENUE_CONNECTOR = GOOGLE_ANALYTICS_4;
export const DEFAULT_NEW_CUSTOMERS = SHOPIFY;

export const getAmazonOption = (revReports: IHistoricalMetricOverviewItem[] = []) => {
    const primaryRev = revReports.filter((c) => c.connector_name.toLowerCase() === AMAZON_SELLING_PARTNER)[0];
    if (primaryRev) {
        return primaryRev;
    }

    return { name: AMAZON_SELLING_PARTNER, value: 0 };
};

export const getDefaultRevenueOption = (revReports: IHistoricalMetricOverviewItem[] = []) => {
    const primaryRev = revReports.filter((c) => c.connector_name.toLowerCase() === DEFAULT_REVENUE_CONNECTOR)[0];

    if (primaryRev) {
        return primaryRev;
    }

    const secondaryRev = revReports.filter((c) => c.connector_name.toLowerCase() === SECONDARY_REVENUE_CONNECTOR)[0];

    if (secondaryRev) {
        return secondaryRev;
    }

    const tertiaryRev = revReports.filter((c) => c.connector_name.toLowerCase() === TERTIARY_REVENUE_CONNECTOR)[0];

    if (tertiaryRev) {
        return tertiaryRev;
    }

    const rev = revReports.filter((c) => c.connector_name.toLowerCase() === QUATERNARY_REVENUE_CONNECTOR)[0];

    if (!rev) {
        return revReports[0];
    }

    return rev;
};

export const getDefaultRevenueOptionByType = (revReports: IHistoricalMetricOverviewItem[] = [], type?: RevenueType) => {
    const amazonRev =
        revReports.filter((c) => {
            return c.connector_name.toLowerCase() === AMAZON_SELLING_PARTNER && c.type === type;
        })[0]?.value || 0;

    const primaryRev =
        revReports.filter((c) => {
            return c.connector_name.toLowerCase() === DEFAULT_REVENUE_CONNECTOR && c.type === type;
        })[0]?.value || 0;

    if (primaryRev) {
        return primaryRev + amazonRev;
    }

    const secondaryRev =
        revReports.filter((c) => {
            return c.connector_name.toLowerCase() === SECONDARY_REVENUE_CONNECTOR && c.type === type;
        })[0]?.value || 0;

    if (secondaryRev) {
        return secondaryRev + amazonRev;
    }

    const tertiaryRev = revReports.filter((c) => {
        return c.connector_name.toLowerCase() === TERTIARY_REVENUE_CONNECTOR && c.type === type;
    })[0]?.value;

    if (tertiaryRev) {
        return tertiaryRev + amazonRev;
    }

    const rev = revReports.filter(
        (c) => c.connector_name.toLowerCase() === QUATERNARY_REVENUE_CONNECTOR && c.type === type,
    )[0]?.value;

    if (!rev) {
        return revReports.filter((c) => c.type === type)[0]?.value + amazonRev;
    }

    return rev + amazonRev;
};

export const defaultMetricData: IKpiPerformanceMetric = {
    revenue: [],
    channel_reported_revenue: [],
    roas: [],
    channel_reported_roas: { data: [], meta: {} },
    spend: [],
    new_customers: [],
    cac: [],
    orders: [],
    blended_roas: [],
    average_order: [],
    abandoned_checkouts: [],
    web_users: [],
    web_sessions: [],
    web_bounce_rate: [],
    first_order_revenue: [],
    second_order_revenue: [],
    modeled_revenue: [],
    modeled_roas: { data: [], meta: {} },
    first_order_conversions: [],
    second_order_conversions: [],
    modeled_customers: [],
    modeled_cac: { data: [], meta: {} },
};

const grpByLogicForEcommerce = (
    grpByPeriod: IDictionary<any[]>,
    getStartDateOfGroup: any,
    ecommerceState?: KeyKPIPerformanceTabTypes,
) => {
    const allTotal =
        Object.values(grpByPeriod)
            .flat(1)
            .filter((m) => m.seriesName === "total")
            .reduce((previousValue, currentValue: any) => previousValue + (currentValue.value || 0), 0) || 0;

    const groupedData = Object.entries(grpByPeriod).map(([day, value]: any) => {
        const metricNames = groupBy(value, "channelName");
        return Object.entries(metricNames).map(([key, value1]) => {
            let value = +value1.reduce((previousValue, currentValue) => previousValue + (currentValue.value || 0), 0);
            if (
                ecommerceState &&
                [
                    "web_bounce_rate",
                    "roas",
                    "channel_reported_roas",
                    "modeled_roas",
                    "modeled_percentage_of_ecommerce_revenue",
                ].includes(ecommerceState)
            ) {
                value = value / value1.length;
            }
            return {
                ...value1[0],
                date: format(getStartDateOfGroup(toDate(value1[0].date)), "yyyy-MM-dd"),
                name: key,
                value,
                percentage: calculatePercentage(value, allTotal),
            };
        });
    });
    return groupedData.flat(1);
};

export const getSpecificGrpByEcommerceData = (
    sortedValuesByDate: IChartItem[],
    grpBy: string,
    ecommerceState?: KeyKPIPerformanceTabTypes,
) => {
    let copySeriesDataHolder = [...sortedValuesByDate];

    switch (grpBy) {
        case chartGrpKey.WEEK:
            const weeks = [...sortedValuesByDate].reduce((m: any, o: any) => {
                const obj = { ...o };
                obj.weekDate = format(startOfWeek(toDate(o.date)), "yyyy-MM-dd");
                m.push(obj);
                return m;
            }, []);
            const grpByWeek = groupBy(weeks, "weekDate");
            copySeriesDataHolder = grpByLogicForEcommerce(grpByWeek, startOfWeek, ecommerceState);
            break;

        case chartGrpKey.MONTH:
            const groupedByMonth = groupBy(sortedValuesByDate, (item) => {
                return item.date.substring(0, 7);
            });
            copySeriesDataHolder = grpByLogicForEcommerce(groupedByMonth, startOfMonth, ecommerceState);
            break;

        case chartGrpKey.QUATER:
            const groupByQuarter = groupBy(
                sortedValuesByDate.map((d) => ({
                    ...d,
                    quarter: `${getYear(parseISO(d.date))}-${getQuarter(parseISO(d.date))}`,
                })),
                (item) => {
                    return item.quarter;
                },
            );
            copySeriesDataHolder = grpByLogicForEcommerce(groupByQuarter, startOfQuarter, ecommerceState);
            break;

        case chartGrpKey.YEAR:
            const groupedByYear = groupBy(sortedValuesByDate, (item) => {
                return getYear(new Date(item.date));
            });
            copySeriesDataHolder = grpByLogicForEcommerce(groupedByYear, startOfYear, ecommerceState);
            break;

        default:
            break;
    }
    return copySeriesDataHolder as IChartItem[];
};

export const getRevenueLegend = (value: string) => {
    const channel = stringToCapitalize(value || "");
    if (channel === "Total") {
        return (
            <Typography variant="subtitle1" color={grey[700]}>
                {channel}
            </Typography>
        );
    }
    return (
        <>
            <Typography variant="subtitle1" color={grey[700]}>
                {channel.slice(0, channel.lastIndexOf(" "))}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                {channel.split(" ").pop()}
            </Typography>
        </>
    );
};

export const addMetricsToHistoricalApiData = (data: IHistoricalMetricData, args: IHistoricalArgs) => {
    const updatedRes = { ...data };

    let reportedRoas: IHistoricalMetricOverviewItem[] = [];

    updatedRes.spend?.forEach((spend) => {
        const findRevenue = updatedRes.channel_reported_revenue.find((c) => c.connector_name === spend.connector_name);
        if (findRevenue) {
            reportedRoas = [...reportedRoas, { ...findRevenue, value: calculateROAS(spend.value, findRevenue?.value) }];
        }
    });

    return { ...updatedRes, channel_reported_roas: reportedRoas };
};

export const formatTrendResponse = (data: IPerformanceTrendData) => {
    const metricSparkValues = Object.entries(data.metric_spark_values).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [camelCase(key)]: value,
        }),
        {},
    );

    return {
        ...keysToCamelCase({
            metrics: data.metrics,
            connector_list: data.connector_list,
        }),
        metricSparkValues,
    };
};

export const formatHistoricalTimeSeriesResponse = (response: IMetricSeriesResponse, args: IArguments) => {
    const filteredGAData = response?.data ? response.data.filter((c: any) => c.name !== GOOGLE_ANALYTICS) : [];
    return {
        data: filteredGAData.map((d: { type: string; name: string }) => {
            const type = !d.type
                ? "Transactional"
                : d.type === "MARKETING"
                ? "Transactional"
                : capitalizeFirstLetter(d.type);

            const channelName =
                args.supportedDataSources.find((c) => c.service === d.name)?.name || stringToCapitalize(d.name || "");

            return {
                ...d,
                type,
                channelName:
                    args.metric_name === "revenue"
                        ? d.type
                            ? `${stringToCapitalize(d.name || "")}-${stringToCapitalize(type || "")}`
                            : `${d.name}-${type}`
                            ? `${channelName}-${type}` || `${d.name}-${stringToCapitalize(type || "")}`
                            : ""
                        : channelName,
                seriesName: `${d.name}${d.type ? `-${stringToCapitalize(type).toLowerCase()}` : ""}`,
            };
        }),
        meta: keysToCamelCase(response.meta),
    };
};
