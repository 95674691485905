import { IDictionary } from "../IDictionary";
import { IErrorValues } from "../IErrorValues";
import { IJob } from "./IJob";
import { IMetricAttributionTableValuesTransformed } from "../performanceDetails/IMetricAttributionTableResponse";
import {
    IForecastCampaignHeadCell,
    SCENARIO_BASIS,
    TIMEFRAME_OPTIONS,
} from "../../consts/optimizationPage/optimizationPage";
import { FORMATS } from "src/enums/Formats";

export interface IScenario extends IErrorValues {
    budget: number;
    name: string;
    description: string;
    scenarioType: SCENARIO_BASIS;
    forecastTimeframe: TIMEFRAME_OPTIONS;
    revenue: number;
    roas: number;
    campaignData: ICampaignData;
    id?: string;
    creatorName?: string;
    createdAt?: string;
    scenarioLevel?: number; // for scenario level column count
    scenarioCampaigns?: IOptimizationModelResponse;
    allocationError?: string;
    jobStatus?: string;
    job?: IJob;
    isScenarioEditable?: boolean;
    confidenceScore?: number;
    activeJobId?: string;
    noChangeJob?: INoChangeJob;
    optimizationConfidence: number;
    isPrivate: boolean;
    creatorId?: string; // used for checking if the scenario is created by the current user
    isArchived?: boolean;
    isHidden: boolean;
}

export type ICampaignData = Array<{
    channel: string;
    campaignId: string;
    connectorName?: string;
    isLocked: boolean;
    budgetAllocated: number;
    currentEstimatedSpend: number;
}>;

export interface INoChangeJob extends IJob {
    campaigns: Array<{
        boundScore: number;
        budgetAllocated: number;
        campaignId: string;
        channel: string;
        confidenceScore: number;
        confidenceStr: string;
        coverageScore: number;
        forecastedRevenue: number;
        frequencyScore: number;
        isLocked: boolean;
    }>;
}

export type IOptimizationModelResponse = Array<{
    campaignName?: string; // used for display campaignName in UI
    budgetAllocated: number;
    campaignId: string;
    channel: string;
    companyId: number;
    createdAt: string;
    forecastedRevenue: number;
    id: number;
    maximumPercentAllocation: string | null;
    minimumPercentAllocation: string | null;
    scenarioId: number;
    updatedAt: string;
    forecastedRoas: number;
    confidenceScore: number;
    isLocked: boolean;
}>;

export interface IScenarioFormattedJobs {
    jobId: string;
    jobName: string;
    actedOnId: number;
    actedOnType: string;
    status: string;
    metadata: {
        scenario: IScenario;
        lockedCampaigns: string[];
        forecastAllocations: IDictionary;
        forecastModelOutput: Array<
            [
                number,
                {
                    predRev: number;
                    confidence: {
                        boundScore: number;
                        confidenceStr: string;
                        coverageScore: number;
                        frequencyScore: number;
                        confidenceScore: number;
                    };
                    campaignName: string;
                },
            ]
        >;
        forecastModelSuccess: boolean;
        allocationModelOutput: {
            payload: {
                error: {};
                success: true;
                confidence: IDictionary;
                campaignSpends: IDictionary;
            };
        };
        allocationModelSuccess: boolean;
    };
    createdAt: string;
    updatedAt: string;
    campaigns: Array<{
        campaignId: string;
        forecastedRevenue: number;
        budgetAllocated: number;
        confidenceScore: number;
        boundScore: number;
        confidenceStr: string;
        coverageScore: number;
        frequencyScore: number;
        isLocked: boolean;
        channel: string;
    }>;
}

export interface IMetricAttributionTableValuesTransformedWithSpendAllocation
    extends IMetricAttributionTableValuesTransformed {
    allocationSpend: number;
    forecastedRevenue: number;
    forecastedRoas: number;
    change: number;
    changePercentage: number;
    allocationPercentage: number;
    scenarioCampaignId: number | undefined;
    confidenceScore: number;
    channel: string;
    isLocked: boolean;
    isEdited: boolean;
    totalSpendDiff: number;
    totalRevenueDiff: number;
    totalRoasDiff: number;
}

export interface IChannelGroup {
    allocationSpend: number;
    change: number;
    changePercentage: number;
    channelName: string;
    cleanChannelName: string;
    confidenceScore: number;
    connectorName: string;
    forecastedRevenue: number;
    forecastedRoas: number;
    spend: number;
    trueRevenue: number;
    trueRoas: number;
    totalSpendDiff: number;
    totalRevenueDiff: number;
    totalRoasDiff: number;
    campaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
}

export interface ITacticGroup {
    allocationSpend: number;
    change: number;
    changePercentage: number;
    tacticId?: number;
    confidenceScore: number;
    forecastedRevenue: number;
    forecastedRoas: number;
    spend: number;
    trueRevenue: number;
    trueRoas: number;
    totalSpendDiff: number;
    totalRevenueDiff: number;
    totalRoasDiff: number;
    campaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
}

export interface IForecastedTotalMetric {
    allocationSpend: number;
    spend: number;
    totalAllocationSpendPercentages: number;
    change: number;
    changePercentage: number;
    forecastedRevenue: number;
    forecastedRoas: number;
    totalForecastedRevenuePercentages: number;
    totalRoasPercentages: number;
    trueRevenue: number;
    trueRoas: number;
    totalSpendDiff: number;
    totalRevenueDiff: number;
    totalRoasDiff: number;
}

export interface ISelectedTotalMetric extends IDictionary {
    spend: number;
    trueRevenue: number;
    trueRoas: number;
    currentEstimatedDailySpend: number;
    currentEstimatedSpend: number;
}

export interface IScenarioFormatted extends IErrorValues {
    scenario: IScenario;
    jobs: IScenarioFormattedJobs[];
    allNoChangeJobs: IScenarioFormattedJobs[];
}

export interface ISummary {
    label: string;
    forecasted: number;
    last: number;
    percentageChange: number;
    unit: FORMATS;
    fixed: number;
    change: number;
}

export interface ISummaryHeader {
    label: string;
    id: keyof ISummary;
    align: "right" | "left" | "center";
}
export enum Views {
    NoChange = "noChange",
    LastDays = "lastDays",
}

export enum MetricRate {
    Total = "total",
    Daily = "daily",
}

export enum RecommandationTab {
    Channels = "channels",
    Campaigns = "campaigns",
    Tactics = "tactics",
}

export interface ISheetData {
    header: Array<
        | Array<{
              label: string;
              id: string;
          }>
        | readonly IForecastCampaignHeadCell[]
    >;
    data: Array<any | IChannelGroup>;
    filename: string;
    compareToLabel: string;
    selectedScenario: IScenario & { predictionSentence: string; metricRate: MetricRate };
}

export interface IColumnFilterProps {
    cards: IForecastCampaignHeadCell[];
    handleTableCol: (column: any) => void;
    compareToLabel: string;
}

export interface IMetricRateProps {
    handleMetricChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    metricRateValue: MetricRate;
}

export interface ICard {
    id: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
    hidden?: boolean;
    align?: "inherit" | "left" | "center" | "right" | "justify" | undefined;
    className?: string;
    groupName?: string;
    width?: string | undefined;
    fixed?: number | undefined;
    exportFixed?: number | undefined;
}
