import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Theme } from "@mui/material";
import { css } from "@emotion/css";
import { useTheme } from "@mui/system";

import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import GeneralSettings from "../GeneralSettings/GeneralSettings";
import { Members } from "../Members/MembersV2";
import { Billing } from "../Billing/Billing";
import { selectCurrentlyViewingCode } from "../../reduxState/slices/organizationSlice";
import {
    BILLING_PATH,
    CAMPAIGN_INCLUSION_PATH,
    CONNECTORS_PATH,
    MEMBER_PATH,
    SETTINGS_PATH,
    TACTICS_PATH,
} from "../../consts/path/path";
import { CampaignInclusion } from "../CampaignInclusions/CampaignInclusion";
import { campaignInclusionsSelector } from "src/reduxState/slices/featuresSlice";
import { PageHeaderTabContext } from "src/components/CustomTabContext/PageHeaderTabContext";
import { ConnectorOnboardingSteps } from "../Connectors/ConnectorOnboardingSteps";
import { currentUserRoleByCompanyIdSelector, userSuperAdminRoleSelector } from "src/reduxState/slices/userSlice";
import { UserRole } from "src/enums/UserRole";
import { Tactics } from "../Tactics/Tactics";

export enum SettingsTab {
    GeneralSettings = "generalSettings",
    Connectors = "connectors",
    Members = "members",
    Billing = "billing",
    CampaignInclusion = "campaign_inclusion",
    newConnector = "new-connector",
    Tactics = "tactics",
}

const useStyles = (theme: Theme, settingsCurrentTab: SettingsTab) => ({
    containerStyle: css({
        padding: theme.spacing(4),
    }),
});

export const OrgSettings: React.FC = () => {
    const [settingsCurrentTab, setSettingsCurrentTab] = useState<SettingsTab>(SettingsTab.GeneralSettings);
    const styles = useStyles(useTheme(), settingsCurrentTab);

    const orgCode = useSelector(selectCurrentlyViewingCode);
    const campaignInclusionsSettingsPage = useSelector(campaignInclusionsSelector);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const isSuperAdmin = useSelector(userSuperAdminRoleSelector);
    const isAdminUser = userRole === UserRole.ADMIN || isSuperAdmin;

    const [unsavedWarningDialog, setUnsavedWarningDialog] = useState<boolean>(false);

    const settingsTabs = [
        {
            label: "General Settings",
            value: SettingsTab.GeneralSettings,
            to: `/org/${orgCode}${SETTINGS_PATH}`,
            component: <GeneralSettings />,
        },
        {
            label: "Connectors",
            value: SettingsTab.Connectors,
            to: `/org/${orgCode}${CONNECTORS_PATH}`,
            component: <ConnectorOnboardingSteps />,
        },
        {
            label: "Members",
            value: SettingsTab.Members,
            to: `/org/${orgCode}${MEMBER_PATH}`,
            component: <Members />,
        },
        {
            label: "Billing",
            value: SettingsTab.Billing,
            to: `/org/${orgCode}${BILLING_PATH}`,
            component: <Billing />,
            isHidden: !isAdminUser,
        },
        {
            label: "Tactics",
            value: SettingsTab.Tactics,
            to: `/org/${orgCode}${TACTICS_PATH}`,
            component: (
                <Tactics
                    unsavedWarningDialog={unsavedWarningDialog}
                    setUnsavedWarningDialog={setUnsavedWarningDialog}
                />
            ),
        },
        {
            label: "Campaign Inclusion",
            value: SettingsTab.CampaignInclusion,
            to: `/org/${orgCode}${CAMPAIGN_INCLUSION_PATH}`,
            component: <CampaignInclusion />,
            isHidden: !campaignInclusionsSettingsPage,
        },
    ];

    useEffect(() => {
        const finalPathname = location.pathname?.split("/").pop();
        const activeTabFromUrl = settingsTabs.find((tab) => tab.value === finalPathname);

        setSettingsCurrentTab(
            location.pathname?.includes(CONNECTORS_PATH)
                ? SettingsTab.Connectors
                : activeTabFromUrl?.value || SettingsTab.GeneralSettings,
        );
    }, [location.pathname]);

    const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: SettingsTab) => {
        if (!unsavedWarningDialog) {
            setSettingsCurrentTab(newValue);
        }
    };

    const currentTab = settingsTabs.find((tab) => tab.value === settingsCurrentTab);

    return (
        <div>
            <PageHeader
                pageHeading="Settings"
                showBreadcrumbs={false}
                tabs={
                    <PageHeaderTabContext
                        value={settingsCurrentTab}
                        handleChange={handleTabChange}
                        tabs={settingsTabs}
                    />
                }
            />
            <PageContainer className={styles.containerStyle}>{currentTab?.component}</PageContainer>
        </div>
    );
};
