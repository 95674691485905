import { FC, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { TabContext, TabPanel } from "@mui/lab";
import { Button, Dialog, Tab, Tabs, Typography, useTheme, Theme } from "@mui/material";
import { Stack } from "@mui/system";
import { addDays, format, isPast } from "date-fns";
import { toDate } from "date-fns-tz";
import { css } from "@emotion/css";

import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { useLazyGetPerformancePageQuery } from "../../../reduxState/apis/performancePageApi";
import { selectCurrentlyViewingId } from "../../../reduxState/slices/organizationSlice";
import { getPacingValue, getTrackedCampaigns } from "../../../services/optimizationPage/optimization";
import { calculatePercentage } from "../../../services/performancePage/performancePage";
import { calculateROAS } from "../../../services/utils";
import { TrackingTable } from "./TrackingTable";
import { getPaidDataSources } from "../../../consts/performancePaidPage/performancePaidPage";
import { IScenario } from "../../../interfaces/entities/IScenario";
import { IMetricDataObj, SCENARIO_TRACKING_TABS } from "../../../consts/optimizationPage/optimizationPage";
import { FORMATS } from "../../../enums/Formats";
import { supportedDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesSlice";

interface IScenarioTrackingModal {
    handleCloseModal: () => void;
    scenarioForTracking: IScenario;
    tabValue: string;
    handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
}

const useStyles = (theme: Theme) => ({
    tablPanel: css({ padding: 0 }),
    tabs: css({
        borderBottom: "1px solid #D9D9D9",
        ".MuiTab-root": { color: "black", fontSize: "15px", textTransform: "initial" },
        ".MuiTab-root.Mui-selected": {
            fontWeight: "bold",
            color: theme.palette.primary.main,
        },
    }),
    btnStyle: css({ width: "fit-content", textTransform: "initial", alignSelf: "center" }),
});

export const ScenarioTrackingModal: FC<IScenarioTrackingModal> = ({
    handleCloseModal,
    scenarioForTracking,
    tabValue,
    handleTabChange,
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const supportedDataSources = useSelector(supportedDataSourcesSelector);

    const [
        getPerformancePage,
        {
            data: performanceData = {
                data: [],
                meta: { campaignsDate: "", revenueData: [] },
            },
            isLoading: isPerformanceLoading,
        },
    ] = useLazyGetPerformancePageQuery();
    const isScenarioExpired = isPast(
        addDays(toDate(scenarioForTracking.createdAt || new Date()), scenarioForTracking.forecastTimeframe),
    );

    useEffect(() => {
        if (supportedDataSources.length && scenarioForTracking && scenarioForTracking.createdAt && scenarioForTracking.scenarioCampaigns) {
            const campaignIds = scenarioForTracking.scenarioCampaigns.map((sc) => sc.campaignId);
            getPerformancePage({
                orgId: currentOrgId,
                data: {
                    start_date: format(toDate(scenarioForTracking.createdAt), "yyyy-MM-dd"),
                    end_date: format(
                        addDays(toDate(scenarioForTracking.createdAt), scenarioForTracking.forecastTimeframe),
                        "yyyy-MM-dd",
                    ),
                    connectors: getPaidDataSources(supportedDataSources),
                    send_eligibility: true,
                    campaign_id: campaignIds,
                },
            });
        }
    }, [supportedDataSources]);

    const campaignData =
        scenarioForTracking && scenarioForTracking.scenarioCampaigns
            ? getTrackedCampaigns(
                  scenarioForTracking.scenarioCampaigns,
                  scenarioForTracking.forecastTimeframe,
                  performanceData.data,
              )
            : [];

    const metricDataObj: IMetricDataObj = {
        spend: { individualData: [], total: { forecasted: 0, actual: 0, potentialFullfilled: 0, pacing: true } },
        revenue: { individualData: [], total: { forecasted: 0, actual: 0, potentialFullfilled: 0, pacing: true } },
        roas: { individualData: [], total: { forecasted: 0, actual: 0, potentialFullfilled: 0, pacing: true } },
    };

    if (campaignData.length) {
        campaignData.forEach((c) => {
            const spend = c.spend || 0;
            const trueRevenue = c.trueRevenue && c.trueRevenue > 0 ? c.trueRevenue : 0;
            const trueRoas = c.trueRoas && c.trueRoas > 0 ? c.trueRoas : 0;
            const spendData = {
                campaignId: c.campaignId || "",
                campaignName: c.campaignName || "",
                channel: c.channel || "",
                forecasted: c.allocationSpend,
                actual: spend,
                change: spend - c.allocationSpend,
                perChange: c.changePercentage,
                potentialFulfilled: calculatePercentage(spend, c.allocationSpend),
                connectorName: c.connectorName,
            };
            const revenueData = {
                campaignId: c.campaignId || "",
                campaignName: c.campaignName || "",
                channel: c.channel || "",
                forecasted: c.forecastedRevenue,
                actual: trueRevenue,
                change: trueRevenue - c.forecastedRevenue,
                perChange: calculatePercentage(c.forecastedRevenue - trueRevenue, trueRevenue),
                potentialFulfilled: calculatePercentage(trueRevenue, c.forecastedRevenue),
                connectorName: c.connectorName,
            };
            const roasData = {
                campaignId: c.campaignId || "",
                campaignName: c.campaignName || "",
                channel: c.channel || "",
                forecasted: c.forecastedRoas,
                actual: trueRoas,
                change: trueRoas - c.forecastedRoas,
                perChange: calculatePercentage(c.forecastedRoas - trueRoas, trueRoas),
                potentialFulfilled: calculatePercentage(trueRoas, c.forecastedRoas),
                connectorName: c.connectorName,
            };

            metricDataObj.spend.individualData = [...metricDataObj.spend.individualData, spendData];
            metricDataObj.revenue.individualData = [...metricDataObj.revenue.individualData, revenueData];
            metricDataObj.roas.individualData = [...metricDataObj.roas.individualData, roasData];
        });

        const totalAllocatedSpend = metricDataObj.spend.individualData.reduce((total, d) => total + d.forecasted, 0);
        const actualTotalSpend = metricDataObj.spend.individualData.reduce((total, d) => total + d.actual, 0);

        const totalAllocatedRevenue = metricDataObj.revenue.individualData.reduce(
            (total, d) => total + d.forecasted,
            0,
        );
        const actualTotalRevenue = metricDataObj.revenue.individualData.reduce((total, d) => total + d.actual, 0);

        const totalAllocatedRoas = calculateROAS(totalAllocatedSpend, totalAllocatedRevenue);
        const actualTotalRoas = calculateROAS(actualTotalSpend, actualTotalRevenue);

        metricDataObj.spend.total = {
            forecasted: totalAllocatedSpend,
            actual: actualTotalSpend,
            potentialFullfilled: calculatePercentage(actualTotalSpend, totalAllocatedSpend),
            pacing: getPacingValue(
                totalAllocatedSpend,
                scenarioForTracking.forecastTimeframe,
                actualTotalSpend,
                scenarioForTracking.createdAt,
            ),
        };

        metricDataObj.revenue.total = {
            forecasted: totalAllocatedRevenue,
            actual: actualTotalRevenue,
            potentialFullfilled: calculatePercentage(actualTotalRevenue, totalAllocatedRevenue),
            pacing: getPacingValue(
                totalAllocatedRevenue,
                scenarioForTracking.forecastTimeframe,
                actualTotalRevenue,
                scenarioForTracking.createdAt,
            ),
        };

        metricDataObj.roas.total = {
            forecasted: totalAllocatedRoas,
            actual: actualTotalRoas,
            potentialFullfilled: calculatePercentage(actualTotalRoas, totalAllocatedRoas),
            pacing: getPacingValue(
                totalAllocatedRoas,
                scenarioForTracking.forecastTimeframe,
                actualTotalRoas,
                scenarioForTracking.createdAt,
                "ROAS",
            ),
        };
    }

    return (
        <Dialog maxWidth="md" open={true}>
            <Stack p="30px" gap="30px" color="black">
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h3">Scenario Tracking</Typography>
                    <Typography onClick={handleCloseModal}>
                        <SVGIconRenderer icon="closeIcon" />
                    </Typography>
                </Stack>
                <Typography variant="h4">{scenarioForTracking.name}</Typography>
                <Stack direction="row" gap="4rem">
                    {scenarioForTracking.createdAt && (
                        <Fragment>
                            <Typography variant="subtitle1">
                                Start Date <b>{format(toDate(scenarioForTracking.createdAt), "MM/dd/yyyy")}</b>
                            </Typography>
                            <Typography variant="subtitle1">
                                End Date{" "}
                                <b>
                                    {format(
                                        addDays(
                                            toDate(scenarioForTracking.createdAt),
                                            scenarioForTracking.forecastTimeframe,
                                        ),
                                        "MM/dd/yyyy",
                                    )}
                                </b>
                            </Typography>
                        </Fragment>
                    )}
                    <Typography variant="subtitle1">
                        Status <b>{isScenarioExpired ? "Completed" : "In Progress"}</b>
                    </Typography>
                </Stack>
                <TabContext value={tabValue}>
                    <Tabs value={tabValue} onChange={handleTabChange} className={classes.tabs}>
                        {SCENARIO_TRACKING_TABS.map((t) => (
                            <Tab value={t.value} label={t.label} key={t.value} />
                        ))}
                    </Tabs>
                    {SCENARIO_TRACKING_TABS.map((t) => (
                        <TabPanel key={t.value} className={classes.tablPanel} value={t.value}>
                            <TrackingTable
                                data={metricDataObj[t.value]}
                                isPerformanceLoading={isPerformanceLoading}
                                unit={t.value === "roas" ? FORMATS.NUMERIC : FORMATS.DOLLAR}
                                tab={t.label}
                                scenarioForTracking={scenarioForTracking}
                            />
                        </TabPanel>
                    ))}
                </TabContext>
                <Button className={classes.btnStyle} variant="contained" onClick={handleCloseModal}>
                    Done
                </Button>
            </Stack>
        </Dialog>
    );
};
