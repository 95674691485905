import React, { useEffect } from "react";
import { Typography, Tooltip, Card } from "@mui/material";
import * as echarts from "echarts";
import { Box, Stack } from "@mui/system";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";

import { FORMATS } from "../../../../enums/Formats";
import { formatValue } from "../../../../services/utils";
import { calculatePercentage, handleHighLightChartSeries } from "../../../../services/performancePage/performancePage";
import { BLACK_COLOR } from "../../../../consts/colors";
import { ISecondOrderEffects } from "../../../../interfaces/entities/ISecondOrderEffects";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { IDictionary } from "src/interfaces/IDictionary";
import { pastRangeBasedOnCurrentRange } from "src/components/DateRangeFilter/reactDateRangeUtils";
import { formattedDateSelector } from "src/reduxState/slices/dateFilterSlice";
import { PercentageTrendChip } from "src/components/core/Chips/PercentageTrendChip";

interface ILegendBox {
    key: string;
    name: string;
    color: string;
    tooltip: JSX.Element;
    metricValue: number;
    formattedMetricValue: string;
    secondaryMetricName?: string;
    secondaryMetricValue?: number;
    formattedSecondaryMetricValue?: string;
    sign: FORMATS;
    display?: boolean;
}

interface IHorizontalBarChart {
    formattedData: {
        legendBoxArr: ILegendBox[];
        totalMetrics: Array<{
            name: string;
            value: string;
        }>;
        allCalculatedValues: any;
        chartData: ISecondOrderEffects[];
        total: number;
        id: string;
    };
    percentageData?: IDictionary<any>;
}

const useStyles = () => ({
    chartContainer: css({
        height: "45px",
        width: "100%",
        maxWidth: "100%",
    }),
    legendBoxClass: css({
        cursor: "pointer",
        padding: "8px",
        borderRadius: "0",
        background: "#FFFFFF",
        boxShadow:
            "inset 1px 0px 0px rgba(0, 0, 0, 0.12), inset 0px -1px 0px rgba(0, 0, 0, 0.12), inset -1px 0px 0px rgba(0, 0, 0, 0.12)",
    }),
    tooltip: css({
        fontSize: "14px",
    }),
});

export const HorizontalBarChart: React.FC<IHorizontalBarChart> = ({ formattedData, percentageData }) => {
    const extraClasses = useStyles();
    const selectFormattedDates = useSelector(formattedDateSelector);
    const pastRangeString = pastRangeBasedOnCurrentRange(selectFormattedDates);

    let keyAttributionMetricChart: any = null;

    const getSeriesData = () => {
        return formattedData.legendBoxArr.map((s) => ({
            name: s.name,
            type: "bar",
            stack: "revenue",
            emphasis: {
                focus: "series",
            },
            data: [calculatePercentage(s.metricValue, formattedData.total)],
            barWidth: 45,
            itemStyle: {
                borderColor: s.color,
                color: s.color,
            },
        }));
    };

    const option = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
            formatter: (params: any) => {
                let formattedTooltip: string = "";
                params.forEach((param: any) => {
                    const findCurrentMetric = formattedData.legendBoxArr.find((b) => b.name === param.seriesName);
                    const metricValue = formatValue(findCurrentMetric?.metricValue, findCurrentMetric?.sign, 0);
                    const metricPer = formatValue(
                        calculatePercentage(findCurrentMetric?.metricValue || 0, formattedData.total),
                        FORMATS.PERCENT,
                        2,
                    );

                    formattedTooltip = `${formattedTooltip}<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block">
                    ${param.marker} ${param.seriesName}</span><span style='margin-left:15px'><b>${metricPer} (${metricValue})</b></span></li>`;
                });
                return formattedTooltip;
            },
            confine: true,
        },
        legend: {
            show: false,
        },
        grid: {
            left: "0%",
            right: "0%",
            containLabel: true,
            show: false,
        },
        xAxis: {
            axisLabel: {
                show: false,
            },
            type: "value",
            splitLine: {
                show: false,
            },
            min: 0,
            max: 100,
        },
        yAxis: {
            type: "category",
            splitLine: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
        },
        series: getSeriesData(),
    };

    const initChart = () => {
        if (document.getElementById(formattedData.id)) {
            keyAttributionMetricChart = echarts.init(document.getElementById(formattedData.id)!, "prescient");
            if (keyAttributionMetricChart) {
                keyAttributionMetricChart.resize();
                keyAttributionMetricChart.setOption(option);
            }
        }
    };

    useEffect(() => {
        initChart();
    }, [formattedData]);

    useEffect(() => {
        const updateSize = () => {
            initChart();
        };
        window.addEventListener("resize", updateSize);
        return () => {
            window.removeEventListener("resize", updateSize);
        };
    }, []);

    const handleHighLight = (key: string, opacity: number) => {
        handleHighLightChartSeries(key, opacity, getSeriesData(), keyAttributionMetricChart, option);
    };

    return (
        <>
            <Box
                sx={{
                    marginBottom: "20px",
                    width: "100%",
                    maxWidth: "100%",
                    color: BLACK_COLOR,
                }}
            >
                <Stack
                    direction="row"
                    justifyContent={`${formattedData.totalMetrics.length === 1 ? "end" : "space-between"}`}
                    pr={1}
                    sx={{ height: "45px", alignItems: "center", backgroundColor: "#F1F0F5" }}
                >
                    {formattedData.totalMetrics.map((i) => {
                        return (
                            <React.Fragment key={i.name}>
                                <Typography
                                    color="inherit"
                                    variant="subtitle2"
                                    component="span"
                                    sx={{ fontSize: "15px" }}
                                >
                                    <b>{i.value}</b>{" "}
                                    <Typography variant="caption" color="inherit">
                                        {i.name}
                                    </Typography>
                                </Typography>
                            </React.Fragment>
                        );
                    })}
                </Stack>
                <div id={formattedData.id} className={extraClasses.chartContainer}></div>
            </Box>
            <Stack direction="row" gap={1} flexWrap="wrap">
                {formattedData.legendBoxArr.map((legend) => {
                    const cleanName = legend.name.split(" - ");
                    return legend.display ? (
                        <Card
                            key={legend.key}
                            className={extraClasses.legendBoxClass}
                            style={{
                                borderTop: `2px solid ${legend.color}`,
                            }}
                            id={`btn_${legend.key}`}
                            onMouseOver={() => handleHighLight(legend.name, 0.1)}
                            onMouseOut={() => handleHighLight(legend.name, 1)}
                        >
                            <Stack direction="row" justifyContent="space-between" gap="10px">
                                <Typography
                                    color="inherit"
                                    variant="body1"
                                    component="span"
                                    sx={{ fontWeight: "700", fontSize: "15px" }}
                                >
                                    {legend.formattedMetricValue}
                                </Typography>
                                {legend.tooltip && (
                                    <Tooltip arrow title={legend.tooltip} classes={{ tooltip: extraClasses.tooltip }}>
                                        <span>
                                            <SVGIconRenderer
                                                icon="infoIcon"
                                                width="18px"
                                                height="18px"
                                                style={{ fontSize: "inherit", marginLeft: "2px" }}
                                            />
                                        </span>
                                    </Tooltip>
                                )}
                            </Stack>
                            {percentageData &&
                            percentageData[legend.name] &&
                            typeof percentageData[legend.name] === "number" ? (
                                <Tooltip arrow title={pastRangeString}>
                                    <Typography
                                        component="div"
                                        sx={{
                                            fontWeight: "normal",
                                            margin: "2px 0 5px 0",
                                            cursor: "pointer !important",
                                        }}
                                    >
                                        <PercentageTrendChip
                                            value={percentageData[legend.name]}
                                            isPositiveTrendGood={true}
                                        />
                                    </Typography>
                                </Tooltip>
                            ) : (
                                <Typography sx={{ height: "32px" }}>-</Typography>
                            )}
                            <Typography
                                variant="body2"
                                component="span"
                                color="inherit"
                                sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                            >
                                <Typography variant="body2" component="span" color="inherit">
                                    {cleanName[0]}
                                </Typography>
                            </Typography>
                            <Typography variant="body2" color="inherit">
                                {cleanName[1]}
                            </Typography>
                            {legend.formattedSecondaryMetricValue && (
                                <Typography variant="caption" component="span" color="inherit">
                                    {legend.formattedSecondaryMetricValue} {legend.secondaryMetricName}
                                </Typography>
                            )}
                        </Card>
                    ) : (
                        <></>
                    );
                })}
            </Stack>
        </>
    );
};
