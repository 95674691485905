import React, { FC } from "react";
import { capitalizeFirstLetter } from "src/services/utils";
import { css } from "@emotion/css";

import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { IChannelGroup, IScenario, MetricRate } from "src/interfaces/entities/IScenario";
import { getDateRangeForTimeframe, getTimeframeValue } from "src/services/optimizationPage/optimization";
import { SCENARIO_BASIS } from "src/consts/optimizationPage/optimizationPage";
import { Button } from "@mui/material";

interface IExportSheet {
    header: any[];
    sheets: Array<any | IChannelGroup>;
    filename: string;
    sheetNames: string[];
    selectedScenario: IScenario & { predictionSentence: string; metricRate: MetricRate };
}

export const ExportSheet: FC<IExportSheet> = ({ sheets, sheetNames, filename, header, selectedScenario }) => {
    let updateHeaderBasedOnScenarioType = [...header];
    const isForecastScenario = selectedScenario.scenarioType === SCENARIO_BASIS.FORECAST;

    if (isForecastScenario) {
        updateHeaderBasedOnScenarioType = header.map((h) => {
            return h.filter((h: any) => h.groupName !== "expected");
        });
    }

    const generateCSV = () => {
        const csvRows = [];

        const addRow = (label: string, value: string) => csvRows.push([label, `"${value || "-"}"`].join(","));

        addRow("Scenario Name", selectedScenario.name);
        addRow("Description", selectedScenario.description);
        addRow(
            "Forecast Timeframe",
            `${getTimeframeValue(selectedScenario?.forecastTimeframe)} (${getDateRangeForTimeframe(selectedScenario)})`,
        );
        addRow("Metric Rate", `${capitalizeFirstLetter(selectedScenario.metricRate)} Results`);

        if (!isForecastScenario) {
            csvRows.push(`"${selectedScenario.predictionSentence}"`, ""); // Empty line after predictionSentence
        }
        // Add data rows to CSV content
        sheets.forEach((sheet, mainIndex) => {
            const headerE = updateHeaderBasedOnScenarioType[mainIndex]
                .filter((column: { hidden: any }) => !column.hidden)
                .map(
                    (column: { label: any; groupName: string }) =>
                        `${column.label}${
                            column.groupName && column.groupName !== "other"
                                ? ` (${capitalizeFirstLetter(column.groupName)})`
                                : ""
                        }`,
                )
                .join(",");

            const body = sheet
                .map((row: { [x: string]: any }) =>
                    updateHeaderBasedOnScenarioType[mainIndex]
                        .filter((c: { hidden: any }) => !c.hidden)
                        .map((c: { id: string | number }) => `"${row[c.id]}"`)
                        .join(","),
                )
                .join("\r\n");

            csvRows.push("", sheetNames[mainIndex], headerE, body); // Empty line before each sheet
        });

        // Construct the data URL with explicit filename
        const dataURL = `data:text/csv;charset=utf-8,${encodeURIComponent(csvRows.join("\r\n"))}`;

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = `${filename}.csv`; // Default filename

        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button
            title="Export CSV of outcome info"
            variant="text"
            color="primary"
            onClick={generateCSV}
            startIcon={<SVGIconRenderer icon="exportIcon" />}
        >
            Export Outcome Info
        </Button>
    );
};
