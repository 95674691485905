import { FC, useContext } from "react";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { ScenarioProjections } from "../../../ScenarioProjections";
import { RecommandationSubHeader } from "../../../RecommandationTabs/RecommandationSubHeader";
import {
    EXPORT_FORECASTED_CAMPAIGNS_HEADERS,
    IForecastCampaignHeadCell,
} from "src/consts/optimizationPage/optimizationPage";
import { RecommendationTab } from "../../../RecommandationTabs/RecommandationTab";
import LoadingComponent from "src/components/core/Loader/LoaderWithMessage";
import { OptimizationOutcomeContext } from "src/containers/OptimizationOutcomePage/OptimizationOutcomePage";
import { generateRowsForCSV } from "src/services/optimizationPage/optimization";
import {
    IChannelGroup,
    IForecastedTotalMetric,
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
    ISummary,
    Views,
    ISummaryHeader,
} from "src/interfaces/entities/IScenario";
import { IDictionary } from "src/interfaces/IDictionary";

interface IOverviewTab {
    jobError: string;
    handleModelingRetry: () => void;
    isPollingAPi: boolean;
    totalAllocationCampaigns: IForecastedTotalMetric;
    selectedScenario: IScenario;
    isTableLoading: boolean;
    compareTo: Views;
    setCompareTo: React.Dispatch<React.SetStateAction<Views>>;
    compareToOption: Array<{
        lable: string;
        value: Views;
        disabled?: boolean | undefined;
    }>;
    summaryArray: ISummary[];
    recommendationsTableHeading: IDictionary<ISummaryHeader[]>;
    predictionSentence: string;
    exportRecommendationsTableHeading: Array<{
        label: string;
        id: string;
    }>;
    channelGroupData: IChannelGroup[];
    forecastCampaign: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    cards: IForecastCampaignHeadCell[];
    handleTableCol: (column: any) => void;
    recomendationsModalToggle: () => void;
    isRecalculateScenarioDisabled: boolean;
    isPerformanceLoading: boolean;
    increaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    decreaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
}

export const OverviewTab: FC<IOverviewTab> = ({
    jobError,
    handleModelingRetry,
    isPollingAPi,
    totalAllocationCampaigns,
    selectedScenario,
    isTableLoading,
    compareTo,
    setCompareTo,
    compareToOption,
    summaryArray,
    recommendationsTableHeading,
    predictionSentence,
    exportRecommendationsTableHeading,
    channelGroupData,
    forecastCampaign,
    cards,
    handleTableCol,
    recomendationsModalToggle,
    isRecalculateScenarioDisabled,
    isPerformanceLoading,
    increaseSpendCampaigns,
    decreaseSpendCampaigns,
}) => {
    const optimizationOutcomeContext = useContext(OptimizationOutcomeContext);
    const generateRows = (rows: ISummary[]) => generateRowsForCSV(rows, exportRecommendationsTableHeading);
    const getCompareLabel = compareToOption.find((op: { value: any }) => op.value === compareTo)?.lable || "";

    return (
        <>
            {jobError !== "" ? (
                <Alert
                    sx={{ alignItems: "center" }}
                    severity="error"
                    action={
                        jobError.includes("server error") && (
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={handleModelingRetry}
                                sx={{ textTransform: "initial" }}
                            >
                                Retry
                            </Button>
                        )
                    }
                >
                    <AlertTitle>
                        {jobError.includes("highest confidence") ? "Confidence Error" : "Modeling Error"}
                    </AlertTitle>
                    <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                        {jobError}
                    </Typography>
                </Alert>
            ) : isPollingAPi ? (
                <LoadingComponent message="Your optimization is currently being processed, check back later." />
            ) : (
                <Stack gap="50px">
                    <ScenarioProjections
                        totalAllocationCampaigns={totalAllocationCampaigns}
                        selectedScenario={selectedScenario}
                        isTableLoading={isTableLoading}
                        compareTo={compareTo}
                        setCompareTo={setCompareTo}
                        compareToOption={compareToOption}
                        summaryArray={summaryArray}
                        recommendationsTableHeading={recommendationsTableHeading}
                        predictionSentence={predictionSentence}
                    />
                    <Stack gap="30px">
                        <Stack
                            sx={{
                                boxShadow: "0px -1px 0px 0px #D9D9D9 inset",
                                paddingBottom: "5px",
                            }}
                            justifyContent="space-between"
                            direction="row"
                            flexWrap="wrap"
                            gap="20px"
                            alignItems="center"
                        >
                            <Typography color="#000" variant="h5" fontWeight="bold">
                                Recommendations
                            </Typography>
                            <Stack direction="row" alignItems="center" gap="10px">
                                {optimizationOutcomeContext && (
                                    <RecommandationSubHeader
                                        downloadSheetData={{
                                            selectedScenario: {
                                                ...selectedScenario,
                                                predictionSentence,
                                                metricRate: optimizationOutcomeContext.metricRate,
                                            },
                                            header: [
                                                exportRecommendationsTableHeading,
                                                EXPORT_FORECASTED_CAMPAIGNS_HEADERS.map((h) =>
                                                    h.id === "campaignName"
                                                        ? {
                                                              ...h,
                                                              id: "cleanChannelName",
                                                              label: "Channels",
                                                          }
                                                        : h,
                                                ),
                                                [
                                                    EXPORT_FORECASTED_CAMPAIGNS_HEADERS[0],
                                                    {
                                                        id: "campaignId",
                                                        label: "Campaign Id",
                                                    },
                                                    ...EXPORT_FORECASTED_CAMPAIGNS_HEADERS.slice(1),
                                                ],
                                            ],
                                            data: [
                                                generateRows(summaryArray),
                                                [
                                                    ...channelGroupData,

                                                    {
                                                        ...totalAllocationCampaigns,
                                                        cleanChannelName: "Total",
                                                    },
                                                ],
                                                [
                                                    ...forecastCampaign,
                                                    {
                                                        ...totalAllocationCampaigns,
                                                        campaignName: "Total",
                                                    },
                                                ],
                                            ],
                                            filename: `${selectedScenario.name} (Compare To- ${getCompareLabel})`,
                                            compareToLabel: getCompareLabel,
                                        }}
                                        columnFilterProps={{
                                            cards,
                                            handleTableCol,
                                            compareToLabel: getCompareLabel,
                                        }}
                                        modalProps={{
                                            recomendationsModalToggle,
                                            disabled: isTableLoading,
                                        }}
                                    />
                                )}
                            </Stack>
                        </Stack>
                        <RecommendationTab
                            selectedScenario={selectedScenario}
                            forecastCampaign={
                                forecastCampaign as IMetricAttributionTableValuesTransformedWithSpendAllocation[]
                            }
                            isRecalculateScenarioDisabled={isRecalculateScenarioDisabled}
                            isPerformanceLoading={isPerformanceLoading}
                            increaseSpendCampaigns={increaseSpendCampaigns}
                            decreaseSpendCampaigns={decreaseSpendCampaigns}
                            compareToLabel={getCompareLabel}
                            cards={cards}
                        />
                    </Stack>
                </Stack>
            )}
        </>
    );
};
