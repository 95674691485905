import { FC } from "react";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import { TabContext, TabList } from "@mui/lab";

interface ICustomTabContext {
    tabValue: string;
    handleChange: (event: React.SyntheticEvent, newValue: string) => void;
    tabs: Array<{
        label: React.ReactNode;
        value: string;
        isHidden?: boolean;
    }>;
    children: JSX.Element;
    className?: any;
    scrollButtons?: boolean;
}

export const CustomTabContext: FC<ICustomTabContext> = ({
    tabValue,
    handleChange,
    tabs,
    children,
    className,
    scrollButtons = false,
}) => {
    return (
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }} className={className}>
                <TabList
                    sx={(theme) => ({
                        "& .MuiTabs-flexContainer": {
                            gap: "10px",
                        },
                        "& .MuiTab-root": {
                            fontSize: "16px",
                        },
                        "& .MuiTab-root.Mui-selected": {
                            fontWeight: 500,
                        },
                        ".MuiTabs-indicator": {
                            backgroundColor: `${theme.palette.secondary.main}`,
                        },
                    })}
                    variant="scrollable"
                    scrollButtons={scrollButtons}
                    allowScrollButtonsMobile
                    onChange={handleChange}
                >
                    {tabs.map(
                        (tab) =>
                            !tab.isHidden && (
                                <Tab
                                    sx={{ textTransform: "inherit" }}
                                    label={tab.label}
                                    value={tab.value}
                                    key={tab.value}
                                />
                            ),
                    )}
                </TabList>
            </Box>
            {children}
        </TabContext>
    );
};
