import React, { FC } from "react";
import { css } from "@emotion/css";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { LightMenu } from "../LightMenu/LightMenu";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";

import { DynamicInputs } from "./FilterDynamicInputs";
import { MetricFilterInputs } from "./MetricFilterInputs";
import { IHeadCell as IEmailHeadCell } from "../../consts/emailPage/emailPage";
import { IHeadCell } from "../../consts/performancePaidPage/performancePaidPage";
import { BetaTooltip } from "../ConnectorsComponent/NewConnectorComponents/BetaTooltip";
import { betaSupportedDataSourcesSelector } from "../../reduxState/slices/supportedDataSourcesSlice";

export interface IFilterItem {
    id: string;
    title: string;
    key: string;
    type: string;
    items: any;
    open: boolean;
}

interface IDynamicFilter {
    filterChipsCount: number;
    isCampaignIdVisible: boolean | undefined;
    currentFilterList: IFilterItem[];
    campaignIdOptions: any[];
    numericColumns: IHeadCell[] | IEmailHeadCell[];
    handleChange: (list: IFilterItem) => void;
    handleFilter: (
        paramKey: string,
        item: {
            id: string;
        },
        isArray?: boolean,
    ) => void;
}

const useStyles = () => ({
    filterWrapper: css({
        width: "550px",
    }),
    filterHeader: css({
        height: "54px",
        "& div span": {
            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: "24px",
            letterSpacing: "0.15px",
        },
    }),
    accordion: css({
        margin: "1px 0 !important",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "none",
        borderRadius: "0 !important",
    }),
    accordionSummary: css({
        height: "48px",
        minHeight: "48px",
    }),
    accordionDetails: css({
        padding: "8px 14px",
        width: "50%",
    }),
    filterMenuContent: css({
        padding: 0,
        paddingBottom: "0 !important",
        "& div::before": {
            background: "unset",
        },
    }),
    channelFilterWrap: css({
        display: "flex",
        flexWrap: "wrap",
    }),
    imageClass: css({
        height: "24px",
    }),
    inputWrapper: css({
        margin: "0 14px 14px",
    }),
});

export const DynamicFilter: FC<IDynamicFilter> = ({
    filterChipsCount,
    currentFilterList,
    isCampaignIdVisible,
    handleChange,
    handleFilter,
    campaignIdOptions,
    numericColumns,
}) => {
    const classes = useStyles();
    const betaSupportedConnectors = useSelector(betaSupportedDataSourcesSelector);

    return (
        <LightMenu
            isList={false}
            title={`${filterChipsCount} Filters`}
            startIcon={<FilterAltOutlinedIcon />}
            variant="outlined"
            color="primary"
        >
            <div className={classes.filterWrapper}>
                <Card variant="outlined">
                    <CardHeader className={classes.filterHeader} title="Filters" />
                    <CardContent className={classes.filterMenuContent}>
                        {currentFilterList.map((list) => {
                            const isExcludedChannels = list.id === "excluded_channels";
                            return (
                                <Accordion
                                    sx={{
                                        display: list.type === "select" && isCampaignIdVisible ? "none" : "block",
                                    }}
                                    TransitionProps={{ unmountOnExit: true }}
                                    className={classes.accordion}
                                    key={list.id}
                                    expanded={list.open}
                                    onChange={() => handleChange(list)}
                                >
                                    <AccordionSummary
                                        expandIcon={
                                            <SVGIconRenderer icon="chevronDownIcon" strokeColor="rgba(0, 0, 0, 0.54)" />
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionSummary}
                                    >
                                        <Typography color="text.secondary" sx={{ fontSize: 14 }}>
                                            {list.title}
                                        </Typography>
                                    </AccordionSummary>
                                    <div className={classes.channelFilterWrap}>
                                        {list.type === "checkbox" &&
                                            list.items.map((item: any) => {
                                                return (
                                                    <AccordionDetails
                                                        key={item.id}
                                                        className={classes.accordionDetails}
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={item.value}
                                                                        onChange={() => handleFilter(list.key, item)}
                                                                        onKeyDown={(e) =>
                                                                            e.key === "Enter" &&
                                                                            handleFilter(list.key, item)
                                                                        }
                                                                        inputProps={{
                                                                            "aria-label": "controlled",
                                                                        }}
                                                                        color="secondary"
                                                                    />
                                                                }
                                                                label={
                                                                    <Stack
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        gap="8px"
                                                                    >
                                                                        {item.image && (
                                                                            <img
                                                                                className={classes.imageClass}
                                                                                src={item.image}
                                                                            />
                                                                        )}
                                                                        <Typography>{item.name}</Typography>
                                                                        {isExcludedChannels &&
                                                                            betaSupportedConnectors.includes(
                                                                                item.id,
                                                                            ) && (
                                                                                <Typography component="span">
                                                                                    <BetaTooltip />
                                                                                </Typography>
                                                                            )}
                                                                    </Stack>
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </AccordionDetails>
                                                );
                                            })}
                                    </div>
                                    {list.type === "conditionInputs" && (
                                        <DynamicInputs
                                            handleFilter={handleFilter}
                                            initialValues={{
                                                conditionInputs: list.items,
                                            }}
                                            classes={classes}
                                        />
                                    )}
                                    {list.type === "metricConditionInputs" && (
                                        <MetricFilterInputs
                                            handleFilter={handleFilter}
                                            initialValues={{
                                                metricConditionInputs: list.items[0].metric,
                                            }}
                                            classes={classes}
                                            selectedTableColumns={numericColumns as IHeadCell[]}
                                        />
                                    )}
                                    {list.type === "select" && (
                                        <Autocomplete
                                            value={list.items}
                                            sx={{ padding: "0 14px 14px", width: "100%" }}
                                            multiple
                                            options={campaignIdOptions}
                                            getOptionLabel={(option) => option.campaignId}
                                            defaultValue={list.items}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Campaign id" />
                                            )}
                                            disableClearable
                                            onChange={(e, newValue, reason, detail) => {
                                                if (detail?.option.campaignId) {
                                                    handleFilter(`campaign_id`, {
                                                        id: detail?.option.campaignId,
                                                    });
                                                }
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={option.campaignId}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                        />
                                    )}
                                </Accordion>
                            );
                        })}
                    </CardContent>
                </Card>
            </div>
        </LightMenu>
    );
};
