import { subDays } from "date-fns";

import { getPastPeriod } from "src/components/DateRangeFilter/reactDateRangeUtils";
import { FORMATS } from "src/enums/Formats";
import { IDictionary } from "src/interfaces/IDictionary";
import { IDateRange, IHeadCell } from "src/interfaces/dashboard/trend";
import { ChartModes } from "src/reduxState/slices/chartSlice";

export enum MetricNameTypeForPayload {
    Spend = "spend",
    Revenue = "revenue",
    ChannelReportedRevenue = "channel_reported_revenue",
    ChannelReportedRoas = "channel_reported_roas",
    NewCustomers = "new_customers",
    Orders = "orders",
    AbandonedCheckouts = "abandoned_checkouts",
    WebUsers = "web_users",
    WebSessions = "web_sessions",
    webBounceRate = "web_bounce_rate",
    modeledRoas = "modeled_roas",
    modeledCac = "modeled_cac",

    FirstOrderRevenue = "first_order_revenue",
    SecondOrderRevenue = "second_order_revenue",
    FirstOrderConversions = "first_order_conversions",
    SecondOrderConversions = "second_order_conversions",
}

export const metricParamsForTrendCard = [
    MetricNameTypeForPayload.Spend,
    MetricNameTypeForPayload.ChannelReportedRevenue,
    MetricNameTypeForPayload.FirstOrderRevenue,
    MetricNameTypeForPayload.SecondOrderRevenue,
    MetricNameTypeForPayload.FirstOrderConversions,
    MetricNameTypeForPayload.SecondOrderConversions,
    MetricNameTypeForPayload.Orders,
    MetricNameTypeForPayload.Revenue,
    MetricNameTypeForPayload.modeledRoas,
    MetricNameTypeForPayload.modeledCac,
    MetricNameTypeForPayload.ChannelReportedRoas,
];

export const DEFAULT_DATE_RANGE_FOR_COMPONENT: IDateRange = {
    startDate: subDays(new Date(), 30),
    endDate: subDays(new Date(), 1),
    key: "selection",
};

export const defaultDateRangeForHomePage = {
    selection: DEFAULT_DATE_RANGE_FOR_COMPONENT,
    compare: {
        ...getPastPeriod([DEFAULT_DATE_RANGE_FOR_COMPONENT], true)[0],
        key: "compare",
    } as IDateRange,
};

export const TREND_TABLE_HEADER: IHeadCell[] = [
    {
        id: "metric",
        numeric: false,
        disablePadding: false,
        label: "Metric",
        align: "left",
    },
    {
        id: "current",
        numeric: true,
        disablePadding: false,
        label: "Current Week",
        align: "right",
        sign: FORMATS.DOLLAR,
        precision: 0,
    },
    {
        id: "prior",
        numeric: true,
        disablePadding: false,
        label: "Prior Year Week",
        align: "right",
        sign: FORMATS.DOLLAR,
        precision: 0,
    },
    {
        id: "difference",
        numeric: true,
        disablePadding: false,
        label: "Difference",
        align: "right",
        sign: FORMATS.DOLLAR,
        precision: 0,
    },
    {
        id: "sparkLine",
        numeric: true,
        disablePadding: false,
        label: "",
        width: "350px",
        align: "right",
    },
    {
        id: "change",
        numeric: true,
        disablePadding: false,
        label: "% Change",
        align: "right",
        sign: FORMATS.PERCENT,
        precision: 2,
    },
];

export const groupByChannelOption = [
    { label: "Total", id: "total", isDisabled: false },
    { label: "Shopify Only", id: "shopify", isDisabled: false },
    { label: "Amazon Selling Partner Only", id: "amazon_selling_partner", isDisabled: false },
];

export type KeyKPIPerformanceTabTypes =
    | "revenue"
    | "channel_reported_revenue"
    | "roas"
    | "channel_reported_roas"
    | "spend"
    | "orders"
    | "new_customers"
    | "abandoned_checkouts"
    | "web_users"
    | "web_sessions"
    | "web_bounce_rate"
    | "cac"
    | "average_order"
    | "first_order_revenue"
    | "second_order_revenue"
    | "modeled_revenue"
    | "modeled_roas"
    | "first_order_conversions"
    | "second_order_conversions"
    | "modeled_customers"
    | "modeled_cac"
    | "modeled_percentage_of_ecommerce_revenue";

export interface IPerformanceKpiMetricsTabsType {
    precision: number;
    sign: FORMATS;
    id: KeyKPIPerformanceTabTypes;
    label: string;
    apiMetric: MetricNameTypeForPayload[];
    historicalApiMetric?: MetricNameTypeForPayload[];
    isGA: boolean;
    isAttribution?: boolean;
    isHistorical?: boolean;
    info?: string;
    isHide?: boolean;
    groupByChannelOption?: Array<{ label: string; id: string; isDisabled: boolean }>;
    tooltipId: string;
}

export const getMetricTooltipText = (defaultRevenueSource: string, isAmazonConnected: boolean): IDictionary => {
    const isDefaultConnected = defaultRevenueSource || "";
    const supportedSource = `${isDefaultConnected}${
        isAmazonConnected ? `${isDefaultConnected ? " and" : ""} Amazon` : ""
    }`;

    return {
        modeledRevenue: `The sum of all modeled revenue sources such as ${supportedSource}`,
        modeledRoas: `Return on ad spend (ROAS) is calculated by dividing MMM Revenue by Spend`,
        modeledNewCustomers: `The sum of all modeled new customers from sources such as ${supportedSource}`,
        modeledCac: `Cost of Customer Acquisition (CAC) is calculated by dividing Spend by MMM New Customers`,
        spend: `The sum of all spend, from all campaigns, across all channels`,
        revenue: `The sum of revenue as reported by ${supportedSource}`,
        roas: `The ROAS is derived from Revenue reported from ${supportedSource}`,
        channelReportedCustomers: `The New Customers that are reported by Shopify`,
        channelReportedCac: `The CAC is derived from New Customers and Spend, reported from Shopify`,
        orders: `The sum of orders as reported by ${supportedSource}`,
        aov: `The Average Order Value (AOV) is calculated by dividing Ecommerce Reported Revenue by Ecommerce Reported Orders as reported by ${supportedSource}`,
        googleMetrics: `Reported by Google Analytics`,
        channelReportedRevenue: `The sum of revenue as reported by marketing platforms`,
        channelReportedRoas: ` Return on ad spend (ROAS) is calculated by dividing Channel Reported Revenue by Spend`,
    };
};

export const PERFORMANCE_KPI_METRICS_TABS: readonly IPerformanceKpiMetricsTabsType[] = [
    {
        precision: 0,
        sign: FORMATS.DOLLAR,
        id: "modeled_revenue",
        label: "MMM Revenue",
        apiMetric: [MetricNameTypeForPayload.FirstOrderRevenue, MetricNameTypeForPayload.SecondOrderRevenue],
        isGA: false,
        isAttribution: true,
        groupByChannelOption,
        tooltipId: "modeledRevenue",
    },
    {
        precision: 2,
        sign: FORMATS.NUMERIC,
        id: "modeled_roas",
        label: "MMM ROAS",
        apiMetric: [MetricNameTypeForPayload.modeledRoas],
        isGA: false,
        isAttribution: true,
        groupByChannelOption,
        tooltipId: "modeledRoas",
    },
    {
        precision: 0,
        sign: FORMATS.NUMERIC,
        id: "modeled_customers",
        label: "MMM New Customers",
        apiMetric: [MetricNameTypeForPayload.FirstOrderConversions, MetricNameTypeForPayload.SecondOrderConversions],
        isGA: false,
        isAttribution: true,
        groupByChannelOption,
        tooltipId: "modeledNewCustomers",
    },
    {
        precision: 2,
        sign: FORMATS.DOLLAR,
        id: "modeled_cac",
        label: "MMM CAC",
        apiMetric: [MetricNameTypeForPayload.modeledCac],
        isGA: false,
        isAttribution: true,
        groupByChannelOption,
        tooltipId: "modeledCac",
    },
    {
        precision: 2,
        sign: FORMATS.PERCENT,
        id: "modeled_percentage_of_ecommerce_revenue",
        label: "MMM % of Total",
        apiMetric: [MetricNameTypeForPayload.FirstOrderRevenue, MetricNameTypeForPayload.SecondOrderRevenue],
        historicalApiMetric: [MetricNameTypeForPayload.Revenue],
        isGA: false,
        isAttribution: true,
        isHistorical: true,
        tooltipId: "modeled_percentage_of_ecommerce_revenue",
    },
    {
        precision: 0,
        sign: FORMATS.DOLLAR,
        id: "spend",
        label: "Spend",
        apiMetric: [MetricNameTypeForPayload.Spend],
        isGA: false,
        tooltipId: "spend",
    },
    {
        precision: 0,
        sign: FORMATS.DOLLAR,
        id: "channel_reported_revenue",
        label: "Channel Revenue",
        apiMetric: [MetricNameTypeForPayload.ChannelReportedRevenue],
        isGA: false,
        tooltipId: "channelReportedRevenue",
    },
    {
        precision: 2,
        sign: FORMATS.NUMERIC,
        id: "channel_reported_roas",
        label: "Channel ROAS",
        apiMetric: [MetricNameTypeForPayload.ChannelReportedRoas],
        isGA: false,
        tooltipId: "channelReportedRoas",
    },
    {
        precision: 0,
        sign: FORMATS.DOLLAR,
        id: "revenue",
        label: "Ecommerce Revenue",
        apiMetric: [MetricNameTypeForPayload.Revenue],
        isGA: false,
        tooltipId: "revenue",
    },
    {
        precision: 2,
        sign: FORMATS.NUMERIC,
        id: "roas",
        label: "Ecommerce ROAS",
        apiMetric: [MetricNameTypeForPayload.Spend, MetricNameTypeForPayload.Revenue],
        isGA: false,

        tooltipId: "roas",
    },
    {
        precision: 0,
        sign: FORMATS.NUMERIC,
        id: "new_customers",
        label: "Ecommerce New Customers",
        apiMetric: [MetricNameTypeForPayload.NewCustomers],
        isGA: false,
        tooltipId: "channelReportedCustomers",
    },
    {
        precision: 2,
        sign: FORMATS.DOLLAR,
        id: "cac",
        label: "Ecommerce Blended CAC",
        apiMetric: [MetricNameTypeForPayload.Spend, MetricNameTypeForPayload.NewCustomers],
        isGA: false,
        tooltipId: "channelReportedCac",
    },
    {
        precision: 0,
        sign: FORMATS.NUMERIC,
        id: "orders",
        label: "Ecommerce Orders",
        apiMetric: [MetricNameTypeForPayload.Orders],
        isGA: false,
        tooltipId: "orders",
    },
    {
        precision: 2,
        sign: FORMATS.DOLLAR,
        id: "average_order",
        label: "Ecommerce Average Order Value",
        apiMetric: [MetricNameTypeForPayload.Revenue, MetricNameTypeForPayload.Orders],
        isGA: false,
        tooltipId: "aov",
    },
    {
        precision: 0,
        sign: FORMATS.NUMERIC,
        id: "abandoned_checkouts",
        label: "Abandoned Checkouts",
        apiMetric: [MetricNameTypeForPayload.AbandonedCheckouts],
        isGA: false,
        tooltipId: "googleMetrics",
    },
    {
        precision: 0,
        sign: FORMATS.NUMERIC,
        id: "web_users",
        label: "GA4 Web Users",
        apiMetric: [MetricNameTypeForPayload.WebUsers],
        isGA: true,
        tooltipId: "googleMetrics",
    },
    {
        precision: 0,
        sign: FORMATS.NUMERIC,
        id: "web_sessions",
        label: "GA4 Web Sessions",
        apiMetric: [MetricNameTypeForPayload.WebSessions],
        isGA: true,
        tooltipId: "googleMetrics",
    },
    {
        precision: 2,
        sign: FORMATS.PERCENT,
        id: "web_bounce_rate",
        label: "GA4 Web Bounce Rate",
        apiMetric: [MetricNameTypeForPayload.webBounceRate],
        isGA: true,
        tooltipId: "googleMetrics",
    },
];

export enum DataValueView {
    Absolute = "absolute",
    Percentage = "percentage",
}

export const dataValueViewOptions = [
    { label: "Absolute", id: DataValueView.Absolute, isDisabled: false },
    { label: "% of Total", id: DataValueView.Percentage, isDisabled: false },
];

export const chartModeOptions = [
    { label: "Lines", id: ChartModes.LINES, isDisabled: false },
    { label: "Stacked", id: ChartModes.STACKED, isDisabled: false },
];
