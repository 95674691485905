import React, { useContext, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useTheme } from "@mui/system";

import { FORECASTED_CAMPAIGNS_HEADERS } from "../../../../consts/optimizationPage/optimizationPage";
import {
    IScenario,
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    ICard,
} from "../../../../interfaces/entities/IScenario";
import { TableRowsLoader } from "../../../TableLoader/TableLoader";
import { OutcomeTableHeader } from "./OutcomeTableHeader";
import { OutcomeTableRow } from "./OutcomeTableRow";
import { optimizationStyle } from "../../../../containers/Optimization/optimizationStyle";
import { IDictionary } from "src/interfaces/IDictionary";
import { OptimizationOutcomeContext } from "src/containers/OptimizationOutcomePage/OptimizationOutcomePage";
import { getComparator, stableSort } from "src/utils/sort";
import { OutcomeTableTotalRow } from "./OutcomeTableTotalRow";
import { recommendationsTableStyle } from "../RecommandationTabs/RecommandationTab";

interface IOutcomeCampaignTable {
    cards: ICard[];
    campaignData: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    scenario: IScenario;
    allForecastedTotalAllocation: IDictionary;
    isTableLoading: boolean;
    compareToLabel: string;
}

export const OutcomeCampaignTable: React.FC<IOutcomeCampaignTable> = ({
    cards,
    campaignData,
    scenario,
    allForecastedTotalAllocation,
    isTableLoading,
    compareToLabel,
}) => {
    const classes = { ...recommendationsTableStyle(), ...optimizationStyle(useTheme()) };
    const optimizationOutcomeContext = useContext(OptimizationOutcomeContext);

    const [campaignOpenId, setCampaignOpenId] = useState<string>("");
    const filteredHeader = (cards || []).filter((h: any) => h.hidden === false);

    useEffect(() => {
        setCampaignOpenId("");
    }, [compareToLabel, optimizationOutcomeContext?.metricRate]);

    const campaignRowClick = (campaignId: string) => {
        setCampaignOpenId((id) => (id === campaignId ? "" : campaignId));
    };

    return (
        <TableContainer className={classes.recommendationTable}>
            <Table>
                <OutcomeTableHeader scenario={scenario} compareToLabel={compareToLabel} cards={cards} />
                {isTableLoading ? (
                    <TableRowsLoader rowsNum={campaignData.length + 1} colsNum={FORECASTED_CAMPAIGNS_HEADERS.length} />
                ) : (
                    <TableBody className={classes.tableBodyStyle}>
                        {campaignData.length ? (
                            <>
                                {stableSort(campaignData, getComparator("asc", "campaignName")).map(
                                    (campaignRow: any) => {
                                        return (
                                            <OutcomeTableRow
                                                cards={cards}
                                                key={campaignRow.campaignId}
                                                rowClick={() => campaignRowClick(campaignRow.campaignId || "")}
                                                campaignRow={
                                                    campaignRow as IMetricAttributionTableValuesTransformedWithSpendAllocation
                                                }
                                                classes={classes}
                                                campaignOpenId={campaignOpenId}
                                                scenario={scenario}
                                                campaignData={campaignData}
                                                compareToLabel={compareToLabel}
                                            />
                                        );
                                    },
                                )}
                                <OutcomeTableTotalRow header={filteredHeader} row={allForecastedTotalAllocation} />
                            </>
                        ) : (
                            <TableRow>
                                <TableCell
                                    className={classes.outcomeTableCell}
                                    colSpan={FORECASTED_CAMPAIGNS_HEADERS.length}
                                    align="center"
                                    sx={{ color: "rgba(0, 0, 0, 0.6)!important" }}
                                >
                                    No campaigns
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};
