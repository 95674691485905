import get from "lodash/get";
import { MEMBERS_CHIPS_VALUE } from "../../consts/chipsValue";
import { IChips } from "../../interfaces/IChips/IChips";
import { IMembersResponse } from "../../interfaces/IMembersResponse";
import { ISuperAdminMembersResponse } from "../../interfaces/ISuperAdminMembersResponse";
import { IMember } from "../../interfaces/entities/IMember";
import { IMemberBody, ISuperAdminUserBody } from "../../reduxState/apis/memberApi";
import { keysToCamelCase } from "../../utils/format";

export const checkChips = (members: IMember[]) => {
    if (members && members.length > 0) {
        return (
            members.filter((member) => member.role === "admin").length > 0 &&
            members.filter((member) => member.role === "member").length > 0
        );
    }

    return false;
};

export const isSelect = (id: number | string, selectedMembers: Array<number | string>) => {
    return selectedMembers.includes(+id);
};

export const generateFilterValue = (filter: number) => {
    switch (filter) {
        case 0:
            return "all";
        case 1:
            return "admin";
        case 2:
            return "member";
        default:
            return "";
    }
};

export const getFilteredValue = (
    members: IMember[] | undefined,
    filterToParse: number,
    selectedMembers: Array<number | string>,
) => {
    const filter = generateFilterValue(filterToParse);
    let filteredValue;
    if (members && members.length > 0) {
        filteredValue = members.filter(
            (member) => member.id && (!filter || filter === "all" || filter === member.role),
        );
    }
    if (filteredValue) {
        return filteredValue.map((member: IMember) => ({
            ...member,
            isChecked: isSelect(member.id, selectedMembers),
        }));
    }

    return [];
};

export const transformMembers = (
    members: IMembersResponse[],
    included: Array<{ type: string; id: string; attributes: any; relationships: any }>,
): IMember[] => {
    return members?.map((member) => {
        const { attributes, relationships } = member;
        const companies = (get(relationships, "companies.data") || []).map((c: { id: string }) => c.id);
        const roles = (get(relationships, "users_roles.data") || []).map((c: { id: string }) => c.id);

        const rolesForUser = included.filter((item) => item.type === "users_roles" && roles.includes(item.id));
        const transformedMember = keysToCamelCase(attributes);
        return {
            id: member.id,
            companies,
            companyCount: companies.length,
            rolesByCompanyId: rolesForUser.reduce((acc, v) => {
                const company = get(v, "relationships.company.data") || {};
                return Object.assign(acc, {
                    [company.id]: {
                        id: v.id,
                        userId: member.id,
                        companyId: company.id,
                        role: v.attributes.role,
                    },
                });
            }, {}),
            ...transformedMember,
        };
    });
};

export const generateMemberBody = (body: IMemberBody, flag?: boolean) => {
    const resultBody: { data: IMembersResponse } = {
        data: {
            attributes: {
                first_name: body.firstName,
                last_name: body.lastName,
                email: body.email,
                role: body.role || "",
            },
            type: "users",
        },
    };

    if (body.avatar) {
        resultBody.data.attributes.avatar = body.avatar;
        resultBody.data.attributes.avatar_file_name = body.avatarName || "";
    }

    return resultBody;
};
export const generateSuperAdminMemberBody = (body: ISuperAdminUserBody) => {
    const resultBody: { data: ISuperAdminMembersResponse } = {
        data: {
            attributes: {
                first_name: body.firstName,
                last_name: body.lastName,
                email: body.email,
                calendar_url: body.calendarUrl || "",
                roles: body.roles || [],
            },
            type: "users",
        },
    };

    if (typeof body.isSuperadmin === "boolean") {
        resultBody.data.attributes.is_superadmin = body.isSuperadmin;
    }

    if (body.avatar) {
        resultBody.data.attributes.avatar = body.avatar;
        resultBody.data.attributes.avatar_file_name = body.avatarName || "";
    }

    return resultBody;
};

export const getSearchedAndFilteredMembers = (members: IMember[], search: string, filter: number, orgId: string) => {
    if (members && members.length) {
        let resultMembers = members;
        if (search) {
            resultMembers = resultMembers.filter((member: IMember) =>
                `${member.firstName.toLowerCase()} ${member.lastName.toLowerCase()}`.includes(search.toLowerCase()),
            );
        }
        if (filter) {
            resultMembers = resultMembers.filter(
                (member: IMember) =>
                    member &&
                    member.rolesByCompanyId &&
                    member.rolesByCompanyId[orgId] &&
                    member.rolesByCompanyId[orgId].role.toLowerCase() === generateFilterValue(filter),
            );
        }

        return resultMembers;
    }

    return [];
};

export const getChipsValues = (members: IMember[], orgId: string) => {
    const newChipsValue: IChips[] = [];
    MEMBERS_CHIPS_VALUE.map((filter) => {
        const items = getSearchedAndFilteredMembers(members, "", filter.key, orgId);
        if (filter.isAll || items.length) {
            newChipsValue.push({
                ...filter,
                disabled: false,
            });
        } else {
            newChipsValue.push({
                ...filter,
                disabled: true,
            });
        }
    });
    return newChipsValue;
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    return date.toLocaleDateString("en-US");
};
