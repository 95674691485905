import { Box } from "@mui/system";
import { format, startOfYesterday, subDays } from "date-fns";
import * as echarts from "echarts";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getModeledMetricLabelBasedOnService } from "src/consts/performancePaidPage/performancePaidPage";
import { defaultRevenueSourceSelector } from "src/reduxState/slices/connectorsSlice";
import { FORMATS } from "../../../../enums/Formats";
import { IForecast } from "../../../../interfaces/entities/IForecast";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { calculateROAS, formatValue, validateDateBetweenTwoDates } from "../../../../services/utils";
import { IChartValue } from "./ForecastTab";
import {
    CONFIDENCE_COVERAGE_OPACITY,
    colors,
    confidenceColorStops,
    getColorFromGradient,
    getForecastTooltip,
} from "./forecastUtils";

interface IForecastChart {
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
    allForecastData: IForecast | undefined;
    tableValues: {
        [key: string]: IChartValue;
    };
    chartStyle: {
        chartContainer: any;
        legend: any;
        grid: any;
    };
}

export const ForecastChart: FC<IForecastChart> = ({
    performanceCampaignData,
    allForecastData,
    tableValues,
    chartStyle,
}) => {
    const { campaignId } = performanceCampaignData;
    const [isConfidence, setIsConfidence] = useState(false);
    const defaultRevenueSource = useSelector(defaultRevenueSourceSelector);
    const modeledMetricLabel = getModeledMetricLabelBasedOnService(defaultRevenueSource);

    let forecastChart: any = null;
    let series: any[] = [];

    if (allForecastData && allForecastData.spend) {
        const {
            totalBestCaseScenario,
            totalWorstCaseScenario,
            totalSaturatedRevenue,
            saturatedSpend,
            confidenceBins,
            revenue,
            spend,
            dates,
        } = allForecastData;

        let revenueSpendDataValuesWithDates: Array<{ date: string; value: [number, number] }> = [];
        let last28Data: Array<{ date: string; value: [number, number] }> = [];
        let notInLast28Data: Array<{ date: string; value: [number, number] }> = [];

        const formattedDate28DaysAgo = format(subDays(new Date(), 28), "yyyy-MM-dd");
        const formattedYesterday = format(startOfYesterday(), "yyyy-MM-dd");

        dates.forEach((date, index) => {
            const spendData = spend[index];
            const revenueData = revenue[index];

            const dataEntry: {
                date: string;
                value: [number, number];
            } = { date, value: [spendData, revenueData] };
            revenueSpendDataValuesWithDates = revenueSpendDataValuesWithDates.concat([dataEntry]);

            const isInLast28Days = validateDateBetweenTwoDates(formattedDate28DaysAgo, formattedYesterday, date);

            if (isInLast28Days) {
                last28Data = last28Data.concat([dataEntry]);
            } else {
                notInLast28Data = notInLast28Data.concat([dataEntry]);
            }
        });

        const getLinePoints = Object.keys(tableValues).map((v) => [tableValues[v].spend, undefined]);

        const saturatedRevenueSpendValue = [
            ...saturatedSpend.map((spend, index) => [spend, totalSaturatedRevenue[index]]),
            ...getLinePoints,
        ];

        const ROASSeries = saturatedSpend
            .filter((spend) => spend > 0)
            .map((spend, index: number) => [spend, calculateROAS(spend, totalSaturatedRevenue[index])]);

        const seriesPlottedForPoints = Object.keys(tableValues).map((v) => {
            return {
                xAxis: tableValues[v].spend,
                lineStyle: { color: colors[v], type: "dotted" },
            };
        });

        const confidenceScore = confidenceBins
            ? confidenceBins.map((val) => {
                  const color = getColorFromGradient(val.confidence);
                  return [
                      {
                          xAxis: val.startBin,
                          itemStyle: {
                              color,
                              opacity: CONFIDENCE_COVERAGE_OPACITY,
                          },
                      },
                      {
                          xAxis: val.endBin,
                          itemStyle: {
                              color,
                              opacity: CONFIDENCE_COVERAGE_OPACITY,
                          },
                      },
                  ];
              })
            : [];

        series = [
            {
                name: "Predicted Revenue",
                data: saturatedRevenueSpendValue,
                type: "line",
                color: colors.predictedRevenue,
                showSymbol: false,
                smooth: true,
                markLine: {
                    symbol: ["none", "none"],
                    label: {
                        show: false,
                    },
                    data: seriesPlottedForPoints,
                },
                emphasis: {
                    focus: "self",
                },
                symbol: "circle",
                legendHoverLink: false,
            },
            {
                name: "Predicted ROAS",
                data: ROASSeries,
                type: "line",
                showSymbol: false,
                color: colors.predictedROAS,
                lineStyle: {
                    type: "dashed",
                    dashOffest: 1,
                },
                yAxisIndex: 1,
                emphasis: {
                    focus: "series",
                },
                symbol: "circle",
                legendHoverLink: false,
            },
            {
                name: "Best Case",
                data: totalBestCaseScenario?.map((c: number, index: number) => [saturatedSpend[index], c]),
                type: "line",
                showSymbol: false,
                z: -1,
                areaStyle: {
                    color: "#d5ebf3",
                },
                color: colors.bestCase,
                smooth: true,
                emphasis: {
                    focus: "series",
                },
                symbol: "circle",
                legendHoverLink: false,
            },
            {
                name: "Worst Case",
                data: totalWorstCaseScenario?.map((c: number, index: number) => [saturatedSpend[index], c]),
                type: "line",
                showSymbol: false,
                z: -1,
                areaStyle: {
                    color: "#ffffff",
                    opacity: 1,
                },
                color: colors.worstCase,
                smooth: true,
                emphasis: {
                    focus: "series",
                },
                symbol: "circle",
                legendHoverLink: false,
            },
            {
                name: `${modeledMetricLabel} Revenue`,
                data: notInLast28Data,
                type: "scatter",
                symbolSize: 6,
                color: colors.modeledRevenue,
                emphasis: {
                    focus: "series",
                },
                legendHoverLink: false,
                tooltip: {
                    trigger: "item",
                    show: true,
                    formatter: (params: any) => getForecastTooltip(params, tableValues, modeledMetricLabel),
                },
            },
            {
                name: "Last 28 days of spend",
                data: last28Data,
                type: "scatter",
                symbolSize: 6,
                color: colors.last28DaysRevenue,
                itemStyle: {
                    opacity: CONFIDENCE_COVERAGE_OPACITY,
                },
                emphasis: {
                    focus: "series",
                },
                legendHoverLink: false,
                tooltip: {
                    trigger: "item",
                    show: true,
                    formatter: (params: any) => getForecastTooltip(params, tableValues, modeledMetricLabel),
                },
            },
            {
                name: "Confidence",
                data: [],
                type: "bar",
                markArea: {
                    data: isConfidence ? confidenceScore : [],
                },
                color: colors.confidence,
                yAxisIndex: 2,
                legendHoverLink: false,
                silent: true,
            },
        ];
    }

    const option = {
        tooltip: {
            trigger: "axis",
            confine: "true",
            formatter: (params: any) => getForecastTooltip(params, tableValues, modeledMetricLabel),
            borderWidth: 0,
        },
        xAxis: {
            name: "Average Daily Spend",
            type: "value",
            axisTick: {
                show: false,
            },
            boundaryGap: false,
            axisLabel: {
                color: "rgba(0, 0, 0, 0.6)",
                hideOverlap: true,
                formatter(value: number) {
                    return formatValue(value, FORMATS.DOLLAR, 0);
                },
            },
            axisLine: {
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.23)",
                    width: 1,
                },
            },
            splitLine: {
                show: false,
            },
            nameLocation: "middle",
            nameTextStyle: {
                align: "center",
                verticalAlign: "top",
                lineHeight: 36,
                color: "rgba(0, 0, 0, 0.6)",
            },
            max: "dataMax",
        },
        yAxis: [
            {
                name: "Revenue",
                type: "value",
                axisLabel: {
                    formatter(value: number) {
                        return formatValue(value, FORMATS.DOLLAR, 0);
                    },
                },
                splitLine: {
                    show: false,
                },
            },
            {
                name: "ROAS",
                type: "value",
                axisLabel: {
                    formatter(value: number) {
                        return formatValue(value, FORMATS.NUMERIC, 1);
                    },
                },
                splitLine: {
                    show: false,
                },
            },
            {
                name: "Confidence",
                type: "value",
                position: "right",
                offset: 50,
                axisLabel: {
                    formatter(value: number) {
                        return formatValue(value, FORMATS.NUMERIC, 1);
                    },
                    margin: 15,
                    color: "rgba(0, 0, 0, 0.6)",
                },
                min: 0,
                max: 1,
                interval: 0.2,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        cap: "square",
                        width: 15,
                        color: {
                            type: "canvas",
                            x: 0,
                            y: 1,
                            x2: 0,
                            y2: 0,
                            colorStops: confidenceColorStops,
                        },
                        smooth: false,
                        opacity: CONFIDENCE_COVERAGE_OPACITY,
                    },
                },
                splitLine: {
                    show: false,
                },
                nameTextStyle: {
                    verticalAlign: "top",
                    align: "center",
                    lineHeight: 65,
                    color: "rgba(0, 0, 0, 0.6)",
                },
                nameLocation: "middle",
                silent: true,
                show: isConfidence,
            },
        ],
        series: [...series],
        grid: chartStyle.grid,
        legend: { ...chartStyle.legend, selected: { ...chartStyle.legend.selected, Confidence: isConfidence } },
    };

    const initChart = () => {
        if (document.getElementById(`revenue-forecasting-${campaignId}`)) {
            forecastChart = echarts.init(document.getElementById(`revenue-forecasting-${campaignId}`)!, "prescient");
            if (forecastChart) {
                forecastChart.resize();
                forecastChart.setOption(option, { noMerge: true, lazyUpdate: true });
                forecastChart.on("legendselectchanged", handleLegendSelectChanged);
            }
        }
    };

    const handleLegendSelectChanged = (params: any) => {
        setIsConfidence(params.selected.Confidence);
    };

    useEffect(() => {
        initChart();
        return () => {
            if (forecastChart) {
                forecastChart.off("legendselectchanged", handleLegendSelectChanged);
            }
        };
    }, [JSON.stringify(allForecastData), JSON.stringify(tableValues), isConfidence]);

    return (
        <>
            <Box id={`revenue-forecasting-${campaignId}`} style={chartStyle.chartContainer}></Box>
        </>
    );
};
