import { Skeleton, Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FC } from "react";
import { useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { isCampaignAvailableSelector, isModelingAvailableSelector } from "../../reduxState/slices/settingsSlice";
import { IHeadCell as IEmailHeadCell } from "../../consts/emailPage/emailPage";
import {
    IExtraPercentageRevenue,
    IHeadCell,
    NEGATIVE_TREND_GOOD,
} from "../../consts/performancePaidPage/performancePaidPage";
import { FORMATS } from "../../enums/Formats";
import { formattedDateSelector, isCompareSelector } from "../../reduxState/slices/dateFilterSlice";
import { formatValue } from "../../services/utils";
import { pastRangeBasedOnCurrentRange } from "../DateRangeFilter/reactDateRangeUtils";
import { TRUE_METRICS } from "../../consts/performancePaidPage/performancePaidPage";
import { PercentageTrendChip } from "../core/Chips/PercentageTrendChip";

interface IAggregateMetricHeader {
    tableHeader: IHeadCell[] | IEmailHeadCell[] | IExtraPercentageRevenue[];
    metricTotalMapObj: {
        [key: string]: {
            title: string;
            info: string;
        };
    };
    isMetricLoading: boolean;
    totalTableAmount: any;
    totalPercentageTableAmount: any;
}

export const AggregateMetricHeader: FC<IAggregateMetricHeader> = ({
    tableHeader,
    metricTotalMapObj,
    isMetricLoading,
    totalTableAmount,
    totalPercentageTableAmount,
}) => {
    const compareToPast = useSelector(isCompareSelector);
    const selectFormattedDates = useSelector(formattedDateSelector);
    const isCampaignAvailable = useSelector(isCampaignAvailableSelector);
    const isModelingAvailable = useSelector(isModelingAvailableSelector);
    const pastRangeString = pastRangeBasedOnCurrentRange(selectFormattedDates);

    const getPercentageValue = (value: number, isPositiveTrendGood: boolean) => {
        return typeof value === "number" ? (
            <PercentageTrendChip
                value={value}
                isPositiveTrendGood={isPositiveTrendGood}
                isSpecialChip={true}
                pastRangeString={pastRangeString}
            />
        ) : (
            value
        );
    };
    const getPercentageChip = (element: any) => {
        const formattedPercentageVal = getPercentageValue(
            totalPercentageTableAmount[element.id],
            NEGATIVE_TREND_GOOD.indexOf(element.id.toUpperCase()) === -1,
        );

        return formattedPercentageVal;
    };
    const isMetricAvailable = (column: any, metricData: any) => {
        if (!isCampaignAvailable || (TRUE_METRICS.includes(column.id) && !isModelingAvailable)) {
            return false;
        }
        return true;
    };
    const columnText = (column: any, metricData: any) => {
        if(!isMetricAvailable(column, metricData)) { return "N/A"; }

        return formatValue(
                totalTableAmount[column.id],
                column.sign || FORMATS.NUMERIC,
                column.fixed || 0,
                );
    };

    return (
        <Stack direction="row" alignItems="flex-start" gap={3} flexWrap="wrap">
            {tableHeader.map((column: any) => {
                if (Object.keys(metricTotalMapObj).includes(column.id)) {
                    const metricData = metricTotalMapObj[column.id];
                    return (
                        <Box key={column.id} component="span" sx={{ p: 0 }}>
                            <Typography
                                color="text.primary"
                                variant="h3"
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                {isMetricLoading ? (
                                    <Skeleton variant="rounded" width={100} height={32} />
                                ) : (
                                    <>
                                        {columnText(column, metricData)}
                                        {compareToPast && getPercentageChip(column)}
                                    </>
                                )}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                {metricData.title}
                                {metricData.info && (
                                    <Tooltip title={<span style={{ fontSize: "14px" }}>{metricData.info}</span>} arrow>
                                        <InfoOutlinedIcon sx={{ height: "14px", width: "14px" }} />
                                    </Tooltip>
                                )}
                            </Typography>
                        </Box>
                    );
                }
            })}
        </Stack>
    );
};
