import React, { useCallback, useState } from "react";
import { css } from "@emotion/css";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";

import { customSortData } from "../../services/utils";
import { CustomColumnFilter } from "../CustomColumnFilter/CustomColumnFilter";
import { ICard } from "src/interfaces/entities/IScenario";
import { useTheme } from "@mui/material/styles";

interface IDraggableColumnSelect {
    updateColumnsInSettingsApi: any;
    selectedTableColumns: any[];
    setselectedTableColumns: React.Dispatch<React.SetStateAction<any[]>>;
    handleTableCol: (column: any) => void;
    onClickHandleOpen: () => void;
    cards: ICard[];
    setCards: React.Dispatch<React.SetStateAction<any[]>>;
}

const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
};

export const DraggableColumnSelect: React.FC<IDraggableColumnSelect> = ({
    handleTableCol,
    selectedTableColumns,
    setselectedTableColumns,
    updateColumnsInSettingsApi,
    onClickHandleOpen,
    cards,
    setCards,
}) => {
    const [dragAndDrop, setDragAndDrop] = useState<any>(initialDnDState);

    const onDragStart = (event: React.DragEvent<HTMLLIElement>) => {
        const initialPosition = Number(event.currentTarget.dataset.position);

        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: initialPosition,
            isDragging: true,
            originalOrder: cards,
        });
    };

    const onDragOver = useCallback(
        (event: React.DragEvent<HTMLLIElement>) => {
            event.preventDefault();

            const newList = [...dragAndDrop.originalOrder];

            // index of the item being dragged
            const draggedFrom = dragAndDrop.draggedFrom;

            // index of the droppable area being hovered
            const draggedTo = Number(event.currentTarget.dataset.position);

            const itemDragged = newList[draggedFrom];

            newList.splice(draggedFrom, 1);
            newList.splice(draggedTo, 0, itemDragged);

            if (draggedTo !== dragAndDrop.draggedTo && dragAndDrop.isDragging) {
                setDragAndDrop({
                    ...dragAndDrop,
                    updatedOrder: newList,
                    draggedTo,
                });
            }
        },
        [dragAndDrop],
    );

    const onDrop = () => {
        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: null,
            draggedTo: null,
            isDragging: false,
        });

        const ids = dragAndDrop.updatedOrder.map((p: any) => p.id);
        const sortMemberByCustomStatus = customSortData(
            selectedTableColumns.filter((p) => p.id !== "campaignName"),
            "id",
            ids,
        );

        setCards(sortMemberByCustomStatus);
        setselectedTableColumns([selectedTableColumns[0], ...sortMemberByCustomStatus]);
    };

    const onDragLeave = () => {
        setDragAndDrop({
            ...dragAndDrop,
            draggedTo: null,
        });
    };

    return (
        <CustomColumnFilter
            cards={cards}
            handleTableCol={handleTableCol}
            isDraggable={true}
            onClickHandleOpen={onClickHandleOpen}
            updateColumnsInSettingsApi={updateColumnsInSettingsApi}
            dragAndDrop={dragAndDrop}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            icon={<ViewColumnOutlinedIcon />}
        />
    );
};
