import { Link } from "@mui/material";
import groupBy from "lodash/groupBy";
import { FORMATS } from "../../../../enums/Formats";
import { ISecondOrderEffects } from "../../../../interfaces/entities/ISecondOrderEffects";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { getTotalKeyAttributionMetrics } from "../../../../services/performancePage/performancePage";
import { calculateROAS, capitalizeFirstLetter, formatValue } from "../../../../services/utils";
import { HALO_EFFECT_PATH } from "src/consts/path/path";
import { IDictionary } from "src/interfaces/IDictionary";
import {
    AMAZON_SELLING_PARTNER_REVENUE,
    AMAZON_SPEND,
    DEFAULT_TARGET_REVENUE,
    DIRECT,
    DIRECT_SPEND,
    ORGANIC,
    SECONDARY_TARGET_REVENUE,
    TERTIARY_TARGET_REVENUE,
    QUATERNARY_TARGET_REVENUE,
} from "src/consts/targetRevenue";

const calculateTotal = (array: any) => {
    return array.reduce(
        (previousValue: number, currentValue: { metricValue: number }) =>
            previousValue + (currentValue.metricValue || 0),
        0,
    );
};

export const getDefaultRevenueFromReveueArr = (revenue: string[]) => {
    const primaryRev = revenue.filter((c) => c.toLowerCase() === DEFAULT_TARGET_REVENUE)[0];

    if (primaryRev) {
        return primaryRev;
    }

    const secondaryRev = revenue.filter((c) => c.toLowerCase() === SECONDARY_TARGET_REVENUE)[0];

    if (secondaryRev) {
        return secondaryRev;
    }

    const tertiaryRev = revenue.filter((c) => c.toLowerCase() === TERTIARY_TARGET_REVENUE)[0];

    if (tertiaryRev) {
        return tertiaryRev;
    }

    const rev = revenue.filter((c) => c.toLowerCase() === QUATERNARY_TARGET_REVENUE)[0];

    if (!rev) {
        return revenue[0];
    }

    return rev;
};

export const getKeyAttributionData = (
    chartData: ISecondOrderEffects[],
    performanceCampaignData: IMetricAttributionTableValuesTransformed,
) => {
    const groupByMetricName = groupBy(chartData, "metricName");
    const mappingMetricName = {
        FIRST_ORDER_CONVERSIONS: "totalFirstOrderNewCustomers",
        SECOND_ORDER_CONVERSIONS: "totalSecondOrderNewCustomers",
        SPEND: "totalSpend",
    };
    const allCalculatedValues = getTotalKeyAttributionMetrics(
        groupByMetricName,
        mappingMetricName,
        true,
        performanceCampaignData,
    );

    const legendBoxArr = [
        {
            key: "newCustomers",
            name: "Base new customers",
            secondaryMetricName: "CAC",
            metricValue: allCalculatedValues.totalFirstOrderNewCustomers,
            formattedMetricValue: formatValue(allCalculatedValues.totalFirstOrderNewCustomers, FORMATS.NUMERIC, 0),
            secondaryMetricValue: allCalculatedValues.baseCAC,
            formattedSecondaryMetricValue: formatValue(allCalculatedValues.baseCAC, FORMATS.DOLLAR, 2),
            color: "#DC267F",
            tooltip: (
                <span>
                    <b>Base new customers</b> refers to new customers earned as a result of customers seeing and
                    interacting with your campaign. It is most analogous to "last click attribution”.
                </span>
            ),
            sign: FORMATS.NUMERIC,
            display: true,
        },
        {
            key: "haloNewCutomers",
            name: "Halo effect",
            secondaryMetricName: "CAC",
            metricValue: allCalculatedValues.totalSecondOrderNewCustomers,
            formattedMetricValue: formatValue(allCalculatedValues.totalSecondOrderNewCustomers, FORMATS.NUMERIC, 0),
            secondaryMetricValue: allCalculatedValues.haloCAC,
            formattedSecondaryMetricValue: formatValue(allCalculatedValues.haloCAC, FORMATS.DOLLAR, 2),
            color: "#6d69f5",
            tooltip: (
                <span>
                    <b>Halo effects</b> tell us when your campaigns have had downstream impacts on purchase behaviors
                    across paid search, organic search, or direct traffic. For more information{" "}
                    <Link href={HALO_EFFECT_PATH} target="_blank" style={{ color: "inherit", fontWeight: "bold" }}>
                        click here
                    </Link>
                    !
                </span>
            ),
            sign: FORMATS.NUMERIC,
            display: true,
        },
    ];

    const totalMetrics = [
        {
            name: `New Customers (${formatValue(allCalculatedValues.totalCAC, FORMATS.DOLLAR, 2)} CAC)`,
            value: formatValue(allCalculatedValues.totalNewCustomers, FORMATS.NUMERIC, 0),
        },
    ];

    return {
        legendBoxArr,
        totalMetrics,
        allCalculatedValues,
        chartData,
        total: allCalculatedValues.totalNewCustomers,
        id: `attribution-metrics-chart-${performanceCampaignData.campaignId}`,
    };
};

export const getHaloEffectBreakdownData = (
    chartData: ISecondOrderEffects[],
    performanceCampaignData: IMetricAttributionTableValuesTransformed,
) => {
    const revenueHaloEffects = chartData.filter((m) => m.metricName.includes("SECOND_ORDER_CONVERSIONS"));
    const groupBytargetChannel = groupBy(revenueHaloEffects, "targetChannelName");
    const mappingChannels = {
        [DIRECT.toUpperCase()]: "totalDirectChannel",
        [ORGANIC.toUpperCase()]: "totalOrganicChannel",
        [DIRECT_SPEND.toUpperCase()]: "totalPaidChannel",
        "": "totalFirstorderChannel",
    };

    const haloAllCalculatedValues = getTotalKeyAttributionMetrics(groupBytargetChannel, mappingChannels);

    const haloLegendBoxArr = [
        {
            key: "paidSearch",
            name: "Paid search",
            metricValue: haloAllCalculatedValues.totalPaidChannel,
            formattedMetricValue: formatValue(haloAllCalculatedValues.totalPaidChannel, FORMATS.NUMERIC, 0),
            color: "#587bf7",
            tooltip: (
                <span>
                    <b>Paid search</b> measures the new customers driven by awareness for your brand created by this
                    campaign, which drove users to search and place an order after interacting with a paid search
                    campaign.
                </span>
            ),
            sign: FORMATS.NUMERIC,
            display: true,
        },
        {
            key: "organicSearch",
            name: "Organic search",
            metricValue: haloAllCalculatedValues.totalOrganicChannel,
            formattedMetricValue: formatValue(haloAllCalculatedValues.totalOrganicChannel, FORMATS.NUMERIC, 0),
            color: "#6d69f5",
            tooltip: (
                <span>
                    <b>Organic search</b> measures the new customers driven by awareness for your brand created by this
                    campaign, which drove users to search and place an order after interacting with an organic search
                    URL.
                </span>
            ),
            sign: FORMATS.NUMERIC,
            display: true,
        },
        {
            key: "directTraffic",
            name: "Direct traffic",
            metricValue: haloAllCalculatedValues.totalDirectChannel,
            formattedMetricValue: formatValue(haloAllCalculatedValues.totalDirectChannel, FORMATS.NUMERIC, 0),
            color: "#f5b945",
            tooltip: (
                <span>
                    <b>Direct traffic</b> measures the new customers driven by awareness for your brand created by this
                    campaign, which drove users to visit your website directly and place an order.
                </span>
            ),
            sign: FORMATS.NUMERIC,
            display: true,
        },
    ];

    const haloToalMetrics = [
        {
            name: "Total Halo Effect",
            value: formatValue(haloAllCalculatedValues.totalHaloEffect, FORMATS.NUMERIC, 0),
        },
    ];

    return {
        legendBoxArr: haloLegendBoxArr,
        totalMetrics: haloToalMetrics,
        allCalculatedValues: haloAllCalculatedValues,
        total: haloAllCalculatedValues.totalHaloEffect,
        chartData,
        id: `halo-effect-chart-${performanceCampaignData.campaignId}`,
    };
};

const getMetricCleanName = (key: string) => {
    return key ? capitalizeFirstLetter(key.replace("_REVENUE", "").toLocaleLowerCase().replace(/_/g, " ")) : "";
};

export const getKeyAttributionMetricsForRevenue = (
    performanceCampaignData: IMetricAttributionTableValuesTransformed,
    groupByMetricName: IDictionary<Array<Pick<ISecondOrderEffects, "metricValue" | "target">>>,
    defaultRevenue: string,
) => {
    const firstOrderRevenue = { BASE_REVENUE: 0 };
    let secondOrderRevenue = defaultRevenue ? { [defaultRevenue]: 0 } : {};

    // Base revenue filter based on default revenue
    firstOrderRevenue.BASE_REVENUE =
        groupByMetricName.FIRST_ORDER_REVENUE?.filter((c) => c.target === defaultRevenue).reduce(
            (acc: any, c: { metricValue: any }) => acc + c.metricValue,
            0,
        ) || 0;

    // Halo Revenue based on target group
    const secondOrderGrpByTarget = groupBy(groupByMetricName.SECOND_ORDER_REVENUE, "target");
    const defaultSecondOrderRevenue = getDefaultRevenueFromReveueArr(Object.keys(secondOrderGrpByTarget));
    const secondOrderRevenueKeys = [defaultSecondOrderRevenue, AMAZON_SELLING_PARTNER_REVENUE.toUpperCase()];

    Object.entries(secondOrderGrpByTarget).forEach(([key, values]: any) => {
        if (secondOrderRevenueKeys.includes(key)) {
            const totalRevenue = calculateTotal(values) || 0;
            secondOrderRevenue = {
                ...secondOrderRevenue,
                [key]: totalRevenue,
            };
        }
    });

    // Add metric here if new metric added
    const keyAttributionMetricObj: IDictionary = {
        BASE_REVENUE: {
            tooltip: (
                <span>
                    <b>Base revenue</b> refers to revenue earned as a result of customers seeing and interacting with
                    your campaign. It is most analogous to “last click attribution”.
                </span>
            ),
            color: "#E5368A",
            display: true,
        },
        [defaultRevenue]: {
            tooltip: (
                <span>
                    <b>Halo effects</b> tell us when your campaigns have had downstream impacts on purchase behaviors
                    across paid search, organic search, or direct traffic. For more information{" "}
                    <Link href={HALO_EFFECT_PATH} target="_blank" style={{ color: "inherit", fontWeight: "bold" }}>
                        click here
                    </Link>
                    !
                </span>
            ),
            color: "#6d69f5",
            display: true,
        },
        AMAZON_SELLING_PARTNER_REVENUE: {
            tooltip: (
                <span>
                    <b>Amazon revenue</b> measures the revenue driven by awareness for your brand created by this
                    campaign, which drove users to place an order on your Amazon store.
                </span>
            ),
            color: "#FF8800",
            display: true,
        },
    };

    return Object.entries({ ...firstOrderRevenue, ...secondOrderRevenue }).map(([key, values]) => {
        const secondOrderKeys = Object.keys(secondOrderRevenue);
        const roas = calculateROAS(performanceCampaignData.spend, values);
        return {
            key,
            name: secondOrderKeys.includes(key)
                ? `Halo effect - ${getMetricCleanName(key)}`
                : `Base revenue - ${getMetricCleanName(defaultRevenue)}`,
            secondaryMetricName: "ROAS",
            metricValue: values,
            formattedMetricValue: formatValue(values, key.includes("REVENUE") ? FORMATS.DOLLAR : FORMATS.NUMERIC, 0),
            secondaryMetricValue: roas,
            formattedSecondaryMetricValue: `${
                !key.toLocaleLowerCase().includes("base") && values > 0 ? "+" : ""
            }${formatValue(roas, FORMATS.NUMERIC, 2)}`,
            color: keyAttributionMetricObj[key]?.color || "",
            tooltip: keyAttributionMetricObj[key]?.tooltip || "",
            sign: FORMATS.DOLLAR,
            display: keyAttributionMetricObj[key]?.display || true,
        };
    });
};

export const getHaloEffectBreakdownDataForRevenue = (revenueHaloEffects: ISecondOrderEffects[]) => {
    const groupBytargetChannel = groupBy(revenueHaloEffects, "targetChannelName");
    const organicChannelGrpByTarget = groupBy(groupBytargetChannel?.ORGANIC || [], "target");

    const defaultRevenue = getDefaultRevenueFromReveueArr(Object.keys(organicChannelGrpByTarget));

    let allCalculatedValues = {
        [DIRECT.toUpperCase()]: null,
        [DIRECT_SPEND.toUpperCase()]: null,
        [ORGANIC.toUpperCase()]: null,
        [AMAZON_SPEND.toUpperCase()]: null,
    };

    let organicChannelRevenue: IDictionary = {};
    let otherResoursesArr: any[] = [];

    Object.entries(groupBytargetChannel).map(([key, values]: any) => {
        if (key !== "ORGANIC") {
            allCalculatedValues = {
                ...allCalculatedValues,
                [key]:
                    values.reduce(
                        (previousValue: number, currentValue: { metricValue: number }) =>
                            previousValue + (currentValue.metricValue || 0),
                        0,
                    ) || 0,
            };
        }
    });

    Object.entries(organicChannelGrpByTarget).map(([key, values]: any) => {
        const totalRevenue = calculateTotal(values) || 0;
        if (key === defaultRevenue) {
            allCalculatedValues = { ...allCalculatedValues, ORGANIC: totalRevenue };
        } else {
            organicChannelRevenue = {
                ...organicChannelRevenue,
                [key]: totalRevenue,
            };
        }
    });

    // Add metric here if new metric added
    const mapping: IDictionary = {
        AMAZON_SELLING_PARTNER_REVENUE: {
            key: "amazonRevenue",
            name: "Organic Search - Amazon selling partner",
            metricValue: organicChannelRevenue.AMAZON_SELLING_PARTNER_REVENUE,
            formattedMetricValue: formatValue(
                organicChannelRevenue.AMAZON_SELLING_PARTNER_REVENUE || 0,
                FORMATS.DOLLAR,
                0,
            ),
            color: "#FF7700",
            tooltip: (
                <span>
                    <b>Amazon revenue</b> measures the revenue driven by awareness for your brand created by this
                    campaign, which drove users to place an order on your Amazon store.
                </span>
            ),
            sign: FORMATS.DOLLAR,
            display: true,
        },
    };

    Object.entries(organicChannelRevenue).map(([key, value]) => {
        if (key !== ORGANIC.toUpperCase() && mapping[key]) {
            otherResoursesArr = [...otherResoursesArr, mapping[key]];
        }
    });

    const amazonCard = {
        key: AMAZON_SPEND,
        name: `Paid search - Amazon Selling Partner`,
        metricValue: allCalculatedValues.AMAZON_SPEND,
        formattedMetricValue: formatValue(allCalculatedValues.AMAZON_SPEND, FORMATS.DOLLAR, 0),
        color: "#f88e14",
        tooltip: "",
        sign: FORMATS.DOLLAR,
        display: true,
    };

    const finalCards = [
        {
            key: "paidSearch",
            name: `Paid search - ${getMetricCleanName(defaultRevenue)}`,
            metricValue: allCalculatedValues[DIRECT_SPEND.toUpperCase()],
            formattedMetricValue: formatValue(allCalculatedValues[DIRECT_SPEND.toUpperCase()], FORMATS.DOLLAR, 0),
            color: "#587bf7",
            tooltip: (
                <span>
                    <b>Paid search</b> measures the revenue driven by awareness for your brand created by this campaign,
                    which drove users to search and place an order after interacting with a paid search campaign.
                </span>
            ),
            sign: FORMATS.DOLLAR,
            display: true,
        },
        {
            key: "organicSearch",
            name: `Organic search - ${getMetricCleanName(defaultRevenue)}`,
            metricValue: allCalculatedValues.ORGANIC,
            formattedMetricValue: formatValue(allCalculatedValues.ORGANIC, FORMATS.DOLLAR, 0),
            color: "#6d69f5",
            tooltip: (
                <span>
                    <b>Organic search</b> measures the revenue driven by awareness for your brand created by this
                    campaign, which drove users to search and place an order after interacting with an organic search
                    URL.
                </span>
            ),
            sign: FORMATS.DOLLAR,
            display: true,
        },
        {
            key: "directTraffic",
            name: `Direct traffic - ${getMetricCleanName(defaultRevenue)}`,
            metricValue: allCalculatedValues.DIRECT,
            formattedMetricValue: formatValue(allCalculatedValues.DIRECT, FORMATS.DOLLAR, 0),
            color: "#f5b945",
            tooltip: (
                <span>
                    <b>Direct traffic</b> measures the revenue driven by awareness for your brand created by this
                    campaign, which drove users to visit your website directly and place an order.
                </span>
            ),
            sign: FORMATS.DOLLAR,
            display: true,
        },
    ];

    return [...finalCards, amazonCard, ...otherResoursesArr].filter((c) => c.metricValue !== null);
};
