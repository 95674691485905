import { css } from "@emotion/css";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/system";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, ScrollRestoration, useLocation, useNavigate } from "react-router-dom";

import { AdBlockerWarning } from "../../components/AdBlockerWarning/AdBlockerWarning";
import CookieConsentComponent from "../../components/CookieConsent/CookieConsentComponent";
import { LightHeader as Header } from "../../components/LightHeader/LightHeader.v2";
import {
    ACCESS_DENIED_PATH,
    ACCESS_REVOKED_PATH,
    BILLING_PATH,
    CONNECTORS_PATH,
    DEFAULT_ORG_PATH,
    ECOMMERCE_PATH,
    HOME_DASHBOARD_PATH,
    HOME_PATH,
    LOGIN_PATH,
    MEMBER_PATH,
    PERFORMANCE_CAMPAIGNS_PATH,
    PERFORMANCE_ECOMMERCE_PATH,
    PERFORMANCE_PAID_PATH,
    SUPERADMIN_MEMBERS_PATH,
    TRIAL_END_PATH,
    USER_SETTINGS_PATH,
} from "../../consts/path/path";
import { useLazyGetTacticsTypeQuery } from "../../reduxState/apis/campaignTacticsApi";
import { useLazyGetAllDataSourcesQuery } from "../../reduxState/apis/dataSourcesApi";
import { adBlockSkipCountSelector, setAdBlockSkipCount } from "../../reduxState/slices/adBlockSlice";
import { selectBrokenConnectors } from "../../reduxState/slices/connectorsSlice";
import {
    selectCurrentlyViewingCode,
    selectCurrentlyViewingCompanyById,
    selectHasAccessToDashboard,
    selectIsActiveSubscription,
} from "../../reduxState/slices/organizationSlice";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../services/utils";
import { AccessDenied } from "../Errors/AccessDenied";
import { AccessRevoked } from "../Errors/AccessRevoked";
import { NotFound } from "../Errors/NotFound";
import { SubscriptionExpired } from "../Errors/SubscriptionExpired";
import { TrialEnd } from "../Errors/TrialEnd";
import { ProtectedOrgRoute } from "../RouteValidation/ProtectedOrgRoute";
import { SuperAdminRoute } from "../RouteValidation/SuperAdminRoute";
import { Settings } from "../Settings/SettingsV2";
import { ProtectedOrgRoutes, superAdminRoutes } from "./ProtectedOrgRoutes";

const useStyles = (theme: Theme) => ({
    contentContainer: css({
        padding: 0,
        position: "relative",
        top: "80px",
        [theme.breakpoints.down("md")]: {
            top: "135px",
            padding: 0,
        },
        [theme.breakpoints.down("md")]: {
            top: "130px",
        },
    }),
    content: css({
        width: "100%",
    }),
    modalWrapper: css({
        maxWidth: "552px",
    }),
    divWrapper: css({
        marginBottom: theme.spacing(2),
    }),
    spanClass: css({
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: "bold",
    }),
    cookieBtnStyle: css({
        position: "fixed",
        right: 0,
        textTransform: "initial",
        transformOrigin: "100% 100% 0px",
        height: "1.875rem",
        boxShadow: "rgba(0, 0, 0, 0.1) -4px -12px 25px 0px",
        borderRadius: "2px 2px 0px 0px",
        transform: "rotate(-90deg) translateX(100%) translateY(0px)",
        bottom: "160px",
        transition: "transform 400ms ease 0s",
        backgroundColor: "rgb(49, 140, 122)",
        zIndex: 2,
    }),
});

export const Home: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const showTopNavbar = !/(\/org\/.+\/onboarding)/.test(location.pathname);

    const orgFromPath = location.pathname.split("/")[2];

    const classes = useStyles(useTheme());
    const currentCompany = useSelector(selectCurrentlyViewingCompanyById);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [adBlockerDetected, setAdBlockerDetected] = useState(true);

    const analyticsService = useAnalyticsService();
    const adBlockSkipCount = useSelector(adBlockSkipCountSelector);
    // @ts-ignore
    const brokenConnectors = useSelector(selectBrokenConnectors);
    const dispatch = useDispatch();

    const [getAllDataSources] = useLazyGetAllDataSourcesQuery();

    const isActiveSubscription = useSelector(selectIsActiveSubscription);
    const hasAccessToDashboard = useSelector(selectHasAccessToDashboard);
    const showSubscriptionExpiredPage =
        currentCompany && currentCompany.subscription && !isActiveSubscription && !hasAccessToDashboard;

    const getDataSources = async () => {
        await getAllDataSources({});
    };

    const [getTactics] = useLazyGetTacticsTypeQuery();

    const getTacticsList = async () => {
        await getTactics({ orgId: currentCompany?.id });
    };

    const handleOpenAdBlocker = () => {
        setOpen(true);
        setAdBlockerDetected(true);
        analyticsService.logEvent("Ad Blocker Warning Displayed", {
            Page: getPathForAnalytics(location.pathname),
        });
    };

    const checkAdBlocker = () => {
        fetch(
            new Request("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js", {
                method: "HEAD",
                mode: "no-cors",
            }),
        )
            .then(() => {
                setAdBlockerDetected(false);
            })
            .catch((error) => {
                handleOpenAdBlocker();
            });
    };

    useEffect(() => {
        if (adBlockSkipCount < 3 && !open && adBlockerDetected) {
            checkAdBlocker();
        }
        getDataSources();
    }, []);

    useEffect(() => {
        if (currentCompany?.id) {
            getTacticsList();
        }
    }, [currentCompany?.id]);

    const handleCloseAdBlocker = () => {
        dispatch(setAdBlockSkipCount({}));
        setOpen(false);
        analyticsService.logEvent("Ad Blocker Warning Dismissed", {
            Page: getPathForAnalytics(location.pathname),
        });
    };

    return (
        <div>
            <ScrollRestoration />
            <CookieConsentComponent />
            <Header showTopNavbar={showTopNavbar} position={showTopNavbar ? "fixed" : "relative"} />
            <main>
                {open && (
                    <div style={{ display: "none" }}>
                        {enqueueSnackbar(``, {
                            id: uniqueId(),
                            variant: "info",
                            persist: true,
                            preventDuplicate: true,
                            variantType: "filled",
                            customMsg: <AdBlockerWarning />,
                            customCloseToast: handleCloseAdBlocker,
                        })}
                    </div>
                )}

                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Routes>
                            <Route path="/" element={<Navigate to={LOGIN_PATH} />} />
                            <Route
                                path="/org/:orgName"
                                element={
                                    showSubscriptionExpiredPage ? (
                                        <SubscriptionExpired />
                                    ) : (
                                        <Navigate replace to={`/org/${orgFromPath}${DEFAULT_ORG_PATH}`} />
                                    )
                                }
                            />
                            <Route
                                path={`/org/:orgName${PERFORMANCE_PAID_PATH}`}
                                element={
                                    <Navigate replace to={`/org/${orgFromPath}${DEFAULT_ORG_PATH}${location.search}`} />
                                }
                            />
                            {superAdminRoutes.map((adminRoute) => (
                                <Route
                                    key={adminRoute.path}
                                    path={adminRoute.path}
                                    element={<SuperAdminRoute>{adminRoute.component}</SuperAdminRoute>}
                                />
                            ))}

                            {ProtectedOrgRoutes.map((protectedRoute) => (
                                <Route
                                    key={protectedRoute.path}
                                    path={protectedRoute.path}
                                    element={
                                        <ProtectedOrgRoute accessRoles={protectedRoute.accessRoles}>
                                            {protectedRoute.component}
                                        </ProtectedOrgRoute>
                                    }
                                />
                            ))}

                            <Route
                                path="/org/:orgName/campaigns"
                                element={
                                    <Navigate
                                        replace
                                        to={{
                                            pathname: `/org/${orgFromPath}${PERFORMANCE_CAMPAIGNS_PATH}`,
                                            search: location.search,
                                        }}
                                    />
                                }
                            />

                            <Route
                                path={`/org/:orgName${HOME_DASHBOARD_PATH}`}
                                element={<Navigate replace to={`/org/${orgFromPath}${DEFAULT_ORG_PATH}`} />}
                            />
                            <Route
                                path={`/org/:orgName/onboarding/*`}
                                element={<Navigate to={`/org/${orgFromPath}${DEFAULT_ORG_PATH}`} />}
                            />
                            <Route path={`/adm`} element={<Navigate to={SUPERADMIN_MEMBERS_PATH} />} />
                            <Route path={USER_SETTINGS_PATH} element={<Settings />} />

                            <Route
                                path={`/org/:orgName${PERFORMANCE_ECOMMERCE_PATH}`}
                                element={<Navigate replace to={`/org/${orgFromPath}${HOME_PATH}`} />}
                            />
                            <Route
                                path={`/org/:orgName${ECOMMERCE_PATH}`}
                                element={<Navigate replace to={`/org/${orgFromPath}${HOME_PATH}`} />}
                            />

                            <Route path={ACCESS_DENIED_PATH} element={<AccessDenied />} />
                            <Route path={ACCESS_REVOKED_PATH} element={<AccessRevoked />} />
                            <Route path={TRIAL_END_PATH} element={<TrialEnd />} />
                            <Route path="*" element={<NotFound />} />

                            <Route
                                path="/org/:orgName/connectors/*"
                                element={
                                    <Navigate
                                        to={{
                                            pathname: `/org/${orgFromPath}${CONNECTORS_PATH}`,
                                            search: location.search,
                                        }}
                                    />
                                }
                            />
                            <Route
                                path="/org/:orgName/members/*"
                                element={<Navigate replace to={`/org/${orgFromPath}${MEMBER_PATH}`} />}
                            />
                            <Route
                                path="/org/:orgName/billing/*"
                                element={<Navigate replace to={`/org/${orgFromPath}${BILLING_PATH}`} />}
                            />
                        </Routes>
                    </div>
                </div>
            </main>
        </div>
    );
};
