import { FC } from "react";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import {
    ICard,
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
} from "src/interfaces/entities/IScenario";
import { recommendationsSentence } from "src/services/optimizationPage/optimization";
import { OutcomeCampaignTable } from "../OutcomeTable/OutcomeCampaignTable";
import { SCENARIO_BASIS } from "src/consts/optimizationPage/optimizationPage";
import { IDictionary } from "src/interfaces/IDictionary";

interface ICampaignsRecommendationsTab {
    cards: ICard[];
    selectedScenario: IScenario;
    forecastCampaign: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    allForecastedTotalAllocation: IDictionary;
    isTableLoading: boolean;
    increaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    decreaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    compareToLabel: string;
}

export const CampaignsRecommendationsTab: FC<ICampaignsRecommendationsTab> = ({
    cards,
    selectedScenario,
    forecastCampaign,
    allForecastedTotalAllocation,
    isTableLoading,
    increaseSpendCampaigns,
    decreaseSpendCampaigns,
    compareToLabel,
}) => {
    const isForecastScenario = selectedScenario.scenarioType === SCENARIO_BASIS.FORECAST;

    return (
        <Stack gap="20px">
            {isForecastScenario ? (
                <Stack gap="20px">
                    <Typography color="#000" variant="h6" fontWeight="bold" fontSize="18px">
                        {recommendationsSentence(selectedScenario)} spend on the following campaign(s)
                    </Typography>
                    <OutcomeCampaignTable
                        cards={cards || []}
                        campaignData={forecastCampaign}
                        scenario={selectedScenario}
                        allForecastedTotalAllocation={allForecastedTotalAllocation}
                        isTableLoading={isTableLoading}
                        compareToLabel={compareToLabel}
                    />
                </Stack>
            ) : (
                <>
                    {!!increaseSpendCampaigns.length && (
                        <Stack gap="20px">
                            <Typography color="#000" variant="h6" fontWeight="bold" fontSize="18px">
                                Increase spend on the following campaign(s)
                            </Typography>
                            <OutcomeCampaignTable
                                cards={cards || []}
                                campaignData={increaseSpendCampaigns}
                                scenario={selectedScenario}
                                allForecastedTotalAllocation={allForecastedTotalAllocation}
                                isTableLoading={isTableLoading}
                                compareToLabel={compareToLabel}
                            />
                        </Stack>
                    )}
                    {!!decreaseSpendCampaigns.length && (
                        <Stack gap="20px">
                            <Typography color="#000" variant="h6" fontWeight="bold" fontSize="18px">
                                Decrease spend on the following campaign(s)
                            </Typography>
                            <OutcomeCampaignTable
                                cards={cards || []}
                                campaignData={decreaseSpendCampaigns}
                                scenario={selectedScenario}
                                allForecastedTotalAllocation={allForecastedTotalAllocation}
                                isTableLoading={isTableLoading}
                                compareToLabel={compareToLabel}
                            />
                        </Stack>
                    )}
                </>
            )}
        </Stack>
    );
};
