import React from "react";
import { Chip, Stack, Tooltip, useTheme } from "@mui/material";

import { useStylesForChips } from "../../../consts/StylesForTables";
import { isTrendPostive } from "../../../services/performancePage/performancePage";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

export interface IPercentageTrendChipProps {
    value: number;
    isPositiveTrendGood: boolean;
    isSpecialChip?: boolean;
    pastRangeString?: string;
}

export const PercentageTrendChip = ({
    value,
    isPositiveTrendGood,
    isSpecialChip = false,
    pastRangeString,
}: IPercentageTrendChipProps) => {
    const isNegative = value < 0;
    const theme = useTheme();
    const chipClasses = useStylesForChips(theme);
    const showNegativeTrend = !isTrendPostive(value, isPositiveTrendGood);
    const classColor = showNegativeTrend ? "error" : "success";

    return (
        <Chip
            component="div"
            size="small"
            className={`${chipClasses.chipStyle} ${
                showNegativeTrend ? chipClasses.negativeChip : chipClasses.positiveChip
            } ${isSpecialChip ? chipClasses.iconChip : ""}`}
            sx={{ cursor: "inherit" }}
            label={
                <Stack component="span" sx={{ flexDirection: "row", alignItems: "center" }}>
                    {isSpecialChip ? (
                        <>
                            <Tooltip
                                title={`${value > 0 ? "+" : ""}${value.toFixed(2)}% ${pastRangeString}`}
                                classes={{ tooltip: chipClasses.tooltip }}
                                arrow
                            >
                                <span>
                                    {isNegative ? (
                                        <SVGIconRenderer
                                            icon="chevronDownIcon"
                                            height="20px"
                                            width="20px"
                                            strokeColor={classColor}
                                        />
                                    ) : (
                                        <SVGIconRenderer
                                            icon="chevronUpIcon"
                                            height="20px"
                                            width="20px"
                                            strokeColor={classColor}
                                        />
                                    )}
                                </span>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            {isNegative ? (
                                <SVGIconRenderer
                                    icon="chevronDownIcon"
                                    height="20px"
                                    width="20px"
                                    strokeColor={classColor}
                                />
                            ) : (
                                <SVGIconRenderer
                                    icon="chevronUpIcon"
                                    height="20px"
                                    width="20px"
                                    strokeColor={classColor}
                                />
                            )}
                            {`${value.toFixed(2)}%`}
                        </>
                    )}
                </Stack>
            }
        />
    );
};
