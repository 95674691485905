import { FC } from "react";
import ReactEcharts from "echarts-for-react";
import { Stack } from "@mui/system";
import { Paper } from "@mui/material";

import { customSortData, formatValue, getPreparedValue } from "src/services/utils";
import { FORMATS } from "src/enums/Formats";
import { IMetricAttributionTableValuesTransformedWithSpendAllocation } from "src/interfaces/entities/IScenario";
import { calculatePercentage } from "src/services/performancePage/performancePage";
import { PredictedLiftTabs } from "./PerdictedLiftTab";
import { NO_CHANGE_FORECAST_HEADING, PREDICTED_LIFT_PER_HEADING } from "src/services/optimizationPage/optimization";
import { PRIMARY_MAIN_COLOR } from "src/consts/colors";

export type ForecastChartData = Array<{
    reportedRevenue: number;
    backtestDate: string;
    forecastedRevenue: number;
    forecastedDate: string;
    accuracy: number;
}>;

interface IPredictedLiftChart {
    isLoading: boolean;
    tableBody: Array<{
        actualPast: number;
        predictedFuture: number;
        perChange: number;
        predictedLiftDollar: number;
        predictedLiftPer: number;
        decomposition: PredictedLiftTabs;
    }>;
}

const colors = {
    noChange: "#91cc75",
    predictedLift: "#4264e0",
};

export const PredictedLiftChart: FC<IPredictedLiftChart> = ({ isLoading, tableBody }) => {
    const optimizationPredictedLiftRevenue =
        tableBody.find((l) => l.decomposition === PredictedLiftTabs.OptimizationPredictedLift)?.predictedFuture || -1;

    const isOptimizationRevenueNegative = optimizationPredictedLiftRevenue < 0;
    const increasedRevenueCampaigns = isOptimizationRevenueNegative ? 0 : optimizationPredictedLiftRevenue;

    const sortedLegends = customSortData(tableBody, "decomposition", [
        PredictedLiftTabs.Holidays,
        PredictedLiftTabs.Trend,
        PredictedLiftTabs.Media,
        PredictedLiftTabs.OptimizationPredictedLift,
        PredictedLiftTabs.EcommerceForecast,
    ]).map((value) => value.decomposition);
    const totalNoChangeForecast =
        tableBody.find((l) => l.decomposition === PredictedLiftTabs.EcommerceForecast)?.actualPast || 0;

    const rawData: Array<Array<[string, number]>> = [
        tableBody.map((t) => [
            t.decomposition,
            t.decomposition === PredictedLiftTabs.OptimizationPredictedLift
                ? increasedRevenueCampaigns
                : t.decomposition === PredictedLiftTabs.EcommerceForecast
                ? t.actualPast + increasedRevenueCampaigns
                : t.actualPast,
        ]),
    ];

    const noChangeForecastData = rawData[0].map((dataItem) => {
        const percentage = calculatePercentage(dataItem[1], totalNoChangeForecast); // Calculate percentage
        return [dataItem[0], percentage, dataItem[1]]; // Update data item with percentage value
    });

    const getDataForPlaceholder = (data: Array<Array<string | number>>, isLineChart: boolean) => {
        let sum = 0;
        const result: Array<Array<string | number>> = [];
        data.forEach((d, index) => {
            if (index === 0) {
                sum = 0;
                result.push([d[0], 0]);
            } else if (!isLineChart && data.length - 1 === index) {
                result.push([d[0], 0]);
            } else {
                sum = sum + +data[index - 1][1];
                result.push([d[0], sum]);
            }
        });
        return result;
    };

    const placeHolderData = getDataForPlaceholder(noChangeForecastData, false);
    const lineData = getDataForPlaceholder(noChangeForecastData, true);

    const series = [
        {
            type: "bar",
            stack: "total",
            data: placeHolderData,
            barWidth: "60%",
            itemStyle: {
                borderColor: "transparent",
                color: "transparent",
            },
        },
        {
            name: NO_CHANGE_FORECAST_HEADING,
            type: "bar",
            stack: "total",
            barWidth: "60%",
            label: {
                show: true,
                formatter: (params: any) => {
                    return params.value[2] ? getPreparedValue(FORMATS.DOLLAR, params.value[2], 0) : "";
                },
                fontSize: "14px",
            },
            data: noChangeForecastData.map((d) => {
                if (d[0] === PredictedLiftTabs.OptimizationPredictedLift) {
                    return {
                        value: d,
                        itemStyle: {
                            color: colors.noChange,
                        },
                    };
                } else {
                    return {
                        value: d,
                        itemStyle: {
                            color: colors.predictedLift,
                        },
                    };
                }
            }),
        },
        {
            name: NO_CHANGE_FORECAST_HEADING,
            type: "line",
            step: "start",
            symbol: "none",
            label: {
                show: true,
                formatter: (params: any) => {
                    return params.value[2] ? getPreparedValue(FORMATS.DOLLAR, params.value[2], 0) : "";
                },
                fontSize: "14px",
            },
            data: lineData,
            z: -1,
        },
    ];

    const options = {
        tooltip: {
            trigger: "axis",
            formatter: (params: any) => {
                const barSeries = params.filter(
                    (param: any) => param.seriesType === "bar" && param.seriesName === NO_CHANGE_FORECAST_HEADING,
                );
                return barSeries
                    .map((params: any) => {
                        return `
                            <li style="list-style:none;display:flex;justify-content:space-between;margin-bottom:2px;align-items:center;">
                                        <div style="margin-top:2px">${params.marker}${params.axisValueLabel}</div>
                                        <div style="margin-left:20px;"><b>${formatValue(
                                            params.value[1],
                                            FORMATS.PERCENT,
                                            2,
                                        )} (${formatValue(params.value[2], FORMATS.DOLLAR, 0)})</b></div>
                                        </li>`;
                    })
                    .join("");
            },
        },
        grid: {
            top: 40,
            bottom: 60,
            left: 30,
            right: 50,
            containLabel: true,
        },
        yAxis: {
            type: "value",
            show: "true",
            splitLine: {
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.23)",
                },
            },
            axisLabel: {
                formatter: "{value}%",
            },
        },
        xAxis: {
            type: "category",
            data: sortedLegends,
            axisTick: {
                show: false,
            },
            axisLabel: {
                fontSize: 14,
            },
        },
        series,
    };

    const legendArr = [
        { label: NO_CHANGE_FORECAST_HEADING, color: colors.predictedLift },
        { label: PREDICTED_LIFT_PER_HEADING, color: colors.noChange, isHidden: isOptimizationRevenueNegative },
    ];

    return (
        <>
            <ReactEcharts
                style={{ height: "100%" }}
                showLoading={isLoading}
                option={options}
                notMerge={true}
                loadingOption={{ text: "Fetching Data...", color: PRIMARY_MAIN_COLOR }}
            />
            <Stack direction="row" gap="15px" justifyContent="center" position="relative" top="-30px">
                {legendArr.map((c) => {
                    return (
                        !c.isHidden && (
                            <Stack key={c.label} direction="row" gap="10px" alignItems="center">
                                <Paper sx={{ backgroundColor: c.color, height: "15px", width: "30px" }}></Paper>
                                {c.label}
                            </Stack>
                        )
                    );
                })}
            </Stack>
        </>
    );
};
