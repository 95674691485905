import { Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { format } from "date-fns";
import { toDate } from "date-fns-tz";
import { capitalize } from "lodash";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { IDictionary } from "src/interfaces/IDictionary";
import { IQaMetric } from "src/interfaces/entities/IQaMetric";
import { useGetQaMetricTypesQuery } from "src/reduxState/apis/qaMetricsApi";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { MetricsList } from "./sharedComponent/MetricList";
import { VerificationHeader } from "./sharedComponent/VerificationHeader";

export const VerifyDataAccuracyStep1 = ({
    meta,
    metricsWithConnector,
    setMetricsWithConnector,
}: {
    meta: any;
    metricsWithConnector: IQaMetric[];
    setMetricsWithConnector: React.Dispatch<React.SetStateAction<IQaMetric[]>>;
}) => {
    const [userReportedData, setUserReportedData] = useState<{ id: string; value: string }>({ id: "", value: "" });
    const [currentCheckboxAnchorEl, setCurrentCheckboxAnchorEl] = useState<HTMLElement | null>(null);
    const orgId = useSelector(selectCurrentlyViewingId);
    const metricTypes = useGetQaMetricTypesQuery({ orgId }).data?.map((item) => item.name) || [];

    const onCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        metric: IQaMetric,
        selectedValue: boolean | null,
    ) => {
        if (selectedValue === false) {
            setCurrentCheckboxAnchorEl(e.currentTarget);
        }
        setMetricsWithConnector((prev) =>
            prev.map((item) =>
                item.id === metric.id
                    ? {
                          ...item,
                          isAccepted: item.isAccepted === selectedValue ? null : selectedValue,
                          isModalOpen: selectedValue === false,
                          userReportedValue: 0,
                      }
                    : { ...item, isModalOpen: false },
            ),
        );
    };

    const onDeclineReasonClose = (metric: IQaMetric) => {
        setCurrentCheckboxAnchorEl(null);
        setUserReportedData({ id: "", value: "" });
        setMetricsWithConnector((prev) =>
            prev.map((item) => (item.connectorId === metric.connectorId ? { ...item, isModalOpen: false } : item)),
        );
    };

    const onDeclineReasonSave = (metricData: IQaMetric) => {
        setMetricsWithConnector((prev) =>
            prev.map((item) =>
                item.id === userReportedData.id
                    ? { ...item, userReportedValue: +userReportedData.value, isModalOpen: false }
                    : item,
            ),
        );
        setUserReportedData({ id: "", value: "" });
    };

    const onExpectedValueChange = (value: string, id: string) => {
        setUserReportedData({ id, value });
    };

    const categorizedMetrics = useMemo(() => {
        const metrics: IDictionary<IQaMetric[]> = metricsWithConnector.length
            ? metricsWithConnector.reduce((metrics, item) => {
                  (metrics[item.metricType] = metrics[item.metricType] || []).push(item);
                  return metrics;
              }, {} as IDictionary<IQaMetric[]>)
            : {};

        Object.keys(metrics).forEach((metricType) => {
            metrics[metricType].sort((a, b) => {
                const nameA = a.connector?.connectorName?.toLowerCase() || "";
                const nameB = b.connector?.connectorName?.toLowerCase() || "";
                return nameA.localeCompare(nameB);
            });
        });

        return metrics;
    }, [metricsWithConnector]);

    return (
        <Stack gap={2}>
            <VerificationHeader
                mainText="To proceed, please confirm the accuracy of the data provided. The model will be able to run once this verification is complete. If you notice any discrepancies, please provide the expected numbers and we will work with you to resolve these. Small discrepancies in data, up to 10%, are acceptable."
                subText={
                    <>
                        <Typography component="span">Confirm the following metrics for the</Typography>
                        <Typography fontWeight={700} component="span">
                            {meta.startDate && meta.endDate
                                ? ` ${format(toDate(meta.startDate), "MMMM d")} - ${format(
                                      toDate(meta.endDate),
                                      "MMMM d, yyyy",
                                  )} `
                                : ` -- `}
                        </Typography>
                        <Typography component="span">period:</Typography>
                    </>
                }
            />
            {metricTypes.map((metricType) => (
                <Stack key={metricType}>
                    <Stack gap={1}>
                        <Typography variant="h6">{capitalize(metricType)}</Typography>
                        <Divider variant="fullWidth" sx={{ height: "2px", width: "100%" }} />
                    </Stack>
                    <MetricsList
                        metricsWithConnector={categorizedMetrics[metricType]}
                        userReportedData={userReportedData}
                        currentCheckboxAnchorEl={currentCheckboxAnchorEl}
                        isDataSubmitted={false}
                        onCheckboxChange={onCheckboxChange}
                        onExpectedValueChange={onExpectedValueChange}
                        onDeclineReasonSave={onDeclineReasonSave}
                        onDeclineReasonClose={onDeclineReasonClose}
                    />
                </Stack>
            ))}
        </Stack>
    );
};
