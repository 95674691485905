import { PERFORMANCE_ATTRIBUTION_HEADERS } from "../performancePaidPage/performancePaidPage";
import { FORMATS } from "../../enums/Formats";
import { IDictionary } from "src/interfaces/IDictionary";

export enum INCLUDED_TYPE {
    USERS = "users",
    JOBS = "jobs",
    COMPANIES = "companies",
}

export enum OptimizationTab {
    Scenarios = "scenarios",
    Recommended = "recommended",
    Archived = "archived",
}

export type Choice = "asc" | "desc";

export interface ICells {
    name: string;
    createdName: string;
    scenarioType: SCENARIO_BASIS;
    budget: string;
    scenarioLevel: string;
    forecastTimeframe: string;
    isScenarioEditable: string;
}

interface IHeadCell {
    id: keyof ICells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
}

export const OPTIMIZATION_PAGE_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Scenario",
    },
    {
        id: "createdName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
    },
    {
        id: "scenarioType",
        numeric: false,
        disablePadding: false,
        label: "Scenario Basis",
    },
    {
        id: "budget",
        numeric: false,
        disablePadding: false,
        label: "Budget",
    },
    {
        id: "scenarioLevel",
        numeric: false,
        disablePadding: false,
        label: "Scenario Level",
    },
    {
        id: "forecastTimeframe",
        numeric: false,
        disablePadding: false,
        label: "Timeframe",
    },
    {
        id: "isScenarioEditable",
        numeric: false,
        disablePadding: false,
        label: "Scenario Performance",
    },
];

export interface IForecastCampaignCells {
    campaignName: string;
    campaignId: string;
    confidenceScore: string;
    spend: string;
    trueRevenue: string;
    trueRoas: string;
    allocationSpend: string;
    forecastedRevenue: string;
    forecastedRoas: string;
}

export interface IForecastCampaignHeadCell {
    id: keyof IForecastCampaignCells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
    hidden: boolean;
    fixed?: number;
    width?: string;
    sign?: FORMATS;
    exportFixed?: number;
    category?: string;
    align: "inherit" | "left" | "center" | "right" | "justify" | undefined;
    className?: string;
    groupName?: string;
}

export const FORECASTED_CAMPAIGNS_HEADERS: readonly IForecastCampaignHeadCell[] = [
    {
        id: "campaignName",
        numeric: false,
        disablePadding: false,
        label: "Campaigns",
        hidden: false,
        width: "350px",
        align: "left",
        className: "campaignNameCell",
        groupName: "other",
    },
    {
        id: "confidenceScore",
        numeric: false,
        disablePadding: false,
        label: "Confidence",
        hidden: false,
        align: "right",
        className: "confidenceCell",
        groupName: "other",
    },
    {
        id: "spend",
        numeric: true,
        disablePadding: false,
        label: "Spend",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        align: "right",
        className: "spendCell",
        groupName: "expected",
    },
    {
        id: "trueRevenue",
        numeric: true,
        disablePadding: false,
        label: "Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        align: "right",
        className: "spendCell",
        groupName: "expected",
    },
    {
        id: "trueRoas",
        numeric: true,
        disablePadding: false,
        label: "ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
        width: "8%",
        align: "right",
        className: "spendCell",
        groupName: "expected",
    },
    {
        id: "allocationSpend",
        numeric: true,
        disablePadding: false,
        label: "Spend",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        width: "9%",
        align: "right",
        className: "spendCell",
        groupName: "optimal",
    },
    {
        id: "forecastedRevenue",
        numeric: true,
        disablePadding: false,
        label: "Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        width: "9%",
        align: "right",
        className: "forecastedRevenueCell",
        groupName: "optimal",
    },
    {
        id: "forecastedRoas",
        numeric: true,
        disablePadding: false,
        label: "ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
        width: "8%",
        align: "right",
        className: "forecastedRoasCell",
        groupName: "optimal",
    },
];

export const EXPORT_FORECASTED_CAMPAIGNS_HEADERS = [
    ...FORECASTED_CAMPAIGNS_HEADERS,
    {
        id: "totalSpendDiff",
        label: "Spend Change",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
    },
    {
        id: "totalRevenueDiff",
        label: "Revenue Change",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
    },
    {
        id: "totalRoasDiff",
        label: "ROAS Change",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
    },
];

interface ITrackingCell {
    id: string;
    numeric: boolean;
    label: string;
    sign?: FORMATS;
    fixed?: number;
    exportFixed?: number;
    width?: string;
    tabLabel?: IDictionary;
}

export const FORECASTED_CAMPAIGNS_TRACKING_HEADERS: ITrackingCell[] = [
    {
        id: "campaignName",
        numeric: false,
        label: "Campaigns",
        width: "350px",
    },
    {
        id: "forecasted",
        numeric: true,
        label: "Forecasted",
        tabLabel: {
            Budget: "Recommended",
        },
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
        width: "9%",
    },
    {
        id: "actual",
        numeric: true,
        label: "Actual",
        sign: FORMATS.NUMERIC,
        fixed: 2,
        exportFixed: 2,
        width: "8%",
    },
    {
        id: "change",
        numeric: true,
        label: "Pacing",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
    },
    {
        id: "potentialFullfilled",
        numeric: true,
        label: "Potential Fullfilled",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 2,
    },
];

export const RECOMMANDATION_TABLE_HEADERS = [
    {
        id: "channelName",
        numeric: false,
        disablePadding: false,
        label: "Channels/Campaigns",
        sign: undefined,
        fixed: 0,
    },
    {
        id: "allocatedDollar",
        numeric: true,
        disablePadding: false,
        label: "Allocated $",
        sign: FORMATS.DOLLAR,
        fixed: 0,
    },
    {
        id: "allocatedPercentage",
        numeric: true,
        disablePadding: false,
        label: "Allocated %",
        sign: FORMATS.PERCENT,
        fixed: 0,
    },
    // {
    //     id: "min",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Min %",
    //     sign: FORMATS.PERCENT,
    //     fixed: 0,
    // },
    // {
    //     id: "max",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Max %",
    //     sign: FORMATS.PERCENT,
    //     fixed: 0,
    // },
];

export interface IForecastTabSeries {
    revenue: any[];
    media: any[];
    holidays: any[];
    trend: any[];
}

export enum TIMEFRAME_OPTIONS {
    DAILY = 1,
    LAST7DAYS = 7,
    LAST14DAYS = 14,
    LAST28DAYS = 28,
}

export enum TIMEFRAME_OPTIONS_LABEL {
    LAST7DAYS = "7 Days",
    LAST14DAYS = "14 Days",
    LAST28DAYS = "28 Days",
}

export const TIMEFRAME_RADIO_OPTIONS = [
    { label: TIMEFRAME_OPTIONS_LABEL.LAST7DAYS, value: TIMEFRAME_OPTIONS.LAST7DAYS },
    { label: TIMEFRAME_OPTIONS_LABEL.LAST14DAYS, value: TIMEFRAME_OPTIONS.LAST14DAYS },
    { label: TIMEFRAME_OPTIONS_LABEL.LAST28DAYS, value: TIMEFRAME_OPTIONS.LAST28DAYS },
];

export enum VISIBILITY {
    private = "Private",
    public = "Public",
}

export enum SCENARIO_BASIS {
    SCALE = "scale", // default
    SHIFT = "shift", // not in use
    GOAL = "goal", // not in use
    FORECAST = "forecast", // when forecasted save
}

export interface IMetricData {
    individualData: Array<{
        campaignId: string;
        campaignName: string;
        connectorName?: string;
        channel: string;
        forecasted: number;
        actual: number;
        change: number;
        perChange: number;
        potentialFulfilled: number;
    }>;
    total: { forecasted: number; actual: number; potentialFullfilled: number; pacing: boolean; unit?: FORMATS };
}

export interface IMetricDataObj {
    spend: IMetricData;
    revenue: IMetricData;
    roas: IMetricData;
}

export const SCENARIO_TRACKING_TABS: Array<{ value: "spend" | "revenue" | "roas"; label: string }> = [
    { value: "revenue", label: "Revenue" },
    { value: "roas", label: "ROAS" },
    { value: "spend", label: "Budget" },
];

export const CHANNEL_TABLE_HEADERS = [
    {
        id: "channelName",
        numeric: false,
        disablePadding: false,
        label: "Channels/Campaigns",
        sign: undefined,
        fixed: 0,
    },
    {
        id: "lock",
        numeric: true,
        disablePadding: false,
        label: "",
    },
    {
        id: "currentEstimatedDailySpend",
        numeric: true,
        disablePadding: false,
        label: "No Change Daily Spend",
        sign: FORMATS.DOLLAR,
        fixed: 0,
    },
    {
        id: "currentEstimatedSpend",
        numeric: true,
        disablePadding: false,
        label: "No Change {7 day} Spend",
        sign: FORMATS.DOLLAR,
        fixed: 0,
    },
    ...PERFORMANCE_ATTRIBUTION_HEADERS.filter((h) => ["channelName", "trueRevenue", "trueRoas"].includes(h.id)),
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        hidden: true,
    },
];
