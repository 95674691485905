import { css } from "@emotion/css";
import { Button, Skeleton, Tab, TabScrollButton, Tabs, Tooltip, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/system";
import * as echarts from "echarts";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { CardLoader } from "src/components/Dashboard/CardComponents/CardLoader";
import { PercentageTrendChip } from "src/components/core/Chips/PercentageTrendChip";
import { DataValueView, KeyKPIPerformanceTabTypes } from "src/consts/homeDashboard/homeDashboard";
import { NEGATIVE_TREND_GOOD, chartGrpKey } from "src/consts/performancePaidPage/performancePaidPage";
import { FORMATS } from "src/enums/Formats";
import { ChartMode, ChartModes, chartModeSelector } from "src/reduxState/slices/chartSlice";
import { dataQaReady } from "src/reduxState/slices/organizationSlice";
import { store } from "src/reduxState/stores/store";
import { BetaTooltip } from "../../../components/ConnectorsComponent/NewConnectorComponents/BetaTooltip";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { IDictionary } from "../../../interfaces/IDictionary";
import { IChartItem } from "../../../interfaces/chart/IChartItem";
import {
    betaSupportedDataSourcesSelector,
    selectDataSourceColor,
} from "../../../reduxState/slices/supportedDataSourcesSlice";
import { getRevenueLegend } from "../../../services/homeDashboard/homeDashboard";
import {
    calculatePercentage,
    formatType,
    formatValue,
    generateValue,
    getTooltipDateFormat,
    toUnderlineCase,
} from "../../../services/utils";

export type INullableChartItem = Omit<IChartItem, "value" | "percentage"> & {
    value: number | null;
    percentage?: number | null | undefined;
};

interface IChartComponent {
    data: INullableChartItem[];
    isFetching?: boolean;
    grpBy: chartGrpKey;
    id: string;
    ecommerceState: KeyKPIPerformanceTabTypes;
    setSelectedMetricSeries?: (data: any) => void;
    isNewHomePage?: boolean;
    tabs?: any[];
    legendTotal?: any[];
    compareMode?: boolean;
    pastDateString?: string;
    isMMMTab?: boolean;
    dataValueView: DataValueView;
}

const useStyles = () => ({
    legendStyle: css({
        fontSize: "15px",
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: "0.4000000059604645px",
        textTransform: "capitalize",
        minWidth: "135px",
    }),
});

const isPastData = (data: { channelName: string }) => {
    return data.channelName.includes("[Past]");
};

export const ChartComponent: React.FC<IChartComponent> = ({
    data,
    isFetching,
    grpBy = chartGrpKey.DAY,
    id,
    setSelectedMetricSeries,
    ecommerceState,
    isNewHomePage = false,
    tabs = [],
    legendTotal,
    compareMode = false,
    pastDateString = "",
    dataValueView,
}) => {
    const theme = useTheme();
    if (isFetching) {
        if (isNewHomePage) {
            return <CardLoader height="570px" />;
        }
        return <Skeleton variant="rectangular" height={330} animation="wave" />;
    }
    const isPercentageViewSelected = useMemo(() => dataValueView === DataValueView.Percentage, [dataValueView]);
    const chartMode: ChartMode = useSelector(chartModeSelector);

    const isStacked = chartMode === ChartModes.STACKED;

    const getChartColor = useCallback((seriesName: string = "") => {
        const name = toUnderlineCase(seriesName.replace(" [Past]", ""))?.replace("-transactional", "");
        return selectDataSourceColor(store.getState(), name);
    }, []);

    const selectedMetric = tabs.filter((t) => t.id === ecommerceState).map((t) => t)[0];
    const sign = isPercentageViewSelected ? FORMATS.PERCENT : selectedMetric?.sign || FORMATS.NUMERIC;
    const precision = isPercentageViewSelected ? 2 : selectedMetric?.precision || 0;

    const betaSupportedConnectors = useSelector(betaSupportedDataSourcesSelector);
    const classes = useStyles();

    const isDataQaReady = useSelector(dataQaReady);

    const generateDateRange = (): string[] => {
        const allDates = data.map((dataPoint) => dataPoint.date);

        const uniqueDates = [...new Set(allDates)];

        const formattedDates = uniqueDates.map((dateStr) => {
            const [year, month, day] = dateStr.split("-").map(Number);
            return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
        });

        const sortedDates = formattedDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        return sortedDates;
    };

    const fillGaps = (channelData: INullableChartItem[]) => {
        const dates = generateDateRange();
        const newData: INullableChartItem[] = [];
        dates.forEach((date) => {
            const exists = channelData.some((item) => item.date === date);

            if (!exists) {
                newData.push({
                    ...channelData[0],
                    value: null,
                    percentage: null,
                    date,
                });
            } else {
                newData.push(...channelData.filter((item) => item.date === date));
            }
        });
        return newData;
    };

    const getValues = (values: INullableChartItem[], legend: string[]) => {
        const minDate = values[0]?.date;
        const maxDate = values[values.length - 1]?.date;
        return legend.map((i: string) => {
            const findSeries = fillGaps(values.filter((t) => t.channelName === i));
            const isPastSeries = i.includes("[Past]");
            const color = getChartColor(findSeries[0].seriesName || findSeries[0].name);
            return {
                name: i,
                data: findSeries.map((t) => [t.date, isPercentageViewSelected ? t.percentage : t.value]),
                connectNulls: false,
                type: "line",
                ...(isStacked && { stack: "Total" }),
                triggerLineEvent: true,
                lineStyle: {
                    type: isPastSeries ? "dashed" : "solid",
                    color,
                },
                areaStyle: {
                    opacity: isStacked ? 0.7 : 0,
                },
                emphasis: {
                    focus: "series",
                },
                blur: isStacked
                    ? {
                          lineStyle: {
                              opacity: 0.2,
                          },
                          areaStyle: {
                              opacity: 0.2,
                          },
                      }
                    : null,
                itemStyle: {
                    color,
                },
                showSymbol: false,
                useUTC: true,
                channelName: i,
                seriesName: findSeries[0].seriesName,
            };
        });
    };
    const copyOfValues = [...data];
    const sortedValuesByDate = copyOfValues.sort((a, b) => +new Date(a.date) - +new Date(b.date));

    let legendKeyObj: IDictionary = {};
    sortedValuesByDate.map((i) => {
        legendKeyObj = { ...legendKeyObj, [i.channelName]: i.name || "" };
    });

    // get unique values using new Set
    const uniqueDataSets = [...new Set(sortedValuesByDate.map((i): string => i.channelName || ""))];

    const isEcommerceChart = useMemo(() => uniqueDataSets.includes("Transactional"), [uniqueDataSets]);

    const legendData = isEcommerceChart
        ? [...uniqueDataSets.filter((l) => l !== "Transactional"), "Transactional"]
        : uniqueDataSets;

    let seriesData = getValues(
        sortedValuesByDate,
        uniqueDataSets.filter((d) => d),
    );

    if (isNewHomePage && seriesData.length > 1) {
        seriesData = [...getValues(sortedValuesByDate, uniqueDataSets)];
    }

    let chartElement: any = null;
    let updatedData = [...seriesData];

    const lastPastDate = () => {
        const pastData = data.filter((d) => isPastData(d));
        return new Date(pastData[pastData.length - 1]?.date).getTime();
    };

    const firstCurrentDate = () => {
        const currentData = data.filter((d) => !isPastData(d));
        return new Date(currentData[0]?.date).getTime();
    };

    const shouldBeVisible = (data: { date: string; channelName: string }) => {
        const date = new Date(data.date).getTime();
        const isPast = isPastData(data);
        return isPast ? date <= lastPastDate() : date >= firstCurrentDate();
    };

    const options = {
        title: {
            show: data.length === 0,
            textStyle: {
                color: "#bcbcbc",
            },
            text: "No data found",
            left: "center",
            top: "center",
        },
        maintainAspectRatio: false,
        legend: {
            show: false,
        },
        tooltip: {
            trigger: "axis",
            confine: true,
            axisPointer: {
                label: {
                    show: false,
                },
            },
            formatter: (params: any) => {
                const chartDate = getTooltipDateFormat(params[0] || "", grpBy);
                const tooltipData = params
                    .map((param: any) => {
                        return shouldBeVisible({ date: param.data[0], channelName: param.seriesName })
                            ? '<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block">' +
                                  param.marker +
                                  param.seriesName +
                                  "</span><span style='margin-left:20px'><b>" +
                                  formatValue(param.value[1], sign, isNewHomePage ? precision : 2) +
                                  "<span></b></li>"
                            : "";
                    })
                    .join("");
                return chartDate + tooltipData;
            },
        },
        grid: {
            top: 8,
            bottom: 40,
            left: 0,
            right: 50,
        },
        xAxis: {
            type: "time",
            axisTick: {
                show: false,
            },
            boundaryGap: false,
            axisLabel: {
                color: "rgba(0, 0, 0, 0.6)",
                hideOverlap: true,
                formatter: grpBy && grpBy !== chartGrpKey.DAY && formatType[grpBy] ? formatType[grpBy] : undefined,
            },
            axisLine: {
                lineStyle: {
                    color: "rgba(0, 0, 0, 0.23)",
                    width: 1,
                },
            },
        },
        yAxis: [
            {
                type: "value",
                position: "right",
                show: "true",
                axisLabel: {
                    formatter(value: number) {
                        return isFetching
                            ? ""
                            : value === 0
                            ? 0
                            : `${sign !== "%" ? `${sign}${generateValue(value)}` : `${generateValue(value)}${sign}`}`;
                    },
                    inside: "true",
                    verticalAlign: "top",
                    showMinLabel: false,
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(0, 0, 0, 0.23)",
                    },
                },
                offset: sign === "$" ? 50 : 38,
            },
        ],
        series: seriesData,
    };

    const btnUpdate = (btn: HTMLElement, i: number) => {
        updatedData = updatedData.map((data) => {
            if (data.channelName === btn.id.split("_button_")[0]) {
                return getUpdateSeriesData(
                    btn,
                    data,
                    seriesData.find((d) => d.channelName === data.channelName),
                );
            }
            return data;
        });
        if (setSelectedMetricSeries) {
            let legendData = {};
            updatedData.forEach((d) => {
                if (!isPastData(d)) {
                    legendData = { ...legendData, [d.name]: !!d.data.length };
                }
            });
            setSelectedMetricSeries(legendData);
        }
        chartElement.setOption({
            ...options,
            series: updatedData,
        });
    };

    const chartResize = () => {
        chartElement.resize();
    };

    const initChart = () => {
        if (document.getElementById(id)) {
            chartElement = echarts.init(document.getElementById(id)!, "prescient");
            if (chartElement) {
                chartElement.setOption(options, true);
                chartElement.on("rendered", () => {
                    seriesData.forEach((d, i) => {
                        const btn = document.getElementById(d.channelName + "_button_" + id);
                        if (btn) {
                            btn.onclick = () => {
                                btnUpdate(btn, i);
                            };
                            btn.onmouseenter = () => {
                                chartElement.dispatchAction({
                                    type: "highlight",
                                    seriesIndex: i,
                                });
                            };
                            btn.onmouseleave = () => {
                                chartElement.dispatchAction({
                                    type: "downplay",
                                    seriesIndex: i,
                                });
                            };
                        }
                    });
                });
                window.addEventListener("resize", chartResize);
            }
        }
    };

    const getUpdateSeriesData = (btn: any, data: any, originalData: any) => {
        const symbolBtn = document.getElementById(data.channelName + "_symbol_" + id);
        const newData = data.data.length ? [] : originalData.data;
        if (symbolBtn) {
            symbolBtn.style.borderTopColor = newData.length
                ? getChartColor(data.seriesName || data.name)
                : "rgba(0, 0, 0, 0.3)";
        }
        if (btn) {
            btn.style.color = newData.length ? "" : "rgba(0, 0, 0, 0.4)";
        }
        return { ...data, data: newData };
    };

    useEffect(() => {
        initChart();
        return () => {
            // reset legend
            options.series.forEach((d) => {
                const btn = document.getElementById(d.channelName + "_button_" + id);
                if (btn) {
                    const symbolBtn = document.getElementById(d.channelName + "_symbol_" + id);
                    if (symbolBtn) {
                        symbolBtn.style.borderTopColor = getChartColor(d.seriesName || d.name);
                    }
                }
            });
            window.removeEventListener("resize", chartResize);
        };
    }, [JSON.stringify(data), dataValueView]);

    useEffect(() => {
        // remove all legend listeners
        return () => {
            data.forEach((d, i) => {
                const btn = document.getElementById(d.channelName + "_button_" + id);
                if (btn) {
                    btn.removeEventListener("click", () => {
                        btnUpdate(btn, i);
                    });
                    btn.removeEventListener("onmouseenter", () => {
                        chartElement.dispatchAction({
                            type: "highlight",
                            seriesIndex: i,
                        });
                    });
                    btn.removeEventListener("onmouseleave", () => {
                        chartElement.dispatchAction({
                            type: "downplay",
                            seriesIndex: i,
                        });
                    });
                }
            });
        };
    }, []);

    const legend = (
        <Stack direction="row" justifyContent="center" flexWrap="wrap" gap="8px">
            {legendData.map((value) => {
                return (
                    <Button
                        className={classes.legendStyle}
                        id={value + "_button_" + id}
                        key={value + "_symbol_" + id}
                        data-cy={value + "_button_" + id}
                        sx={{ minWidth: "auto !important" }}
                    >
                        <Stack direction="row" alignItems="center" gap="10px">
                            <div
                                id={value + "_symbol_" + id}
                                style={{
                                    fontSize: "14px",
                                    marginRight: "5px",
                                    borderTop: `3px ${value.includes("Past") ? "dashed" : "solid"} ${getChartColor(
                                        legendTotal?.find((legend) => value.includes(legend.name))?.seriesName || value,
                                    )}`,
                                    height: "2px",
                                    width: "25px",
                                }}
                            ></div>
                            <Stack direction="row" gap="10px" alignItems="center">
                                <Stack alignItems="start">
                                    {ecommerceState === "revenue" ? (
                                        getRevenueLegend(value)
                                    ) : (
                                        <span>{value || ""}</span>
                                    )}
                                </Stack>
                                {legendKeyObj[value] && betaSupportedConnectors.includes(legendKeyObj[value]) && (
                                    <BetaTooltip />
                                )}
                                {value.includes("Shopify-Transactional") ? (
                                    <Tooltip
                                        title={
                                            <>
                                                Prescient only models revenue that can be attributed to marketing. Any
                                                revenue that comes from wholesale orders or subscriptions is removed
                                                before models are run.
                                                <br />
                                                <br />
                                                For more information about how wholesale and subscription revenue is
                                                removed, hover over the information icons within the wholesale and
                                                subscription KPI boxes.
                                            </>
                                        }
                                    >
                                        <span
                                            style={{
                                                marginTop: "4px",
                                            }}
                                        >
                                            <SVGIconRenderer
                                                strokeColor="rgba(0, 0, 0, 0.6)"
                                                icon="infoIcon"
                                                width="16px"
                                                height="16px"
                                            />
                                        </span>
                                    </Tooltip>
                                ) : (
                                    ""
                                )}
                                {value.includes("Google Analytics 4") ? (
                                    <Tooltip
                                        arrow
                                        title={
                                            <>
                                                Prescient unifies your Universal Analytics (UA) and Google Analytics 4
                                                (GA4) data to calculate the KPIs above.
                                                <br />
                                                <br />
                                                Historical values from before your GA4 profile was created will be
                                                filled in with UA data. This means that the lines will be the same until
                                                the date your GA4 profile started reporting data.
                                            </>
                                        }
                                    >
                                        <span
                                            style={{
                                                marginTop: "4px",
                                            }}
                                        >
                                            <SVGIconRenderer
                                                strokeColor="rgba(0, 0, 0, 0.6)"
                                                icon="infoIcon"
                                                width="16px"
                                                height="16px"
                                            />
                                        </span>
                                    </Tooltip>
                                ) : (
                                    ""
                                )}
                            </Stack>
                        </Stack>
                    </Button>
                );
            })}
        </Stack>
    );

    const UpdatedScrollButton = styled(TabScrollButton)(() => ({
        height: "40px",
        width: "40px",
        padding: "8px",
        color: "#000000",
        borderRadius: "50%",
        overflow: "hidden",
        transition: "width 0.5s",
        ".MuiSvgIcon-root": {
            fontSize: "24px",
        },
        "&.Mui-disabled": {
            width: 0,
            padding: 0,
        },
    }));

    return isNewHomePage ? (
        <Stack style={{ width: "98%", overflow: "auto", scrollBehavior: "smooth" }}>
            <Tabs
                ScrollButtonComponent={UpdatedScrollButton}
                className="tabs"
                TabIndicatorProps={{
                    style: {
                        display: "none",
                    },
                }}
                value={0}
                variant="scrollable"
                sx={{
                    ".MuiTabs-scroller .Mui-selected": {
                        color: theme.palette.tertiary.main,
                    },
                }}
            >
                {!!data.length &&
                    legendTotal?.map((series) => {
                        return (
                            <Tab
                                key={series.name}
                                disableRipple
                                className={classes.legendStyle}
                                sx={{
                                    cursor: "default",
                                    borderLeft: `5px solid ${getChartColor(series.seriesName || series.name)}`,
                                    borderRadius: 0,
                                }}
                                label={
                                    <Stack width="100%">
                                        <Stack direction="row" alignItems="center" gap="2px">
                                            <Typography variant="h4" textAlign="left" fontWeight="bold" color="#000">
                                                {formatValue(
                                                    isPercentageViewSelected ? series.percentage : series.value,
                                                    sign,
                                                    isPercentageViewSelected && series.name === "Total" ? 0 : precision,
                                                )}
                                            </Typography>

                                            {compareMode && (
                                                <PercentageTrendChip
                                                    value={calculatePercentage(series.value, series.pastValue) || 0}
                                                    isSpecialChip={true}
                                                    pastRangeString={`${pastDateString}`}
                                                    isPositiveTrendGood={
                                                        NEGATIVE_TREND_GOOD.indexOf(ecommerceState.toUpperCase()) === -1
                                                    }
                                                />
                                            )}
                                        </Stack>
                                        <Stack direction="row" gap="5px" alignItems="center">
                                            <Stack alignItems="start" textAlign="left">
                                                {ecommerceState === "revenue" ? (
                                                    getRevenueLegend(series.name)
                                                ) : (
                                                    <Typography variant="subtitle1" color={grey[700]}>
                                                        {series.name}
                                                    </Typography>
                                                )}
                                            </Stack>
                                            {legendKeyObj[series.name] &&
                                                betaSupportedConnectors?.includes(legendKeyObj[series.name]) && (
                                                    <BetaTooltip />
                                                )}
                                            {series.name.includes("Shopify-Transactional") ? (
                                                <Tooltip
                                                    title={
                                                        <>
                                                            Prescient only models revenue that can be attributed to
                                                            marketing. Any revenue that comes from wholesale orders or
                                                            subscriptions is removed before models are run.
                                                            <br />
                                                            <br />
                                                            For more information about how wholesale and subscription
                                                            revenue is removed, hover over the information icons within
                                                            the wholesale and subscription KPI boxes.
                                                        </>
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            marginTop: "4px",
                                                        }}
                                                    >
                                                        <SVGIconRenderer
                                                            strokeColor="rgba(0, 0, 0, 0.6)"
                                                            icon="infoIcon"
                                                            width="16px"
                                                            height="16px"
                                                        />
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                ""
                                            )}
                                            {series.name.includes("Google Analytics 4") ? (
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        <>
                                                            Prescient unifies your Universal Analytics (UA) and Google
                                                            Analytics 4 (GA4) data to calculate the KPIs above.
                                                            <br />
                                                            <br />
                                                            Historical values from before your GA4 profile was created
                                                            will be filled in with UA data. This means that the lines
                                                            will be the same until the date your GA4 profile started
                                                            reporting data.
                                                        </>
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            marginTop: "4px",
                                                        }}
                                                    >
                                                        <SVGIconRenderer
                                                            strokeColor="rgba(0, 0, 0, 0.6)"
                                                            icon="infoIcon"
                                                            width="16px"
                                                            height="16px"
                                                        />
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                ""
                                            )}
                                        </Stack>
                                    </Stack>
                                }
                            />
                        );
                    })}
            </Tabs>
            <Box
                id={id}
                data-cy="chart-div"
                sx={(theme) => ({
                    height: "500px",
                    minWidth: "99%",
                    width: "99%",
                })}
            />
            {legend}
        </Stack>
    ) : (
        <div style={{ maxWidth: "95%", overflow: "auto" }}>
            <Box
                id={id}
                data-cy="chart-div"
                sx={(theme) => ({
                    height: "300px",
                    minWidth: "98%",
                    width: "98%",
                    [theme.breakpoints.down("xl")]: {
                        width: "550px",
                    },
                })}
            />
            {legend}
        </div>
    );
};
