import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, FormControlLabel, IconButton, Link as MaterialLink, Switch, Toolbar, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Stack, useTheme } from "@mui/system";
import { css } from "@emotion/css";

import { SUPERADMIN_ORG_PATH, SETTINGS_PATH, INTERCOM_HELP_CENTER_PATH } from "../../consts/path/path";
import {
    currentUserRoleByCompanyIdSelector,
    isSuperAdminSelector,
    userStateSelector,
} from "../../reduxState/slices/userSlice";
import {
    selectPeriodExpiredDaysCount,
    selectIsActiveTrial,
    OrganizationsListedByOrderSelector,
    setCurrentlyViewing,
} from "../../reduxState/slices/organizationSlice";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import companyLogo from "./../../assets/logo/Prescient-Logo.svg";
import { navBarLogoClick } from "../../reduxState/actions/navBarLogoClick";
import { demoModeSelector, setDemoMode } from "../../reduxState/slices/demoModeSlice";
import { useLazyGetOrganizationsQuery } from "../../reduxState/apis/organizationApi";
import { BLACK_COLOR } from "../../consts/colors";
import { HeaderTabs } from "./HeaderTabs.v2";
import { AccountSettings } from "./RightHeaderComponents/AccountSettings.v2";
import OrganizationSelection from "./RightHeaderComponents/OrganizationSelection";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import NotificationsList from "./RightHeaderComponents/NotificationsList";
import { useLazyGetAnnouncementsQuery } from "../../reduxState/apis/announcementApi";
import { selectCurrentlyViewingCode } from "../../reduxState/slices/organizationSlice";
import { getDefaultRedirectPath } from "src/consts/path/path";
import { UserRole } from "src/enums/UserRole";

const useStyles = (theme: Theme) => ({
    appbar: css({
        boxShadow: "none",
        height: "80px",
        padding: "0px 30px 0px 20px !important",
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1px solid #0000001F",
    }),
    headerWrapper: css({
        width: "100%",
    }),
    rightPart: css({
        display: "flex",
        alignSelf: "center",
        gap: "15px",
        alignItems: "center",
    }),
    iconButton: {
        height: "40px",
        width: "40px",
    },
    avatar: css({
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        fontSize: "15px",
        fontWeight: "700",
        lineHeight: "22.5px",
    }),
    displayRow: css({
        display: "flex",
        flexDirection: "row",
        "& .logoWrapper": {
            marginRight: "40px",
        },
    }),
    customNavBar: css({
        width: "100%",
        display: "flex",
        height: "80px",
    }),
    logoWrapperLink: css({
        padding: "7px",
        display: "flex",
    }),
    logoClass: css({
        width: "126px",
        height: "22.11px",
        alignSelf:"center"
    }),
    extraButtonClass: css({
        width: "100%",
        "& button": {
            width: "100%",
            justifyContent: "space-between",
            "& .MuiButton-endIcon svg": {
                fontSize: "24px",
                color: BLACK_COLOR,
            },
        },
    }),
    menuItemClass: css({
        padding: "10px 16px !important",
        "& .listItemIcon": {
            color: "rgba(0, 0, 0, 1)",
        },
    }),
    expiredTrialClass: css({
        backgroundColor: "#0D1B21",
        borderRadius: "22px",
        padding: "0px 20px 1px",
        whiteSpace: "pre",
    }),
    bellIconMenu: css({
        "& button": {
            minWidth: "40px!important",
            width: "40px!important",
            borderRadius: "50%",
        },
    }),
});

interface ILightHeaderProps {
    position: "fixed" | "absolute" | "relative" | "static" | "sticky" | undefined;
    showTopNavbar: boolean;
}

export const LightHeader: React.FC<ILightHeaderProps> = ({ position = "fixed", showTopNavbar }) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(userStateSelector);
    const organizations: IOrganization[] = useSelector(OrganizationsListedByOrderSelector);
    const isDemoMode = useSelector(demoModeSelector);
    const trialExpiredDaysCount = useSelector(selectPeriodExpiredDaysCount);
    const isTrialActive = useSelector(selectIsActiveTrial);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);

    const navigate = useNavigate();
    const classes = useStyles(useTheme());
    const location = useLocation();
    const [isUpdate, setIsUpdate] = useState(true);

    const [getAnnouncements] = useLazyGetAnnouncementsQuery();

    const [getOrganizations] = useLazyGetOrganizationsQuery();
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const isSuperAdminRoute = location.pathname.match(/\/adm\//);

    const handleDemoMode = () => {
        // if in demo mode we want to route user back to admin
        // then demo mode will get disabled.
        if (isDemoMode) {
            navigate(SUPERADMIN_ORG_PATH);
        }
        dispatch(setDemoMode(!isDemoMode));
    };

    useEffect(() => {
        getAnnouncements({});
    }, []);

    useEffect(() => {
        if (isSuperAdmin) {
            getOrganizations("").then(() => {
                if (!isDemoMode) {
                    setIsUpdate(false);
                }
            });
        }
    }, [isDemoMode]);

    useEffect(() => {
        if (isDemoMode && !isUpdate) {
            setIsUpdate(true);
            dispatch(setCurrentlyViewing(organizations[0].code));
            navigate(`/org/${organizations[0].code}${getDefaultRedirectPath(organizations[0])}`);
        }
    }, [organizations]);

    const handleLogoClick = () => {
        dispatch(navBarLogoClick());
    };

    const handleSettings = () => {
        navigate(`/org/${orgCode}${SETTINGS_PATH}`);
    };

    return (
        <AppBar className={classes.appbar} position={position} id="top-app-bar">
            <Stack direction="row" alignItems="center" gap={5}>
                <Link to="/" onClick={handleLogoClick} className={classes.logoWrapperLink}>
                    <img src={companyLogo} className={classes.logoClass} />
                </Link>
                <div className={classes.customNavBar}>{showTopNavbar && <HeaderTabs />}</div>
            </Stack>
            <div className={classes.rightPart}>
                {currentUser.isSuperadmin && (isSuperAdminRoute || isDemoMode) && (
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                name="isDemoMode"
                                checked={isDemoMode}
                                onChange={handleDemoMode}
                                size="small"
                            />
                        }
                        label="Demo mode"
                        style={{ whiteSpace: "nowrap", margin: 0 }}
                    />
                )}

                {showTopNavbar && !isSuperAdminRoute && (
                    <>
                        {isTrialActive && (
                            <Stack
                                className={classes.expiredTrialClass}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                gap="5px"
                            >
                                <Typography fontSize="13px" fontWeight="700">
                                    FREE TRIAL
                                </Typography>
                                <Typography>&#x2022;</Typography>
                                <Typography fontSize="13px">
                                    {trialExpiredDaysCount === 0 ? "Last day" : ""}
                                    {trialExpiredDaysCount > 0 ? `${trialExpiredDaysCount + 1} days remaining` : ""}
                                </Typography>
                            </Stack>
                        )}
                        {(isSuperAdmin || userRole !== UserRole.MEMBER) && (
                            <IconButton
                                title="Settings"
                                color="inherit"
                                data-cy="settingsIcon"
                                onClick={handleSettings}
                            >
                                <SVGIconRenderer icon="settingsIcon" />
                            </IconButton>
                        )}

                        <div className={classes.bellIconMenu}>
                            <NotificationsList classes={classes} />
                        </div>

                        <MaterialLink data-cy="helpCenterBtn" target="_blank" href={INTERCOM_HELP_CENTER_PATH}>
                            <IconButton title="Help center" color="inherit">
                                <SVGIconRenderer icon="helpIcon" />
                            </IconButton>
                        </MaterialLink>
                        <OrganizationSelection />
                    </>
                )}
                <AccountSettings classes={classes} />
            </div>
        </AppBar>
    );
};
