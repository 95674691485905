import { css } from "@emotion/css";
import { Theme, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ConnectorSuccess } from "src/components/ConnectorV3/CreateConnectorSteps/ConnectorSuccess";
import { ConnectorTableWithStepperWrapper } from "src/components/ConnectorV3/CreateConnectorSteps/ConnectorTableWithStepperWrapper";
import { SelectDataChannelsStep } from "src/components/ConnectorV3/CreateConnectorSteps/SelectDataChannelsStep";
import { AmazonAdsModal } from "src/components/ConnectorsComponent/Modals/AmazonAdsModal";
import { ConnectGoogleSheetsModal } from "src/components/ConnectorsComponent/Modals/ConnectGoogleSheetsModal";
import { ConnectorConnectModal } from "src/components/ConnectorsComponent/Modals/ConnectorConnectModal";
import { PurgeConnectorModal } from "src/components/ConnectorsComponent/Modals/PurgeConnectorModal";
import { ResyncConnectorModal } from "src/components/ConnectorsComponent/Modals/ResyncConnectorModal";
import { ShareConnectorModal } from "src/components/ConnectorsComponent/Modals/ShareConnectorModal";
import { CustomModal } from "src/components/CustomModal/CustomModal";
import { AMAZON_ADS, GOOGLE_SHEETS } from "src/consts/connectors";
import { CONNECTOR_CREATION_STEPS } from "src/consts/connectorsPage/connectorsPage";
import { CONNECTORS_PATH, CUSTOM_CREATE_CONNECTOR_PATH } from "src/consts/path/path";
import { IConnector } from "src/interfaces/IConnector";
import { IDataSource, IDataSourceWithConnected } from "src/interfaces/IData";
import { IMember } from "src/interfaces/entities/IMember";
import {
    STATUS_MAPPING,
    useCreateConnectorMutation,
    useCreateCustomConnectorMutation,
    useDeleteConnectorMutation,
    useHistoricalResyncConnectorMutation,
    usePurgeConnectorMutation,
    useReconnectConnectorMutation,
    useShareConnectorConfigureMutation,
} from "src/reduxState/apis/connectorsApi";
import { useLazyGetSupportedDataSourcesQuery } from "src/reduxState/apis/dataSourcesApi";
import { useLazyGetMembersQuery } from "src/reduxState/apis/memberApi";
import {
    addConnector,
    connectorsSelector,
    deleteConnectorById,
    setConnector,
} from "src/reduxState/slices/connectorsSlice";
import { adminMembersSelector } from "src/reduxState/slices/membersSlice";
import {
    isOnboardingCompletedOfCurrentCompany,
    selectCurrentlyViewingCode,
    selectCurrentlyViewingId,
} from "src/reduxState/slices/organizationSlice";
import {
    orgSpecificCleanDataSourcesSelector,
    orgSpecificDataSourcesSelector,
} from "src/reduxState/slices/supportedDataSourcesByOrgSlice";
import { userIdSelector } from "src/reduxState/slices/userSlice";
import { isCustomConnector } from "src/services/connectors/connectors";
import { dataSourceServiceIsGoogleSheets } from "src/services/dataSources/dataSources";
import {
    areAllRequiredConnectorCategoriesConnected,
    getActiveConnectorStep,
    getActiveStepBasedOnConnectorIdParam,
    getAdvertisingConnectorsGrp,
    getAnalyticsConnectorsGrp,
    getEcommerceConnectorsGrp,
    getUpdatedDataSources,
} from "src/services/onboardingPage/onboardingPage";
import { buildConnectionCardUrl } from "src/services/utils";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../services/utils";
import { ConnectorsListingPage } from "./ConnectorsListingPage";
import { DeleteConnectorModal } from "src/components/ConnectorsComponent/Modals/DeleteConnectorModal";

const useStyles = (theme: Theme) => ({
    containerStyle: css({
        display: "flex",
        gap: theme.spacing(5),
        flexDirection: "column",
    }),
});

export const ConnectorOnboardingSteps = () => {
    const classes = useStyles(useTheme());
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const latestQueryParamsObj = queryString.parse(location.search);
    const connectorId = latestQueryParamsObj.connector_id;
    const fivetranId = latestQueryParamsObj.id;

    const [activeStep, setActiveStep] = useState(0);
    const [analyticsReady, setAnalyticsReady] = useState(false);

    const [currentService, setCurrentService] = useState<string[]>([]);
    const [selectedConnector, setSelectedConnector] = useState<IConnector | null>(null);
    const [confirmationModal, setConfirmationModal] = useState<string>("");
    const [isConnectionInProgress, setIsConnectionInProgress] = useState(false);
    const [selectedDataSource, setSelectedDataSource] = useState<IDataSource | null>(null);

    const orgId = useSelector(selectCurrentlyViewingId);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const orgSpecificCleanDataSources = useSelector(orgSpecificCleanDataSourcesSelector);
    const orgSpecificDataSources = useSelector(orgSpecificDataSourcesSelector);
    const connectors: IConnector[] = useSelector(connectorsSelector);
    const adminMembers = useSelector(adminMembersSelector);
    const currentUserId = useSelector(userIdSelector);

    const [getSupportedDataSources, { isLoading, isFetching }] = useLazyGetSupportedDataSourcesQuery();
    const [reconnectConnector] = useReconnectConnectorMutation();
    const [deleteConnectors, { isLoading: disableDeleteBtn }] = useDeleteConnectorMutation();
    const [purgeConnector, { isLoading: purgeLoading }] = usePurgeConnectorMutation();
    const [historicalResyncConnector, { isLoading: resyncLoading }] = useHistoricalResyncConnectorMutation();
    const [shareConnectorConfigure] = useShareConnectorConfigureMutation();
    const [getMembers] = useLazyGetMembersQuery();
    const [createConnector] = useCreateConnectorMutation();
    const [createCustomConnector] = useCreateCustomConnectorMutation();
    const isConnectorOnboardingCompleted = useSelector(isOnboardingCompletedOfCurrentCompany);

    const [hasAcknowledgedTerms, setHasAcknowledgedTerms] = useState<Record<string, boolean>>({});

    const adminMemberOptions: Array<{ id: string; name: string }> = adminMembers
        .filter((m: IMember) => m.id !== currentUserId)
        .map((member: IMember) => ({
            id: member.id,
            name: `${member.firstName} ${member.lastName} - ${member.email}`,
        }));

    const preparedDataSources = getUpdatedDataSources(connectors, orgSpecificCleanDataSources, orgSpecificDataSources);
    const isConnectorsConnectedForStep1 = areAllRequiredConnectorCategoriesConnected(connectors);

    const ecommerceConnectors = getEcommerceConnectorsGrp(connectors);
    const advertisingConnectors = getAdvertisingConnectorsGrp(connectors);
    const analyticsConnectors = getAnalyticsConnectorsGrp(connectors);
    const analyticsService = useAnalyticsService();

    const getAnalyticsConnectorFlowStepName = () => {
        if (isConnectorOnboardingCompleted) {
            return;
        }

        return CONNECTOR_CREATION_STEPS[activeStep]?.label || "";
    };

    useEffect(() => {
        if (!analyticsReady) {
            return;
        }

        analyticsService.logEvent("Connectors Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
            "Connector Flow Step": getAnalyticsConnectorFlowStepName(),
        });
    }, [analyticsReady, activeStep]);

    useEffect(() => {
        getMembers({ orgId, include: "companies,users_roles" });
        getSupportedDataSources({ orgId }).then(() => {
            if (!connectorId && !fivetranId) {
                const initialStep = getActiveConnectorStep(connectors);
                setActiveStep(initialStep);
            }
            setAnalyticsReady(true);
        });
    }, []);

    useEffect(() => {
        // Helper function to handle setting active step and navigating
        const handleNavigation = (id: string, idKey: keyof IConnector) => {
            const connector = connectors.find((c) => c[idKey] === id);
            const connectorGroup = connector && preparedDataSources.find((c) => c.service === connector?.service);
            if (connectorGroup) {
                setActiveStep(getActiveStepBasedOnConnectorIdParam(connectorGroup));
                // once redirected to respective step, remove search params
                navigate({ search: "" });
            }
        };

        if (connectorId || fivetranId) {
            const id = (connectorId ?? fivetranId) as string;
            const idKey = connectorId ? "id" : "fivetranId";
            handleNavigation(id, idKey);
        }
    }, [connectors, orgSpecificCleanDataSources, orgSpecificDataSources]);

    const addChannelHandlerForStep1 = async (dataSource: IDataSource) => {
        const connectorParamObj = {
            data_source_id: dataSource.id,
        };
        setCurrentService((services) => [...new Set([...services, dataSource.programmaticName])]);
        const createFunction = dataSource.provider === "fivetran" ? createConnector : createCustomConnector;
        try {
            const res = await createFunction({
                body: connectorParamObj,
                orgId,
            }).unwrap();

            dispatch(addConnector(res));
        } finally {
            setCurrentService((services) => [...services.filter((s) => s !== dataSource.programmaticName)]);
        }
    };

    const removeChannelHandler = async (dataSource: IDataSourceWithConnected) => {
        if (dataSource.connectorId) {
            setCurrentService((services) => [...new Set([...services, dataSource.programmaticName])]);
            await deleteConnectors({
                id: dataSource.connectorId.toString(),
                orgId,
            })
                .then(() => {
                    dispatch(deleteConnectorById(dataSource.connectorId));
                })
                .finally(() => {
                    setCurrentService((services) => [...services.filter((s) => s !== dataSource.programmaticName)]);
                });
        }
    };

    const reconnectConnectorHandler = (connector: IConnector) => {
        setIsConnectionInProgress(true);
        if (isCustomConnector(connector.service)) {
            navigate(
                `/org/${orgCode}${CUSTOM_CREATE_CONNECTOR_PATH}?connectorName=${connector.dataSource.programmaticName}&connectorId=${connector.id}&isConfigSet=true`,
            );
        } else {
            reconnectConnector({
                orgId,
                connectorId: connector.id.toString(),
            })
                .unwrap()
                .then((response: { data: { fivetran_connect_url: string } }) => {
                    const path = window.location.origin + `/org/${orgCode}${CONNECTORS_PATH}`;
                    const redirectURL = `${response.data.fivetran_connect_url}${path}`;
                    window.open(redirectURL, "_self");
                })
                .catch(() => {
                    setIsConnectionInProgress(false);
                    enqueueSnackbar(
                        `Failed to perform action for ${connector.connectorName}. Please try again later.`,
                        {
                            id: uniqueId(),
                            variant: "error",
                        },
                    );
                })
                .finally(() => {
                    closeModal();
                });
        }
    };

    const deleteConnector = (connector: IConnector) => {
        analyticsService.logEvent("Connector Deleted", {
            Page: getPathForAnalytics(location.pathname),
            "Connector categories": connector.categories,
            "Connector name": connector.connectorName,
        });
        deleteConnectors({
            id: connector.id.toString(),
            orgId,
        })
            .unwrap()
            .then(() => {
                dispatch(deleteConnectorById(connector.id));
                enqueueSnackbar(`${connector.connectorName} deleted successfully.`, {
                    id: uniqueId(),
                    variant: "success",
                });
            })
            .catch(() => {
                enqueueSnackbar(`Failed to delete ${connector.connectorName}. Please try again later.`, {
                    id: uniqueId(),
                    variant: "error",
                });
            })
            .finally(() => {
                closeModal();
            });
    };

    const purgeConnectorHandler = async (connector: IConnector) => {
        await purgeConnector({
            connectorId: connector.id.toString(),
            orgId,
        })
            .then(() => {
                enqueueSnackbar(`${connector.connectorName} purged successfully.`, {
                    id: uniqueId(),
                    variant: "success",
                });
            })
            .catch(() => {
                enqueueSnackbar(`Failed to purge ${connector.connectorName}. Please try again later.`, {
                    id: uniqueId(),
                    variant: "error",
                });
            })
            .finally(() => {
                closeModal();
            });
    };

    const resyncConnectorHandler = async (connector: IConnector) => {
        await historicalResyncConnector({
            connectorId: connector.id.toString(),
            orgId,
        })
            .unwrap()
            .then(() => {
                dispatch(
                    setConnector(
                        connectors.map((c: IConnector) => {
                            return connector.id === c.id
                                ? {
                                      ...c,
                                      status: STATUS_MAPPING.historical_syncing,
                                  }
                                : c;
                        }),
                    ),
                );
                enqueueSnackbar(`Historical resync started for ${connector.connectorName}.`, {
                    id: uniqueId(),
                    variant: "success",
                });
            })
            .catch(() => {
                enqueueSnackbar(`Failed to historical resync ${connector.connectorName}. Please try again later.`, {
                    id: uniqueId(),
                    variant: "error",
                });
            })
            .finally(() => {
                closeModal();
            });
    };

    const shareConnectorHandler = (
        connector: IConnector,
        formState: {
            userId: string;
            message: string;
        },
    ) => {
        shareConnectorConfigure({
            connectorId: connector.id.toString(),
            orgId,
            body: {
                user_id: formState.userId,
                message: formState.message,
            },
        })
            .then(() => {
                enqueueSnackbar(`Email sent successfully.`, {
                    id: uniqueId(),
                    variant: "success",
                });
                analyticsService.logEvent("Share Connector Request Sent", {});
            })
            .catch(() => {
                enqueueSnackbar(`Failed to share connector. Please try again later.`, {
                    id: uniqueId(),
                    variant: "error",
                });
            })
            .finally(() => {
                closeModal();
            });
    };

    // if connector already created then perform reconnect action for connector listing connect/Re-Connect
    const handleCreateConnectorWithFivetran = async (dataSource: IDataSourceWithConnected) => {
        const findConn = connectors.find((c) => c.service === dataSource.service);

        if (dataSource.isConnected && findConn) {
            reconnectConnectorHandler(findConn);
            return;
        }

        if ([GOOGLE_SHEETS, AMAZON_ADS].includes(dataSource.service)) {
            setSelectedDataSource(dataSource);
            if (dataSource.service === AMAZON_ADS || !hasAcknowledgedTerms[dataSource.id]) {
                setConfirmationModal(dataSource.service);
            } else {
                setConfirmationModal("create");
            }
            return;
        }

        if (dataSource.provider === "fivetran") {
            setSelectedDataSource(dataSource);
            setConfirmationModal("create");
            return;
        }

        await createCustomConnector({
            body: {
                data_source_id: dataSource.id,
            },
            orgId,
        })
            .unwrap()
            .then((response) => {
                navigate(
                    `/org/${orgCode}${CUSTOM_CREATE_CONNECTOR_PATH}?connectorName=${dataSource.programmaticName}&connectorId=${response.id}&isConfigSet=false&path=/org/${orgCode}/settings/connectors?id=${response.id}`,
                );
            });
    };

    const connectFivetranConnector = (dataSource: IDataSource) => {
        setIsConnectionInProgress(true);
        createConnector({
            body: {
                data_source_id: dataSource.id,
            },
            orgId,
        })
            .unwrap()
            .then((response: { fivetranCardUri: string }) => {
                const connectionCardUrl = buildConnectionCardUrl(
                    response.fivetranCardUri,
                    undefined,
                    window.location.origin + `/org/${orgCode}${CONNECTORS_PATH}`,
                );
                window.open(connectionCardUrl, "_self");
            })
            .catch(() => {
                setIsConnectionInProgress(false);
            });
    };

    const handleNext = (isFinish: boolean = false) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => {
        setActiveStep(step);
    };

    const openRespectiveModal = (connector: IConnector, action: string) => {
        if (isCustomConnector(connector.service) && action === "connect") {
            reconnectConnectorHandler(connector);
            return;
        }
        setSelectedConnector(connector);
        if (["amazon_ads"].includes(connector.service) && action === "connect") {
            setConfirmationModal(connector.service);
            return;
        }
        setConfirmationModal(action);
    };

    const createOrReconnectAmazonAdsConnector = () => {
        selectedConnector
            ? reconnectConnectorHandler(selectedConnector)
            : selectedDataSource && connectFivetranConnector(selectedDataSource);
    };

    const closeModal = () => {
        setSelectedConnector(null);
        setConfirmationModal("");
    };

    const rememberTheClientCheckedTheBoxFor = (dataSource: IDataSource) => {
        setHasAcknowledgedTerms((prev) => ({ ...prev, [dataSource.id]: true }));
    };

    // Common props that are passed to each step component
    const commonProps = {
        activeStep,
        handleNext,
        handleBack,
        handleStep,
        editConnector: reconnectConnectorHandler,
        openRespectiveConfirmModal: openRespectiveModal,
    };

    const getStepComponent: { [key: number]: JSX.Element } = {
        0: (
            <SelectDataChannelsStep
                isLoading={isFetching}
                preparedDataSources={preparedDataSources}
                addChannelHandler={addChannelHandlerForStep1}
                removeChannelHandler={removeChannelHandler}
                currentService={currentService}
                activeStep={activeStep}
                isConnectorsConncted={!isFetching && isConnectorsConnectedForStep1}
                handleNext={handleNext}
                handleBack={handleBack}
                handleStep={handleStep}
            />
        ),
        1: (
            <ConnectorTableWithStepperWrapper
                connectors={ecommerceConnectors}
                {...commonProps}
                title="Next, connect your E-commerce channels"
                subTitle="Connect, authorize and assign permissions for each E-commerce channel. You historical revenue will help optimize your budget allocation."
            />
        ),
        2: (
            <ConnectorTableWithStepperWrapper
                connectors={advertisingConnectors}
                {...commonProps}
                title="Now, connect your Advertising channels"
                subTitle="Connect, authorize and assign permissions for each Advertising channel. This will assist in optimizing your ad spend."
            />
        ),
        3: (
            <ConnectorTableWithStepperWrapper
                connectors={analyticsConnectors}
                {...commonProps}
                title="Finally, connect your Analytics channels"
                subTitle="Connect, authorize and assign permissions for each Analytics channel."
            />
        ),
        4: (
            <ConnectorSuccess
                handleNext={() => {
                    location.reload();
                }}
            />
        ),
    };

    return (
        <Stack className={classes.containerStyle}>
            {isConnectorOnboardingCompleted ? (
                <ConnectorsListingPage
                    openRespectiveConfirmModal={openRespectiveModal}
                    editConnector={reconnectConnectorHandler}
                    handleCreateConnectorWithFivetran={handleCreateConnectorWithFivetran}
                    isConnectionInProgress={isConnectionInProgress}
                />
            ) : (
                <Stack padding="0 120px 0 120px" gap={5}>
                    {getStepComponent[activeStep]}
                </Stack>
            )}

            {selectedConnector && (
                <>
                    {confirmationModal === "resync" && (
                        <ResyncConnectorModal
                            connector={selectedConnector}
                            closeModal={closeModal}
                            resyncConnector={resyncConnectorHandler}
                            resyncLoading={resyncLoading}
                        />
                    )}
                    {confirmationModal === "purge" && (
                        <PurgeConnectorModal
                            connector={selectedConnector}
                            closeModal={closeModal}
                            purgeConnector={purgeConnectorHandler}
                            purgeLoading={purgeLoading}
                        />
                    )}
                    {confirmationModal === "delete" && (
                        <DeleteConnectorModal
                            selectedConnector={selectedConnector}
                            deleteConnector={deleteConnector}
                            closeModal={closeModal}
                            disableDeleteBtn={disableDeleteBtn}
                        />
                    )}
                    {confirmationModal === "connect" &&
                        (dataSourceServiceIsGoogleSheets(selectedConnector.dataSource) &&
                        !hasAcknowledgedTerms[selectedConnector.dataSource.id] ? (
                            <ConnectGoogleSheetsModal
                                action={() => rememberTheClientCheckedTheBoxFor(selectedConnector.dataSource)}
                                closeModal={closeModal}
                                shouldSendEmail={Object.keys(hasAcknowledgedTerms).length === 0}
                            />
                        ) : (
                            <ConnectorConnectModal
                                selectedConnector={selectedConnector}
                                connectorName={selectedConnector.connectorName || ""}
                                closeModal={closeModal}
                                reconnectConnectorHandler={reconnectConnectorHandler}
                                isLoading={isConnectionInProgress}
                            />
                        ))}
                    {confirmationModal === "share" && (
                        <ShareConnectorModal
                            adminMemberOptions={adminMemberOptions}
                            closeModal={closeModal}
                            shareConnectorHandler={shareConnectorHandler}
                            selectedConnector={selectedConnector}
                        />
                    )}
                </>
            )}

            {confirmationModal === GOOGLE_SHEETS && selectedDataSource && (
                <ConnectGoogleSheetsModal
                    action={() => {
                        rememberTheClientCheckedTheBoxFor(selectedDataSource);
                        setConfirmationModal("create");
                    }}
                    shouldSendEmail={Object.keys(hasAcknowledgedTerms).length === 0}
                    closeModal={closeModal}
                />
            )}
            {confirmationModal === "amazon_ads" && (
                <AmazonAdsModal
                    closeModal={closeModal}
                    onContinueClicked={createOrReconnectAmazonAdsConnector}
                    isLoading={isConnectionInProgress}
                />
            )}
            {selectedDataSource && confirmationModal === "create" && (
                <ConnectorConnectModal
                    selectedConnector={selectedDataSource}
                    connectorName={selectedDataSource.name}
                    closeModal={closeModal}
                    reconnectConnectorHandler={connectFivetranConnector}
                    isLoading={isConnectionInProgress}
                />
            )}
        </Stack>
    );
};
