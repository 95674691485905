import React, { useState } from "react";
import { css } from "@emotion/css";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { Formik, Form, FormikConfig, FormikProps, FieldArray } from "formik";
import { object, string } from "yup";
import Spinner from "@mui/material/CircularProgress";
import { Stack } from "@mui/system";
import { TabPanel } from "@mui/lab";

import { IDataSourceForm } from "src/interfaces/IData";
import { ImageInput } from "src/components/core/ImageInput/ImageInput";
import { fullWidthClass, imageInputClass, imageLabel } from "src/assets/styles/commonStyle";
import { Flex, FlexDirection } from "src/components/core/Flex/Flex";
import { FlexItem } from "src/components/core/FlexItem/FlexItem";
import { Field } from "src/components/core/Field/Field";
import { Button } from "src/components/core/Button/Button";
import { getDataSourceIconImage } from "src/services/dataSources/dataSources";
import {
    API_KEY_TYPE,
    DATA_SOURCE_FORM_TABS,
    DATA_SOURCE_PROVIDERS,
    DataSourceTabType,
} from "src/consts/dataSourcePage/dataSourcePage";
import { CustomTabContext } from "src/components/CustomTabContext/CustomTabContext";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { capitalizeFirstLetter, stringToCapitalize } from "src/services/utils";
import { IDataProvider } from "src/interfaces/IDataProvider";
import { IDataService } from "src/interfaces/IDataServices/IDataServices";
import { IDataCategory } from "src/interfaces/IDataCategory/IDataCategory";

const switchStyle = css`
    margin: 0 auto !important;
`;

interface IDataSourceFormModalProps extends FormikConfig<IDataSourceForm> {
    isEditMode?: boolean;
    dataServicesOptions: IDataService[];
    dataSourceCategoriesOptions: IDataCategory[];
    dataSourceProviders: IDataProvider[];
    onChangeMode?: (...args: any[]) => any;
}

const validationSchema = object().shape({
    name: string().required("Data source name is required"),
    programmaticName: string().required("Programmatic name is required"),
    description: string().required("Description is required"),
    colorCode: string().required("Color code is required").nullable(),
    dataSourceServiceId: string().required("Data source service is required"),
    dataSourceProviderId: string().required("Data source provider is required"),
});

const toBase64 = (file: Blob) =>
    new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

export const DataSourceForm: React.FC<IDataSourceFormModalProps> = ({
    isEditMode,
    dataServicesOptions,
    dataSourceCategoriesOptions,
    dataSourceProviders,
    ...rest
}) => {
    const theme = useTheme();
    const [tabValue, setTabValue] = useState(DataSourceTabType.Details);

    const handleFormTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue as DataSourceTabType);
    };

    const handleImageSelect = async (
        file: Blob | null,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    ) => {
        if (file) {
            const base64 = await toBase64(file);
            setFieldValue("icon", base64);
        }
    };

    return (
        <Formik validationSchema={validationSchema} {...rest}>
            {({ touched, errors, handleChange, values, isSubmitting, setFieldValue }: FormikProps<IDataSourceForm>) => {
                const disabledInput = isSubmitting;
                let tabs = [...DATA_SOURCE_FORM_TABS];

                const formFields: Array<{
                    id: keyof IDataSourceForm;
                    label: string;
                    error: boolean;
                    helperText?: string;
                    multiline?: boolean;
                    rows?: number;
                }> = [
                    {
                        id: "name",
                        label: "Data source name",
                        error: Boolean(touched.name && errors.name),
                        helperText: errors.name,
                    },
                    {
                        id: "programmaticName",
                        label: "Programmatic name",
                        error: Boolean(touched.programmaticName && errors.programmaticName),
                        helperText: errors.programmaticName,
                    },
                    {
                        id: "description",
                        label: "Description",
                        error: Boolean(touched.description && errors.description),
                        helperText: errors.description,
                        multiline: true,
                        rows: 3,
                    },
                    {
                        id: "colorCode",
                        label: "Color code",
                        error: Boolean(touched.colorCode && errors.colorCode),
                        helperText: errors.colorCode,
                    },
                ];

                const findP = dataServicesOptions.find(
                    (o) => o.id === values.dataSourceServiceId,
                )?.dataSourceProviderId;
                const provider = findP ? DATA_SOURCE_PROVIDERS[findP] : "";

                if (provider === "Prescient") {
                    tabs = tabs.map((t) =>
                        t.value === DataSourceTabType.SchemaConfig ? { ...t, isHidden: false } : t,
                    );
                }

                const filteredDataServicesBasedOnProvider = dataServicesOptions.filter(
                    (service) => service.dataSourceProviderId === values.dataSourceProviderId,
                );

                const dataSourceProviderChangeHandler = (e: SelectChangeEvent<number>) => {
                    setFieldValue("dataSourceProviderId", e.target.value);
                    setFieldValue("dataSourceServiceId", "");
                };

                return (
                    <Form>
                        <CustomTabContext tabValue={tabValue} handleChange={handleFormTabChange} tabs={tabs}>
                            <>
                                <TabPanel sx={{ padding: 0, marginTop: "2em" }} value={DataSourceTabType.Details}>
                                    <Typography className={imageLabel}>Logo</Typography>
                                    <div className={imageInputClass}>
                                        <ImageInput
                                            onChange={handleChange}
                                            initial={isEditMode ? getDataSourceIconImage(values) : ""}
                                            onSelectImage={(file, name) => handleImageSelect(file, setFieldValue)}
                                            isAvatar
                                            name="icon"
                                        />
                                    </div>
                                    <Flex direction={FlexDirection.Column} spacing="24px">
                                        {formFields.map((field) => (
                                            <FlexItem className={fullWidthClass} key={field.id}>
                                                <Field
                                                    id={field.id}
                                                    name={field.id}
                                                    disabled={disabledInput}
                                                    label={field.label}
                                                    value={values[field.id] || ""}
                                                    onChange={handleChange}
                                                    inputProps={{ maxLength: 30 }}
                                                    placeholder={field.label}
                                                    className={fullWidthClass}
                                                    error={field.error}
                                                    helperText={field.error ? field.helperText : ""}
                                                    rows={field.rows || 1}
                                                    multiline={field.multiline || false}
                                                />
                                            </FlexItem>
                                        ))}
                                        <FlexItem className={fullWidthClass}>
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    error={Boolean(
                                                        touched.dataSourceProviderId && errors.dataSourceProviderId,
                                                    )}
                                                >
                                                    Data source provider
                                                </InputLabel>
                                                <Select
                                                    onChange={(e) => dataSourceProviderChangeHandler(e)}
                                                    value={values.dataSourceProviderId || ""}
                                                    label="Data source provider"
                                                    name="dataSourceProviderId"
                                                    error={Boolean(
                                                        touched.dataSourceProviderId && errors.dataSourceProviderId,
                                                    )}
                                                    disabled={disabledInput}
                                                >
                                                    {dataSourceProviders.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {capitalizeFirstLetter(item.name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.dataSourceProviderId && errors.dataSourceProviderId ? (
                                                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                                                        {errors.dataSourceProviderId}
                                                    </FormHelperText>
                                                ) : (
                                                    <></>
                                                )}
                                            </FormControl>
                                        </FlexItem>
                                        <FlexItem className={fullWidthClass}>
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    error={Boolean(
                                                        touched.dataSourceServiceId && errors.dataSourceServiceId,
                                                    )}
                                                >
                                                    Data source service
                                                </InputLabel>
                                                <Select
                                                    onChange={handleChange}
                                                    value={values.dataSourceServiceId || ""}
                                                    label="Data source service"
                                                    name="dataSourceServiceId"
                                                    error={Boolean(
                                                        touched.dataSourceServiceId && errors.dataSourceServiceId,
                                                    )}
                                                    disabled={disabledInput}
                                                >
                                                    {filteredDataServicesBasedOnProvider.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {stringToCapitalize(item.name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.dataSourceServiceId && errors.dataSourceServiceId ? (
                                                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                                                        {errors.dataSourceServiceId}
                                                    </FormHelperText>
                                                ) : (
                                                    <></>
                                                )}
                                            </FormControl>
                                        </FlexItem>
                                        <FlexItem className={fullWidthClass}>
                                            <FormControl fullWidth>
                                                <InputLabel>Data source category</InputLabel>
                                                <Select
                                                    onChange={handleChange}
                                                    value={values.dataSourceCategoryIds || []}
                                                    label="Data source category"
                                                    name="dataSourceCategoryIds"
                                                    multiple
                                                    disabled={disabledInput}
                                                >
                                                    {dataSourceCategoriesOptions.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </FlexItem>
                                        {(
                                            ["beta", "deprecated", "pauseByDefault", "displayPerformance"] as Array<
                                                keyof IDataSourceForm
                                            >
                                        ).map((switchField) => (
                                            <FlexItem className={fullWidthClass} key={switchField}>
                                                <FormControlLabel
                                                    className={switchStyle}
                                                    control={
                                                        <Switch
                                                            className="customSwitch"
                                                            color="primary"
                                                            checked={!!values[switchField]}
                                                            onChange={() =>
                                                                setFieldValue(switchField, !values[switchField])
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        switchField.charAt(0).toUpperCase() +
                                                        switchField.slice(1).replace(/([A-Z])/g, " $1")
                                                    }
                                                    disabled={disabledInput}
                                                />
                                            </FlexItem>
                                        ))}
                                    </Flex>
                                </TabPanel>
                                <TabPanel sx={{ padding: 0, marginTop: "2em" }} value={DataSourceTabType.SchemaConfig}>
                                    <FieldArray
                                        name="apiKeys"
                                        render={({ remove, push }) => {
                                            const addEntry = () => {
                                                push({
                                                    fieldName: "",
                                                    dataType: "",
                                                    isRequired: false,
                                                });
                                            };

                                            const apiKeysLength = values.apiKeys?.length || 0;

                                            return (
                                                <div>
                                                    {values.apiKeys?.map((apiKey, index: number) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <Stack
                                                                    flexDirection="row"
                                                                    gap="10px"
                                                                    alignItems="center"
                                                                    sx={{ marginTop: "24px", width: "100%" }}
                                                                >
                                                                    <Tooltip arrow title="Make required">
                                                                        <Checkbox
                                                                            sx={{ padding: 0 }}
                                                                            color="primary"
                                                                            name={`apiKeys.${index}.isRequired`}
                                                                            checked={apiKey.isRequired}
                                                                            onChange={(e) => {
                                                                                setFieldValue(
                                                                                    `apiKeys.${index}.isRequired`,
                                                                                    e.target.checked,
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                    <Field
                                                                        id={`apiKeys.${index}.fieldName`}
                                                                        value={apiKey.fieldName}
                                                                        name={`apiKeys.${index}.fieldName`}
                                                                        disabled={disabledInput}
                                                                        label="Field name"
                                                                        onChange={handleChange}
                                                                        inputProps={{ maxLength: 30 }}
                                                                        placeholder="Field name"
                                                                    />
                                                                    <FormControl fullWidth>
                                                                        <InputLabel>Data type</InputLabel>
                                                                        <Select
                                                                            onChange={(e, newValue) => {
                                                                                if (newValue) {
                                                                                    setFieldValue(
                                                                                        `apiKeys.${index}.dataType`,
                                                                                        e.target.value,
                                                                                    );
                                                                                }
                                                                            }}
                                                                            value={apiKey.dataType}
                                                                            label="Data type"
                                                                            name={`apiKeys.${index}.dataType`}
                                                                            disabled={disabledInput}
                                                                        >
                                                                            {API_KEY_TYPE.map((item) => (
                                                                                <MenuItem key={item.id} value={item.id}>
                                                                                    {item.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>

                                                                    <IconButton onClick={() => remove(index)}>
                                                                        <SVGIconRenderer
                                                                            icon="closeIcon"
                                                                            strokeColor="rgba(0, 0, 0, 0.54)"
                                                                        />
                                                                    </IconButton>
                                                                </Stack>
                                                            </React.Fragment>
                                                        );
                                                    })}

                                                    <Button
                                                        onClick={() => addEntry()}
                                                        disabled={
                                                            values.apiKeys && apiKeysLength > 0
                                                                ? !values.apiKeys[apiKeysLength - 1].dataType ||
                                                                  !values.apiKeys[apiKeysLength - 1].fieldName
                                                                : false
                                                        }
                                                        variant="text"
                                                    >
                                                        Add new property
                                                    </Button>
                                                </div>
                                            );
                                        }}
                                    />
                                </TabPanel>
                            </>
                        </CustomTabContext>

                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            className={fullWidthClass}
                            fullWidth
                            sx={{ marginTop: "15px" }}
                        >
                            {isSubmitting ? <Spinner size={25} /> : isEditMode ? "Save Changes" : "Create Data Source"}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};
