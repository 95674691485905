import { LockOutlined } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button } from "@mui/material";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { VERIFY_QA_METRICS_PATH } from "src/consts/path/path";
import { selectCurrentlyViewingCode } from "src/reduxState/slices/organizationSlice";
import { HomePagePartialCard } from "./HomePagePartialCard";

interface IVerifyDataQa {
    style?: React.CSSProperties;
    tabName: string;
    description?: string;
}

export const VerifyDataQa: FC<IVerifyDataQa> = ({ style = { padding: "40px 20px" }, tabName, description }) => {
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const navigate = useNavigate();

    return (
        <HomePagePartialCard
            animatedIcon={false}
            icon={
                <LockOutlined
                    color="primary"
                    sx={{
                        width: "56px",
                        height: "56px",
                        opacity: "100%",
                    }}
                />
            }
            heading={`Verify Your Data to Access ${tabName}`}
            description={
                description ||
                "Once you have verified your data, results typically begin to flow 1 week after completion. Please allow more time for Amazon."
            }
            actionButton={
                <Button
                    data-cy="verify-data-qa"
                    variant="contained"
                    size="small"
                    endIcon={<ChevronRightIcon />}
                    onClick={() => {
                        navigate(`/org/${orgCode}${VERIFY_QA_METRICS_PATH}`);
                    }}
                >
                    Verify Your Data
                </Button>
            }
            style={style}
        />
    );
};
