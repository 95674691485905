import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { Form, Formik, FormikProps } from "formik";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import uniqueId from "lodash/uniqueId";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import ScenarioForm from "./ScenarioForm/ScenarioForm";
import addScenarioFormModel from "../formModel/addScenarioFormModel";
import validationSchema from "../formModel/validationSchema";
import { SCENARIO_BASIS, TIMEFRAME_OPTIONS } from "../../../consts/optimizationPage/optimizationPage";
import { IScenario } from "../../../interfaces/entities/IScenario";
import {
    calculatePercentage,
    capitalizeFirstLetter,
    formatValue,
    getDiffObjectKeys,
    getPathForAnalytics,
} from "../../../services/utils";
import { ScenaioAppBar } from "../AppBar/ScenaioAppBar";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { selectCurrentlyViewingCode, selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { userStateSelector } from "../../../reduxState/slices/userSlice";
import { optimizationStyle } from "../../../containers/Optimization/optimizationStyle";
import { OPTIIMIZATION_CREATE_PATH, OPTIIMIZATION_PATH } from "../../../consts/path/path";
import {
    useLazyGetAvailableOptimizationModelsQuery,
    useCreateScenarioMutation,
    useEditScenarioMutation,
    useLazyGetScenarioByIdQuery,
} from "../../../reduxState/apis/optimizationApi";
import { ScenarioFooter } from "../ScenarioFooter/ScenarioFooter";
import { TransitionComponent } from "../../../components/core/TransitionComponent/TransitionComponent";
import { currentlyViewingScenarioSelector } from "../../../reduxState/slices/scenarioSlice";
import noConnectors from "../../../assets/noConnectors.png";
import { Message } from "../../../components/Message/Message";
import { getReportingDataText, getReportingDateObj } from "../../../services/optimizationPage/optimization";
import { optimizerWithConfidenceSelector } from "src/reduxState/slices/featuresSlice";
import { FORMATS } from "src/enums/Formats";

const { formId, formField } = addScenarioFormModel;

const MainScenarioForm = ({}) => {
    const classes = optimizationStyle(useTheme());
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const analyticsService = useAnalyticsService();
    const { scenarioId } = useParams();
    const currentValidationSchema = validationSchema[0];
    const isNewScenario = location.pathname.includes(OPTIIMIZATION_CREATE_PATH);

    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const currentUser = useSelector(userStateSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const currentlyViewingScenario = useSelector(currentlyViewingScenarioSelector);
    const optimizerWithConfidence = useSelector(optimizerWithConfidenceSelector);

    const scenarioInitialValues = useMemo(
        () => ({
            id: "",
            name: `${currentUser ? currentUser.firstName : ""}’s Scenario ${format(new Date(), "MMM d, yyyy")}`,
            description: "",
            scenarioType: SCENARIO_BASIS.SCALE,
            forecastTimeframe: TIMEFRAME_OPTIONS.LAST28DAYS,
            budget: 0,
            revenue: 0,
            roas: 1.0,
            campaignData: [],
            optimizationConfidence: optimizerWithConfidence ? 70 : 0,
            isPrivate: false,
            isHidden: true,
        }),
        [currentUser],
    );

    const [selectedScenario, setSelectedScenario] = useState<IScenario>(scenarioInitialValues);
    const [timeframeValue, setTimeframeValue] = useState<TIMEFRAME_OPTIONS>(TIMEFRAME_OPTIONS.LAST28DAYS);
    const [errMsg, setErrMsg] = useState("");
    const [isFormSubmitLoading, setIsFormSubmitLoading] = useState(false);
    const [lastDaysTooltip, setLastDaysTooltip] = useState("");

    const [createScenario] = useCreateScenarioMutation();
    const [editScenario] = useEditScenarioMutation();
    const [getScenarioById] = useLazyGetScenarioByIdQuery();

    const [
        getPerformancePage,
        {
            data: performanceData = {
                data: [],
                meta: { spendDate: "" },
            },
            isLoading: isCampaignLoading,
            isFetching: isCampaignFetching,
        },
    ] = useLazyGetAvailableOptimizationModelsQuery();

    useEffect(() => {
        if (scenarioId) {
            if (currentlyViewingScenario?.id === scenarioId) {
                setSelectedScenario(currentlyViewingScenario);
                setErrMsg("");
            } else {
                getScenarioById({ orgId: currentOrgId, scenarioId }).then((response: any) => {
                    if (response.error?.status === 404) {
                        setErrMsg(`We couldn't find Scenario with 'id'=${scenarioId}`);
                    }
                });
            }
        }
    }, [scenarioId, location.pathname, currentlyViewingScenario]);

    useEffect(() => {
        if (timeframeValue && !scenarioId) {
            const lastDataDateObj = getReportingDateObj(timeframeValue);
            setLastDaysTooltip(
                getReportingDataText(timeframeValue, lastDataDateObj.start_date, lastDataDateObj.end_date),
            );

            getPerformancePage({
                orgId: currentOrgId,
                data: {
                    ...lastDataDateObj,
                },
            });
        } else {
            if (scenarioId && selectedScenario.createdAt) {
                const lastDataDateObj = getReportingDateObj(timeframeValue, selectedScenario.createdAt);
                setLastDaysTooltip(
                    getReportingDataText(timeframeValue, lastDataDateObj.start_date, lastDataDateObj.end_date),
                );

                getPerformancePage({
                    orgId: currentOrgId,
                    data: {
                        ...lastDataDateObj,
                    },
                });
            }
        }
    }, [selectedScenario, timeframeValue]);

    const handleExit = () => {
        navigate(`/org/${orgCode}${OPTIIMIZATION_PATH}`);
    };

    const redirectToScenarioOutcome = (id: string) => {
        navigate(`/org/${orgCode}${OPTIIMIZATION_PATH}/${id}`);
    };

    const onFinalSubmit = async (values: IScenario) => {
        let eventData = {
            Page: getPathForAnalytics(location.pathname),
        };

        if (values.scenarioType === SCENARIO_BASIS.SCALE) {
            const scaleType = capitalizeFirstLetter(values.scenarioType);
            const maxSpendTotal = values.campaignData.reduce((a, p) => a + (p.currentEstimatedSpend || 0), 0);
            const percentChange = calculatePercentage(values.budget, maxSpendTotal);
            eventData = { ...eventData, [scaleType]: formatValue(percentChange, FORMATS.PERCENT, 0) };
        }

        setIsFormSubmitLoading(true);
        analyticsService.logEvent("Configure Optimization Request Sent", eventData);

        const changedValues = getDiffObjectKeys(values, selectedScenario);
        if (!values.id) {
            createScenario({ body: values, orgId: currentOrgId })
                .unwrap()
                .then((response) => {
                    redirectToScenarioOutcome(response.id);
                })
                .catch(() => {
                    setIsFormSubmitLoading(false);
                    enqueueSnackbar("Something went wrong!", {
                        id: uniqueId(),
                        variant: "error",
                    });
                    throw new Error(`Something went wrong`);
                });
        } else {
            if (changedValues.length) {
                editScenario({
                    body: values,
                    orgId: currentOrgId,
                    scenarioId: values.id.toString(),
                })
                    .unwrap()
                    .then((response) => {
                        redirectToScenarioOutcome(response.id);
                    });
            } else {
                setIsFormSubmitLoading(false);
                navigate(`/org/${orgCode}${OPTIIMIZATION_PATH}/${values.id}`);
            }
        }
    };

    const getTimeframeValue = useCallback((value: TIMEFRAME_OPTIONS) => {
        setTimeframeValue(value);
    }, []);

    const getFooterBtnTitle = () => {
        return isNewScenario ? "Create Optimization Scenario" : "See Optimization Outcome";
    };

    const getFormTitle = () => {
        return scenarioId ? "Edit Optimization" : isNewScenario ? "Create New Optimization" : "Optimization Outcome";
    };

    const isPerformanceLoading = isCampaignLoading || isCampaignFetching;

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: "#f5f5f5",
                },
            }}
            fullScreen
            open={true}
            onClose={handleExit}
            TransitionComponent={TransitionComponent}
            disableEscapeKeyDown
        >
            <Formik
                initialValues={selectedScenario}
                validationSchema={currentValidationSchema}
                onSubmit={onFinalSubmit}
                enableReinitialize={true}
            >
                {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }: FormikProps<IScenario>) => {
                    const hasBudgetError = Boolean(errors.budget);
                    const hasCampaignError = Boolean(errors.campaignData);

                    return (
                        <>
                            <ScenaioAppBar
                                handleExitModal={handleExit}
                                heading={getFormTitle()}
                                isCloseDisabled={false}
                            />
                            {errMsg ? (
                                <Stack alignContent="center" height="100%" justifyContent="center">
                                    <Message title={errMsg} subtitle="" icon={noConnectors} filters={[]} />
                                </Stack>
                            ) : (
                                <Stack direction="row">
                                    <Form style={{ width: "100%" }} id={formId}>
                                        <ScenarioForm
                                            formField={formField}
                                            errors={errors}
                                            touched={touched}
                                            values={values}
                                            handleChange={handleChange}
                                            classes={classes}
                                            setFieldValue={setFieldValue}
                                            performanceData={performanceData}
                                            isPerformanceLoading={isPerformanceLoading}
                                            getTimeframeValue={getTimeframeValue}
                                            selectedScenario={selectedScenario}
                                            lastDaysTooltip={lastDaysTooltip}
                                        />
                                        <ScenarioFooter
                                            isDisplayBackBtn={false}
                                            isBtnDisabled={isPerformanceLoading || isFormSubmitLoading}
                                            handleCancelClick={handleExit}
                                            submitBtnTitle={getFooterBtnTitle()}
                                            onFinalSubmit={handleSubmit}
                                            isNotValidForm={hasBudgetError || hasCampaignError}
                                        />
                                    </Form>
                                </Stack>
                            )}
                        </>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

export default memo(MainScenarioForm);
