import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";
import { selectCurrentlyViewingCode, setCurrentlyViewing } from "./organizationSlice";
import { IConnector } from "../../interfaces/IConnector";
import { brokenConnectorsSelector } from "./brokenConnectorSlice";
import { logoutUser } from "../actions/auth";
import {
    AMAZON_SELLING_PARTNER,
    BIGQUERY_ECOMMERCE,
    GOOGLE_ANALYTICS_4,
    SALESFORCE_COMMERCE_CLOUD,
    SHOPIFY,
} from "src/consts/connectors";
import { getDefaultSource } from "src/services/homeDashboard/homePageTab";
import {
    SFCC_ORG_CODES_MISSING_REVENUE_CONNECTOR,
    BQE_ORG_CODES_MISSING_REVENUE_CONNECTOR,
} from "src/consts/performancePaidPage/performancePaidPage";

const initialState: any = [];

const connectorsSlice = createSlice({
    name: "connectors",
    initialState,
    reducers: {
        setConnector: (state, { payload }) => payload,
        addConnector: (state, { payload }) => {
            state.push(payload);
        },
        deleteConnectorById: (state, { payload }) => {
            return state.filter((c: any) => c.id !== payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
        builder.addCase(logoutUser, () => {
            return initialState;
        });
    },
});

export const { setConnector, addConnector, deleteConnectorById } = connectorsSlice.actions;

export default connectorsSlice.reducer;

// Selectors
export const connectorsSelector = (state: RootState) => state.connectors;

export const selectBrokenConnectors = createSelector(
    [connectorsSelector, brokenConnectorsSelector, selectCurrentlyViewingCode],
    (connectors, brokenConnectors, code) =>
        connectors.filter(
            (c: IConnector) =>
                c.status === "broken" && !brokenConnectors.filter((b) => b.includes(c.id.toString())).length,
        ),
);

export const isShopifyConnectedSelector = createSelector([connectorsSelector], (connectors) =>
    connectors.find((c: IConnector) => c.service === SHOPIFY) ? true : false,
);

export const shopifyConnectedSelector = createSelector([connectorsSelector], (connectors) =>
    connectors.find((c: IConnector) => c.service === SHOPIFY),
);

export const isAmazonConnectedSelector = createSelector([connectorsSelector], (connectors) =>
    connectors.find((c: IConnector) => c.service === AMAZON_SELLING_PARTNER) ? true : false,
);

export const defaultRevenueSourceSelector = createSelector(
    [connectorsSelector, selectCurrentlyViewingCode],
    (connectors, orgCode) => {
        const services = connectors
            .filter((c: IConnector) =>
                [SALESFORCE_COMMERCE_CLOUD, BIGQUERY_ECOMMERCE, SHOPIFY, GOOGLE_ANALYTICS_4].includes(
                    c.dataSource?.programmaticName,
                ),
            )
            .map((c: IConnector) => c.dataSource?.programmaticName);
        return (
            getDefaultSource(services) ||
            (SFCC_ORG_CODES_MISSING_REVENUE_CONNECTOR.includes(orgCode)
                ? SALESFORCE_COMMERCE_CLOUD
                : BQE_ORG_CODES_MISSING_REVENUE_CONNECTOR
                ? BIGQUERY_ECOMMERCE
                : "")
        );
    },
);
