import { format, subDays } from "date-fns";
import isArray from "lodash/isArray";
import { toDate } from "date-fns-tz";

import { FORECAST_TABLE_HEADERS_V2 } from "../../../../consts/performancePaidPage/performancePaidPage";
import { FORMATS } from "../../../../enums/Formats";
import { IForecast } from "../../../../interfaces/entities/IForecast";
import { formatValue, calculateROAS } from "../../../../services/utils";
import { IChartValue, ITableValue } from "./ForecastTab";
import { IDictionary } from "../../../../interfaces/IDictionary";
import { HIGH_CONFIDENCE_COLOR } from "src/services/optimizationPage/optimization";

export const forecastDate = format(subDays(toDate(new Date()), 3), "yyyy-MM-dd");

export const INCREMENT_INITIAL_PER_CHANGE = 10;
export const CONFIDENCE_COVERAGE_OPACITY = 0.8;

export const inputForEvent: IDictionary = {
    forecast1Spend: "average daily spend $",
    forecast2Spend: "average daily spend $",
    forecast1SpendPercentage: "average daily spend %",
    forecast2SpendPercentage: "average daily spend %",
};

export const confidenceColorStops = [
    { offset: 0.4, color: "#ffffff" },
    { offset: 0.4, color: "#ffffff" },
    { offset: 1, color: HIGH_CONFIDENCE_COLOR },
];

const interpolateColor = (startColor: string, endColor: string, percentage: number) => {
    const start = startColor.substring(1);
    const end = endColor.substring(1);

    const r1 = parseInt(start.substring(0, 2), 16);
    const g1 = parseInt(start.substring(2, 4), 16);
    const b1 = parseInt(start.substring(4, 6), 16);

    const r2 = parseInt(end.substring(0, 2), 16);
    const g2 = parseInt(end.substring(2, 4), 16);
    const b2 = parseInt(end.substring(4, 6), 16);

    const r = Math.round(r1 + (r2 - r1) * percentage);
    const g = Math.round(g1 + (g2 - g1) * percentage);
    const b = Math.round(b1 + (b2 - b1) * percentage);

    return `rgb(${r},${g},${b})`;
};

export const colors: IDictionary = {
    predictedRevenue: "black",
    predictedROAS: "#505050",
    bestCase: "#3fcc63",
    worstCase: "#f58417",
    modeledRevenue: "#7a7a7a",
    forecast1: "#ff3333",
    forecast2: "#1f70ff",
    last28DaysRevenue: "rgba(31, 210, 90)",
    confidence: interpolateColor("#ffffff", HIGH_CONFIDENCE_COLOR, 0.7),
};

export const initialTableValues = {
    forecast1: {
        heading: "No Change",
        spendPercentage: 0,
        spend: 0,
        revenue: 0,
        bestCase: 0,
        worstCase: 0,
        roas: 0,
        confidence: 0,
        formattedSpend: "",
    },
    forecast2: {
        heading: "10% spend",
        spendPercentage: 0,
        spend: 0,
        revenue: 0,
        bestCase: 0,
        worstCase: 0,
        roas: 0,
        confidence: 0,
        formattedSpend: "",
    },
};

export const emptyForcast: IForecast = {
    haloData: null,
    dates: [],
    spend: [],
    revenue: [],
    saturatedSpend: [],
    saturatedRevenue: [],
    bestCaseScenario: [],
    worstCaseScenario: [],
    totalSaturatedRevenue: [],
    totalBestCaseScenario: [],
    totalWorstCaseScenario: [],
    confidenceBins: [],
};

const getTooltipContent = (param: any, modeledRevenue: string) => {
    const scatterSeriesNames = [`${modeledRevenue} Revenue`, "Last 28 days of spend"];
    return (
        '<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block">' +
        (param.marker ||
            `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>`) +
        (scatterSeriesNames.includes(param.seriesName) ? `${modeledRevenue} Revenue / ROAS` : param.seriesName) +
        "</span><span style='margin-left:20px'><b>" +
        (scatterSeriesNames.includes(param.seriesName)
            ? `${formatValue(param.value[1], FORMATS.DOLLAR, 0)} /
           ${formatValue(param.value[1] / param.value[0] || 0, FORMATS.NUMERIC, 2)}`
            : formatValue(
                  param.value[1],
                  param.seriesName === "Predicted ROAS" ? FORMATS.NUMERIC : FORMATS.DOLLAR,
                  param.seriesName === "Predicted ROAS" ? 2 : 0,
              )) +
        "</span></b></li>"
    );
};

export const getHTMLForTooltip = (params: any, modeledRevenue: string) => {
    const tooltipBody = params
        .map((param: any) => {
            return getTooltipContent(param, modeledRevenue);
        })
        .join("");
    return tooltipBody;
};

export const getForecastTooltip = (
    params: any,
    tableValues: {
        [key: string]: IChartValue;
    },
    modeledRevenue: string,
) => {
    if (!isArray(params)) {
        const date = format(toDate(params.data.date), "MMM dd, yyyy");

        return (
            '<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block;font-weight:bold">' +
            date +
            "</span></b></li>" +
            '<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block;">' +
            `${date ? "Spend" : "Average Daily Spend"}` +
            "</span><span style='margin-left:20px'><b>" +
            formatValue(params.value[0], FORMATS.DOLLAR, 0) +
            "</b><span></li><hr/>" +
            getTooltipContent(params, modeledRevenue)
        );
    } else {
        const currentSpendValue = params.map((p: any) => p.data[0])[0];

        if (tableValues) {
            const hoveredItem = Object.values(tableValues).find(
                (value: any) => value.spend === currentSpendValue && value,
            );

            if (params.length === 1 && hoveredItem) {
                const data = [
                    { seriesName: "Spend", value: [hoveredItem.spend, hoveredItem.spend], color: "gray" },
                    {
                        seriesName: "Predicted Revenue",
                        value: [hoveredItem.spend, hoveredItem.revenue],
                        color: colors.predictedRevenue,
                    },
                    {
                        seriesName: "Predicted ROAS",
                        value: [hoveredItem.spend, calculateROAS(hoveredItem.spend, hoveredItem.revenue)],
                        color: colors.predictedROAS,
                    },
                    {
                        seriesName: "Best Case",
                        value: [hoveredItem.spend, hoveredItem.bestCase],
                        color: colors.bestCase,
                    },
                    {
                        seriesName: "Worst Case",
                        value: [hoveredItem.spend, hoveredItem.worstCase],
                        color: colors.worstCase,
                    },
                ];

                return (
                    '<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block;font-weight:bold">' +
                    hoveredItem.heading +
                    "</span></li>" +
                    '<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block;">' +
                    "Average Daily Spend" +
                    "</span><span style='margin-left:20px'><b>" +
                    formatValue(data[0].value[1], FORMATS.DOLLAR, 0) +
                    "</b><span></li><hr/>" +
                    getHTMLForTooltip(data.slice(1), modeledRevenue)
                );
            }
        }

        const axisValue = formatValue(params[0].axisValue, FORMATS.DOLLAR, 0);
        return (
            '<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block;">Average Daily Spend' +
            "</span><span style='margin-left:20px'><b>" +
            axisValue +
            "</b><span></li><hr/>" +
            getHTMLForTooltip(params, modeledRevenue)
        );
    }
};

export const getPercentageChange = (oldNumber: number, newNumber: number) => {
    const percentage = oldNumber && newNumber ? ((oldNumber - newNumber) / oldNumber) * 100 : 0;
    return -percentage;
};

export const XDecreaseByYPer = (decPerChange: number | "-", originalValue?: number) => {
    if (decPerChange === "-") {
        return originalValue || 0;
    }
    return originalValue && decPerChange ? originalValue - (originalValue * -decPerChange) / 100 : 0;
};

export const XIncreaseByYPer = (incPerChange: number | "-", originalValue?: number) => {
    if (incPerChange === "-") {
        return originalValue || 0;
    }
    return originalValue && incPerChange ? originalValue + (originalValue * incPerChange) / 100 : 0;
};

const interpolateY = (a: { x: number; y: number }, b: { y: number; x: number }, c: number) => {
    return ((c - a.x) * (b.y - a.y)) / (b.x - a.x) + a.y;
};

export const getDataPoints = (allData: any, newSpend: number) => {
    if (Object.keys(allData).length === 0) {
        return {};
    }

    const {
        saturatedSpend = [],
        totalSaturatedRevenue = [],
        totalBestCaseScenario = [],
        totalWorstCaseScenario = [],
    } = allData;

    const sameIndexedForecastData1 = saturatedSpend.map((saturatedSpend: any, index: number) => ({
        saturatedSpend,
        saturatedRevenue: totalSaturatedRevenue[index] || 0,
        bestCase: totalBestCaseScenario[index] || 0,
        worstCase: totalWorstCaseScenario[index] || 0,
        roas: calculateROAS(saturatedSpend, totalSaturatedRevenue[index]) || 0,
    }));

    const nearestForecastSpend = saturatedSpend.reduce(
        (prev: number, curr: number) => (Math.abs(curr - newSpend) < Math.abs(prev - newSpend) ? curr : prev),
        0,
    );

    const findDataSeriesBySpend = sameIndexedForecastData1.findIndex(
        (sameIndexedForecastData1: any) => sameIndexedForecastData1.saturatedSpend === nearestForecastSpend,
    );

    const isDataFound = findDataSeriesBySpend > -1;

    const dataPoint1 = isDataFound
        ? sameIndexedForecastData1[findDataSeriesBySpend]
        : {
              saturatedSpend: newSpend,
              saturatedRevenue: 0,
              bestCase: 0,
              worstCase: 0,
              roas: 0,
          };
    const dataPoint2 = isDataFound && sameIndexedForecastData1[findDataSeriesBySpend + 1];

    return { dataPoint1, dataPoint2 };
};

export const getInterpolation = (allData: Partial<IForecast>, newSpend: number) => {
    if (!!Object.keys(allData).length) {
        const dataPoints = getDataPoints(allData, newSpend);
        const { dataPoint1, dataPoint2 } = dataPoints;

        const { confidenceBins = [] } = allData;
        const confidence =
            confidenceBins.find((confidence: any) => confidence.startBin <= newSpend && confidence.endBin >= newSpend)
                ?.confidence || 0;

        if (dataPoint1 && !dataPoint2) {
            return {
                saturatedSpend: newSpend,
                saturatedRevenue: dataPoint1.saturatedRevenue,
                worstCase: dataPoint1.worstCase,
                bestCase: dataPoint1.bestCase,
                roas: calculateROAS(newSpend, dataPoint1.saturatedRevenue),
                confidence,
            };
        }

        const revenue1 = { x: dataPoint1.saturatedSpend, y: dataPoint1.saturatedRevenue };
        const revenue2 = { x: dataPoint2.saturatedSpend, y: dataPoint2.saturatedRevenue };

        const worstCase1 = { x: dataPoint1.saturatedSpend, y: dataPoint1.worstCase };
        const worstCase2 = { x: dataPoint2.saturatedSpend, y: dataPoint2.worstCase };

        const bestCase1 = { x: dataPoint1.saturatedSpend, y: dataPoint1.bestCase };
        const bestCase2 = { x: dataPoint2.saturatedSpend, y: dataPoint2.bestCase };

        const revenue = interpolateY(revenue1, revenue2, newSpend);
        const worstCaseY = interpolateY(worstCase1, worstCase2, newSpend);
        const bestCaseY = interpolateY(bestCase1, bestCase2, newSpend);

        const updatedDataPoints = {
            saturatedSpend: newSpend,
            saturatedRevenue: revenue < 0 ? 0 : revenue,
            worstCase: worstCaseY,
            bestCase: bestCaseY,
            roas: calculateROAS(newSpend, revenue),
            confidence,
        };
        return updatedDataPoints;
    }
    return {
        saturatedSpend: newSpend,
        saturatedRevenue: 0,
        worstCase: 0,
        bestCase: 0,
        roas: 0,
        confidence: 0,
    };
};

export const inputSpendFormat = (spend: number, isEmpty?: boolean) => {
    return spend
        ? spend
              .toFixed(0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : isEmpty
        ? ""
        : "0";
};

export const updatedTableHeader = (tableValues: ITableValue) => {
    return FORECAST_TABLE_HEADERS_V2.map((header) => {
        let label = "0% Spend";
        if (["forecast1", "forecast2"].includes(header.id)) {
            if (+tableValues[header.id].spendPercentage !== 0) {
                label = `${+tableValues[header.id].spendPercentage > 0 ? "+" : ""}${
                    tableValues[header.id].spendPercentage
                }% Spend`;
            }
            if (+tableValues[header.id].spendPercentage === 0) {
                label = `No Change`;
            }
            return {
                ...header,
                label,
            };
        }
        return header;
    });
};

export const getDataAsPerViewForChart = (obj: any, days: number) => {
    let updatedTableValues = {};
    Object.keys(obj).forEach((c: any) => {
        updatedTableValues = {
            ...updatedTableValues,
            [c]: typeof obj[c] === "number" ? obj[c] / days : obj[c],
            formattedSpend: inputSpendFormat(obj.spend / days),
            roas: +formatValue(calculateROAS(obj.spend / days, obj.revenue / days), FORMATS.NUMERIC, 2),
            heading: +obj.spendPercentage === 0 ? `No Change` : `${obj.spendPercentage}% Spend`,
        };
    });
    return updatedTableValues as IChartValue;
};

export const getColorFromGradient = (value: number) => {
    const percentage = +(value * 100).toFixed(0);
    const startColor = HIGH_CONFIDENCE_COLOR;
    const endColor = "#ffffff";

    if (percentage >= 100) {
        return HIGH_CONFIDENCE_COLOR;
    } else if (percentage >= 40) {
        return interpolateColor(endColor, startColor, percentage / 100);
    } else {
        return endColor;
    }
};
