import { createSlice, createSelector } from "@reduxjs/toolkit";
import { IDictionary } from "src/interfaces/IDictionary";
import { logoutUser } from "../actions/auth";
import { RootState } from "../stores/store";
import { setCurrentlyViewing } from "./organizationSlice";
import { selectCurrentlyViewingCompanyById } from "./organizationSlice";
import { OnboardingStateValue } from "src/consts/onboardingPage/onboardingPage";

export interface IPerformanceCard {
    connectorName: string | undefined;
    metric: string;
}

export interface ISettings extends IDictionary {
    performanceOverviewCards: IPerformanceCard[];
    performanceDetailCards: string[];
    performanceColumnOrders: string[];
    paginationCount: number;
    isCampaignAvailable: boolean;
}

const initialState: ISettings = {
    performanceDetailCards: [],
    performanceOverviewCards: [],
    performanceColumnOrders: [],
    paginationCount: 25,
    isCampaignAvailable: true,
    isModelingAvailable: true,
};

const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setUserSettings: (state, { payload }) => {
            const { settings, key, fromApi } = payload;
            const { performanceColumnOrders, performanceDetailCards, performanceOverviewCards, counts } = settings;
            if (fromApi) {
                state.performanceColumnOrders = performanceColumnOrders || [];
                state.performanceDetailCards = performanceDetailCards
                    ? Object.values(performanceDetailCards)
                    : state.performanceDetailCards;
                state.performanceOverviewCards = performanceOverviewCards
                    ? Object.values(performanceOverviewCards).map((card: any) => ({
                          metric: card.kpi,
                          connectorName: card.connectorType,
                      }))
                    : state.performanceOverviewCards;
                state.paginationCount = counts && counts[0] ? counts[0].paginationCount : state.paginationCount;
                state.isModelingAvailable = state.isModelingAvailable;
                state.isCampaignAvailable = state.isCampaignAvailable;
            } else {
                state[key as keyof ISettings] = payload.settings;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setCurrentlyViewing, (state) => {
            state.isCampaignAvailable = initialState.isCampaignAvailable;
            state.isModelingAvailable = initialState.isModelingAvailable;
            return state;
        });
        builder.addCase(logoutUser, () => {
            return initialState;
        });
    },
});

export const { setUserSettings } = settingsSlice.actions;

export default settingsSlice.reducer;

// Selectors
export const settingsSelector = (state: RootState) => state.settings;

export const performanceColumnOrdersSelector = createSelector(
    settingsSelector,
    (data) => data.performanceColumnOrders || [],
);

export const paginationCountSelector = createSelector(settingsSelector, (data) => data.paginationCount || 25);

export const isCampaignAvailableSelector = (state: RootState) => state.settings.isCampaignAvailable;
export const isModelingAvailableSelector = (state: RootState) => state.settings.isModelingAvailable;

export const partialStateSelectorForHome = createSelector(
    [isCampaignAvailableSelector, isModelingAvailableSelector, selectCurrentlyViewingCompanyById],
    (isCampaignAvailable, isModelingAvailable, selectCurrentlyViewingCompanyById) =>
        !isCampaignAvailable ||
        !isModelingAvailable ||
        selectCurrentlyViewingCompanyById.onboardingState?.dataQa !== OnboardingStateValue.complete,
);
